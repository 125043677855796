export const KEY_VALUE_LIST_DISPLAY_TYPE_TEXT = 'text';
export const KEY_VALUE_LIST_DISPLAY_TYPE_SELECT = 'select';
export const KEY_VALUE_LIST_DISPLAY_TYPE_CUSTOM = 'custom';
export const KEY_VALUE_LIST_DISPLAY_TYPE = {
	TEXT: KEY_VALUE_LIST_DISPLAY_TYPE_TEXT,
	SELECT: KEY_VALUE_LIST_DISPLAY_TYPE_SELECT,
	CUSTOM: KEY_VALUE_LIST_DISPLAY_TYPE_CUSTOM,
};
export const KEY_VALUE_LIST_DISPLAY_TYPES = [
	KEY_VALUE_LIST_DISPLAY_TYPE_TEXT,
	KEY_VALUE_LIST_DISPLAY_TYPE_SELECT,
	KEY_VALUE_LIST_DISPLAY_TYPE_CUSTOM,
];