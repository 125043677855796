import React from "react";
import {components} from "react-select";
import Icon from 'Core/components/display/Icon';
import Label from 'Core/components/display/Label';
import {getBool} from 'Core/helpers/data';

/**
 * Select option component for capping plan select input that will display capping plan status alongside its name
 * @description Use this as an Option component replacement for the SelectInput (react-select) component. You can pass
 * Label component props through 'labelProps' prop of the SelectInput component. These prop can be an instance of
 * SelectDynamicValueFunction class if prop should depend on option data.
 *
 * @param {Object} props - Option props sent by the react-select component to all the options.
 * @return {JSX.Element}
 * @constructor
 */
const StatusOptionComponent = props => {
	const active = getBool(props, 'data.active');

	return (
		<components.Option {...props}>
			<Icon
				symbol="circle"
				className={`capping-plan-status-icon ${active ? 'success-color' : 'faded-color'}`}
			/>
			&nbsp;
			<Label
				element="span"
				elementProps={{className: !active ? 'capping-plan-inactive' : ''}}
				content={props.label}
			/>
		</components.Option>
	)
};

export default StatusOptionComponent;