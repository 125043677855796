import React from "react";
import {components} from "react-select";
import Icon from 'Core/components/display/Icon';
import {getBool} from 'Core/helpers/data';

/**
 * Single value component for capping plan select input that will display capping plan status alongside its name
 * @description Use this as an SingleValue component replacement for the SelectInput (react-select) component. You can 
 * pass Label component props through 'singleValueProps' prop of the SelectInput component. These prop can be an 
 * instance of SelectDynamicValueFunction class if prop should depend on option data.
 *
 * @param {Object} - Single value props sent by the react-select component to all the single values.
 * @return {JSX.Element}
 * @constructor
 */
const StatusSingleValueComponent = ({children, ...props}) => {
	const active = getBool(props, 'data.active');

	return (
		<components.SingleValue {...props}>
			<Icon
				symbol="circle"
				className={`capping-plan-status-icon ${active ? 'success-color' : 'faded-color'}`}
			/>
			&nbsp;
			<span className={!active ? 'capping-plan-inactive' : ''}>{children}</span>
		</components.SingleValue>
	)
};

export default StatusSingleValueComponent;