import {get} from "lodash";
import {getBoolean, getInteger, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'recipients';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@recipients/reset',
	SET_CUSTOMER_MESSAGE_LIST_DATA: '@recipients/set_customer_message_list_data',
	CLEAR_CUSTOMER_MESSAGE_LIST_DATA: '@recipients/clear_customer_message_list_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	/**
	 * @param {IoJsonFetchResponseObject} customerMessageListData
	 * @return {{type: string, customerMessageListData: IoJsonFetchResponseObject}}
	 */
	setCustomerMessageListData: customerMessageListData => ({
		type: REDUCER_TYPES.SET_CUSTOMER_MESSAGE_LIST_DATA,
		customerMessageListData,
	}),
	clearCustomerMessageListData: () => ({type: REDUCER_TYPES.CLEAR_CUSTOMER_MESSAGE_LIST_DATA}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	customerMessageListData: null, 
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};

		case REDUCER_TYPES.SET_CUSTOMER_MESSAGE_LIST_DATA: 
			return {...state, customerMessageListData: action.customerMessageListData};
		case REDUCER_TYPES.CLEAR_CUSTOMER_MESSAGE_LIST_DATA: return {...state, 
			customerMessageListData: initialState.customerMessageListData};

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getCustomerMessageList: state => get(state, [reducerStoreKey, 'customerMessageListData', 'data']),
	getCustomerMessageListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'customerMessageListData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'customerMessageListData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'customerMessageListData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'customerMessageListData', 'isLastPage'])
	}),
	getCustomerMessageListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'customerMessageListData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'customerMessageListData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getCustomerMessageListFilter: state => get(state, [reducerStoreKey, 'customerMessageListData', 'filter'], null),
};

export default reducer;