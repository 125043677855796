/**
 * Data object representing the blacklists list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class BlacklistsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} name - Blacklist name.
	 * @param {string} projectName - Name of the project blacklist is assigned to.
	 * @param {CampaignMessageContentType} messageType - Type of the message blacklist is for.
	 * @param {string} clientId - DB ID of the client (used by super admin).
	 */
	constructor(id, name, projectName, messageType, clientId) {
		this.id = id;
		this.name = name;
		this.projectName = projectName;
		this.messageType = messageType;
		this.clientId = clientId;
	}
}

/**
 * Data object representing the blacklists item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class BlacklistsItemDataObject {
	/**
	 * @param {string|null} [id=null] - List item's ID.
	 * @param {string} [name=''] - Blacklist name.
	 * @param {?string} [projectId=null] - Project blacklist is assigned to.
	 * @param {CampaignMessageContentType} [messageType=''] - Type of the message blacklist is for.
	 * @param {?string} [channelId=null] - Channel that the blacklist will apply to.
	 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
	 */
	constructor(id = null, name = '', projectId = null, messageType = '', channelId = null, clientId = null) {
		this.id = id;
		this.name = name;
		this.projectId = projectId;
		this.messageType = messageType;
		this.channelId = channelId;
		this.clientId = clientId;
	}
}