import {find, findIndex, get} from "lodash";
import {getArray, getBoolean, getInteger, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'campaign';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@campaign/reset',
	SET_LIST_DATA: '@campaign/set_list_data',
	CLEAR_LIST_DATA: '@campaign/clear_list_data',
	SET_ITEM: '@campaign/set_item',
	SET_ITEM_STATUS: '@campaign/set_item_status',
	CLEAR_ITEM: '@campaign/clear_item',
	SET_MESSAGE_DEFINITIONS: '@campaign/set_message_definitions',
	CLEAR_MESSAGE_DEFINITIONS: '@campaign/clear_message_definitions',
	UPDATE_MESSAGE_DEFINITION: '@campaign/update_message_definition',
	SET_MESSAGE_DEFINITION_STATUS: '@campaign/set_message_definition_status',
	SET_MESSAGE_DEFINITION_GENERATING_CUSTOMER_MESSAGES: '@campaign/set_message_definition_generating_customer_messages',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	
	/**
	 * @param {IoJsonFetchResponseObject} campaignListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	setCampaignListData: campaignListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA, 
		listData: campaignListData
	}),
	clearCampaignListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
	
	/**
	 * @param {CampaignItemDataObject} campaignItem
	 * @return {{type: string, item: CampaignItemDataObject}}
	 */
	setCampaignItem: campaignItem => ({
		type: REDUCER_TYPES.SET_ITEM, 
		item: campaignItem
	}),
	/**
	 * @param {CampaignStatus} status
	 * @return {{type: string, status: CampaignStatus}}
	 */
	setCampaignItemStatus: status => ({type: REDUCER_TYPES.SET_ITEM_STATUS, status}),
	clearCampaignItem: () => ({type: REDUCER_TYPES.CLEAR_ITEM}),

	/**
	 * @param {MessageDefinitionDataObject[]} messageDefinitions
	 * @return {{type: string, messageDefinitions: MessageDefinitionDataObject[]}}
	 */
	setMessageDefinitions: messageDefinitions => ({
		type: REDUCER_TYPES.SET_MESSAGE_DEFINITIONS,
		messageDefinitions,
	}),
	clearMessageDefinitions: () => ({type: REDUCER_TYPES.CLEAR_MESSAGE_DEFINITIONS}),
	/**
	 * @param {MessageDefinitionDataObject} messageDefinition
	 * @return {{type: string, messageDefinition: MessageDefinitionDataObject}}
	 */
	updateMessageDefinition: messageDefinition => ({type: REDUCER_TYPES.UPDATE_MESSAGE_DEFINITION, messageDefinition}),
	/**
	 * @param {string} id - Message definition DB ID
	 * @param {MessageDefinitionStatus} status
	 * @return {{type: string, status: MessageDefinitionStatus}}
	 */
	setMessageDefinitionStatus: (id, status) => ({type: REDUCER_TYPES.SET_MESSAGE_DEFINITION_STATUS, id, status}),
	/**
	 * @param {string} id - Message definition DB ID
	 * @param {boolean} isGenerating
	 * @return {{type: string, id: string, isGenerating: boolean}}
	 */
	setMessageDefinitionGenerating: (id, isGenerating) => ({
		type: REDUCER_TYPES.SET_MESSAGE_DEFINITION_GENERATING_CUSTOMER_MESSAGES, id, isGenerating
	})
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {CampaignItemDataObject}
	 */
	item: null,

	/**
	 * @note null means that the list is not loaded yet.
	 * @type {MessageDefinitionDataObject[]}
	 */
	messageDefinitions: null,

	/**
	 * List of message definitions and their generating customer messages status
	 * @type {{id: string, isGenerating: boolean}[]}
	 */
	messageDefinitionsGenerating: [],
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		case REDUCER_TYPES.SET_ITEM: return {...state, item: action.item};
		case REDUCER_TYPES.SET_ITEM_STATUS: 
			return {
				...state, 
				item: (state.item ? {...state.item, status: action.status} : state.item)
			};
		case REDUCER_TYPES.CLEAR_ITEM: return {...state, item: initialState.item};
		
		case REDUCER_TYPES.SET_MESSAGE_DEFINITIONS: 
			return  {...state, messageDefinitions: action.messageDefinitions};
		case REDUCER_TYPES.CLEAR_MESSAGE_DEFINITIONS: 
			return {...state, messageDefinitions: initialState.messageDefinitions};
			
		case REDUCER_TYPES.SET_MESSAGE_DEFINITION_GENERATING_CUSTOMER_MESSAGES:
			let updated = [];
			const entryIndex = findIndex(getArray(state, 'messageDefinitionsGenerating'), {id: action.id});
			if (entryIndex !== -1) {
				updated = state.messageDefinitionsGenerating
					.map(i => i.id === action.id ? {...i, isGenerating: action.isGenerating} : i);
			} else {
				updated.push(action);
			}
			return {...state, messageDefinitionsGenerating: updated};
			
		case REDUCER_TYPES.UPDATE_MESSAGE_DEFINITION:
			if (Array.isArray(state.messageDefinitions) && action.messageDefinition) {
				return {
					...state, 
					messageDefinitions: state.messageDefinitions.map(item => {
						if (item.id === action.messageDefinition.id) return {...action.messageDefinition};
						else return item;
					})
				}
			}
			return state;
		case REDUCER_TYPES.SET_MESSAGE_DEFINITION_STATUS:
			if (Array.isArray(state.messageDefinitions)) {
				return {
					...state,
					messageDefinitions: state.messageDefinitions.map(item => {
						if (item.id === action.id) return {...item, status: action.status};
						else return item;
					})
				};
			}
			return state;

		default: return state;
	}
};

// Selectors
export const selectors = {
	getCampaignList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getCampaignListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getCampaignListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getCampaignListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),

	areCustomerMessagesBeingGenerated: state => getArray(state, [reducerStoreKey, 'messageDefinitionsGenerating'])
		.some(i => i.isGenerating),
	isMessageDefinitionGeneratingCustomerMessages: (state, id) => 
		getBoolean(find(get(state, [reducerStoreKey, 'messageDefinitionsGenerating']), {id}), 'isGenerating'), 

	getCampaignItem: state => get(state, [reducerStoreKey, 'item']),
	
	getMessageDefinitions: state => get(state, [reducerStoreKey, 'messageDefinitions']),
	getMessageDefinition: (state, id) => find(get(state, [reducerStoreKey, 'messageDefinitions']), {id}),
};

export default reducer;