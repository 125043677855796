import {
	ioJsonDeleteItemAction,
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioJsonSaveAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import * as itemUpdateDataMap from "./dataMap/itemUpdate";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Reset capping list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetCappingListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch capping list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [projectId] - DB ID of the project to get the capping list for.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {string} [clientId=''] - DB ID of the client (used by super admin).
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchCappingListAction = (
	abortCallback, projectId, filter = null, pageNo = 1, perPage, sortBy, sortDir, clientId = ''
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'frequency-capping-plan/search',
		'',
		isset(filter) && filter !== null ? {...filter, projectId} : {projectId},
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null,
		sortDir,
		{clientId}
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load capping list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [projectId] - DB ID of the project to get the capping list for.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @param {string} [clientId=''] - DB ID of the client (used by super admin).
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadCappingListAction = (
	abortCallback, projectId, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true, clientId
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchCappingListAction(abortCallback, projectId, filter, pageNo, perPage, sortBy, sortDir, clientId)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setCappingListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set capping list filter in Redux store
 * 
 * @param {Object} filter - Filter to set.
 * @return {(function(*): void)|*}
 */
export const setCappingFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setCappingListFilter(filter));
};

/**
 * Clear capping list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearCappingListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearCappingListData());
}

/**
 * Fetch capping item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the capping item to fetch.
 * @return {function(*=): Promise<CappingItemDataObject|undefined>}
 */
export const fetchCappingItemAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'frequency-capping-plan/fetch-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isSuccessful(responseData) ? itemDataMap.input(responseData?.data) : undefined);
};

/**
 * Load capping item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the capping item to load.
 * @return {function(*=): Promise<CappingItemDataObject|undefined>}
 */
export const loadCappingItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchCappingItemAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setCappingItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear capping item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearCappingItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearCappingItem());
};

/**
 * Create capping item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {CappingItemDataObject} item - Capping item to save.
 * @return {function(*=): Promise<CappingItemDataObject>} Promise that will resolve with the created item 
 * received from IO or undefined if creation failed.
 */
export const createCappingItemAction = (abortCallback, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'frequency-capping-plan/create',
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined);
};

/**
 * Update capping item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the capping item to update.
 * @param {CappingItemDataObject} item - Capping item to save.
 * @return {function(*=): Promise<CappingItemDataObject>} Promise that will resolve with the updated item 
 * received from IO or undefined if updating failed.
 */
export const updateCappingItemAction = (abortCallback, id, item) => dispatch => {
	const loading = showLoading('#item-popup');
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'frequency-capping-plan/update',
		{
			id,
			data: itemUpdateDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setCappingItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => { 
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setCappingItem(updatedItem));
			return updatedItem; 
		});
};

/**
 * Delete capping item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the capping item that will be deleted.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const deleteCappingItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'', // TODO: Add endpoint
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};