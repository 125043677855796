import styles from "./index.module.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import Label from "Core/components/display/Label";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import AppName from "Core/components/display/AppName";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import TextInput from "Core/components/input/TextInput";
import DataValueValidation from "Core/validation";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import {app_home_page_router_path} from "Config/app";

class RequestDemoPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				email: "",
			},
		}, {
			layout: 'auth',
			domPrefix: 'request-demo-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			renderTitle: false,
			disableLoad: true,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['*'],
		}, 'page_title');
		
		// Action methods
		this.submit = this.submit.bind(this);
	}

	// TODO: Redirect to home page if user is already logged in

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('email', 'required', 'email');

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Submit a demo request
	 * @return {Promise}
	 */
	submit() {
		const {requestDemoAction} = this.props;
		const email = this.getValue('email');

		this.clearValidationErrors()
			.then(() => {
				if (this.validate()) return this.executeAbortableActionMount(requestDemoAction, email);
				else return Promise.resolve();
			});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		// TODO: Render different content for RequestDemo page using React Router (https://blog.roastmylandingpage.com/request-a-demo-page-examples/)
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<div className={`login-component card-style ${styles['inner']}`}>
					<div className="login-header">
						<div className="app-name">
							<AppName />
						</div>
						<div className="form-title">
							<Label content={this.t('form_title')} />
							<Label 
								element="div" 
								elementProps={{className: 'form-desc'}} 
								content={this.t('form_description')} 
							/>
						</div>
					</div>
					<div className="login-content">
						<FormWrapper>
							<FormField
								required={true}
								label={this.t('emailField')}
								labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
								errorMessages={this.getValidationErrors('email')}
							>
								<TextInput
									type="email"
									name="email"
									value={this.getValue('email')}
									onChange={this.handleInputChange}
									onEnterKey={this.submit}
									placeholder={this.t('emailFiledPlaceholder')}
								/>
							</FormField>
						</FormWrapper>
					</div>
					<div className="login-actions">
						<Button
							big={true}
							displayStyle={BUTTON_STYLE.ACTION}
							icon="paper-plane-o"
							label={this.t('submit')}
							onClick={this.submit}
						/>
						<Button
							big={true}
							className="back-btn"
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							displayStyle={BUTTON_STYLE.SUBTLE}
							icon="chevron-left"
							label={this.t('Back', 'general')}
							onClick={() => this.redirectTo(app_home_page_router_path)}
						/>
					</div>
				</div>
			</div>
		), undefined, undefined, {
			showHeader: true,
			footerJustifyContent: 'center',
		});
	}
}

export * from "./config";
export default withRouter(connect(null, getPageActions(actions))(RequestDemoPage));