import "react-phone-input-2/lib/plain.css";
import "./index.css";

import customMasks from "./masks.json";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import {default as ReactPhoneInput} from "react-phone-input-2";
import PropTypes from "prop-types";
import Country from "Core/country";
import {PHONE_INPUT_REGIONS, PHONE_INPUT_SUBREGIONS} from "Core/components/input/PhoneInput/const";
import {omit, uniq} from "lodash";
import {getArray, getObject, getString} from "Core/helpers/data";
import {v4} from "uuid";
import {getAppLocaleValue} from "Core/helpers/locale";

class PhoneInput extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'PhoneInput',
			domPrefix: 'phone-input-component',
		});

		// Input handle methods
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		const {cleanInput} = this.props;
		
		if (cleanInput && this.phoneInputRef && getString(this.props, 'value') === '') {
			this.phoneInputRef.state.formattedNumber = '';
			this.phoneInputRef.forceUpdate();
		}
		
		return Promise.resolve();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {cleanInput} = this.props;

		if (cleanInput && this.phoneInputRef && getString(this.props, 'value') === '') {
			this.phoneInputRef.state.formattedNumber = '';
			this.phoneInputRef.forceUpdate();
		}
	}

	// I18n -------------------------------------------------------------------------------------------------------------
	/**
	 * Handle locale change
	 * @note This method will be called every time locale changes.
	 *
	 * @param {object} translation - Newly loaded translation object.
	 * @return {Promise<Object>} - Promise that resolves to a newly loaded translation object.
	 */
	handleLocaleChange(translation) {
		// Update component's ID because it is used as key so that the 'localization' prop change is processed
		this.id = `c-${v4()}`;
		
		return super.handleLocaleChange(translation);
	}


	// Input handle methods ---------------------------------------------------------------------------------------------
	/**
	 * Input element key down handler
	 * @param {KeyboardEvent|SyntheticEvent} event - JavaScript key down event.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleKeyDown(event) {
		// Trigger standard keydown event
		executeComponentCallback(this.props.onKeyDown, event);

		// Trigger Enter key event if menu is closed since when it is opened Enter key is used to select a value.
		if (event.key === 'Enter') {
			event.preventDefault();
			executeComponentCallback(this.props.onEnterKey, event);
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			id, className, useAppLocaleCode, country, preferredCountries, value, masks, nullClearsCountry, onKeyDown,
			currentCountryAsDefault, ...otherProps
		} = this.props;
		const searchPlaceholder = getString(this.props, 'searchPlaceholder', this.t('search_placeholder'))
		const searchNotFound = getString(this.props, 'searchNotFound', this.t('search_not_found'))
		
		return (
			<ReactPhoneInput
				{...omit(otherProps, ['searchPlaceholder', 'searchNotFound', 'disableSearchIcon'])}
				{...(useAppLocaleCode ? getAppLocaleValue('plugins.phoneInputComponent') : {})}
				key={this.getDomId()}
				id={this.getDomId()}
				className={`${this.getOption('domPrefix')} ${className}`}
				disableSearchIcon={true}
				masks={{
					...customMasks,
					...getObject(masks)
				}}
				country={
					country ? 
						country : 
						(currentCountryAsDefault ? Country.getAppCountry().code2.toLocaleLowerCase() : null)
				}
				preferredCountries={
					getArray(preferredCountries).length === 0 ?
						uniq(Country.getAppLocaleList().map(locale => locale.countryCode.toLocaleLowerCase())) :
						preferredCountries
				}
				searchPlaceholder={searchPlaceholder}
				searchNotFound={searchNotFound}
				value={nullClearsCountry ? value : (value === null ? '' : value)}
				onKeyDown={this.handleKeyDown}
				ref={node => { this.phoneInputRef = node; }}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PhoneInput.propTypes = {
	// @note This component uses react-phone-input-2 component
	// @link https://github.com/bl00mber/react-phone-input-2
	
	// Phone input wrapper element id attribute
	id: PropTypes.string,
	// Phone input wrapper element class attribute
	className: PropTypes.string,
	// This will determine if current app locale will be added to the component (translations)
	useAppLocaleCode: PropTypes.bool,

	// JSON objet containing all translations 
	// @see Exports at the bottom of this file. 
	localization: PropTypes.object,

	// Class for container
	containerClass : PropTypes.string,
	// Class for input
	inputClass: PropTypes.string,
	// Class for dropdown button
	buttonClass: PropTypes.string,
	// Class for dropdown container
	dropdownClass: PropTypes.string,
	// Class for search field
	searchClass: PropTypes.string,
	// Styles for container
	containerStyle: PropTypes.string,
	// Styles for input
	inputStyle: PropTypes.string,
	// Styles for dropdown button
	buttonStyle: PropTypes.string,
	// Styles for dropdown container
	dropdownStyle: PropTypes.string,
	// Styles for search field
	searchStyle: PropTypes.string,

	// Initial country
	// @note This component uses ISO 3166-1 alpha-2 two-letter lowercase country code (ex: 'sr').
	// @link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
	country: PropTypes.string,
	// Flag that specifies if current country will be selected as default country
	currentCountryAsDefault: PropTypes.bool,
	// Input state value 
	value: PropTypes.string,
	// Country codes to be included
	onlyCountries: PropTypes.arrayOf(PropTypes.string),
	// Country codes to be at the top
	// @note If this is not defined, countries from app locale list will be used.
	preferredCountries: PropTypes.arrayOf(PropTypes.string),
	// Array of country codes to be excluded
	excludeCountries: PropTypes.arrayOf(PropTypes.string),
	// Custom placeholder 
	placeholder: PropTypes.string,
	// Props to pass into the input 
	inputProps: PropTypes.object,

	// On/off phone formatting
	autoFormat: PropTypes.bool,
	// Disable input and dropdown
	disabled: PropTypes.bool,
	// Disable dropdown only
	disableDropdown: PropTypes.bool,
	// Disable country code
	disableCountryCode: PropTypes.bool,
	// Enable local codes for all countries
	enableAreaCodes: PropTypes.bool,
	// Enable dependent territories with population of ~100,000 or lower
	enableTerritories: PropTypes.bool,
	// Boolean/number 
	enableLongNumbers: PropTypes.bool,
	// Country code editable
	countryCodeEditable: PropTypes.bool,
	// Enable search in the dropdown
	enableSearch: PropTypes.bool,
	// Flag that specifies if null 'value' prop will clear the country dropdown value.
	nullClearsCountry: PropTypes.bool,
	// Flag that specifies if input value will be cleared if 'value' prop is empty even if the country is selected
	// @description This component will inject the country code in the input when the country is selected but the value
	// will not be passed to the 'value' props which means that the actual value in the input and the 'value' prop are 
	// not synchronized. Setting this flag to true will not allow for that to happen. It will clear the input value if
	// 'value' prop is empty.
	cleanInput: PropTypes.bool,
	
	// To show countries only from specified regions 
	regions: PropTypes.oneOf([...PHONE_INPUT_REGIONS, ...PHONE_INPUT_SUBREGIONS]),

	defaultMask: PropTypes.string,
	alwaysDefaultMask: PropTypes.bool,
	prefix: PropTypes.string,
	searchPlaceholder: PropTypes.string,
	searchNotFound: PropTypes.string,
	copyNumbersOnly: PropTypes.bool,
	renderStringAsFlag: PropTypes.string,
	autocompleteSearch: PropTypes.bool,
	jumpCursorToEnd: PropTypes.bool,
	priority: PropTypes.object,
	enableClickOutside: PropTypes.bool,
	showDropdown: PropTypes.bool,
	defaultErrorMessage: PropTypes.string,
	specialLabel: PropTypes.string,
	disableInitialCountryGuess: PropTypes.bool,
	disableCountryGuess: PropTypes.bool,

	// Events
	onChange: PropTypes.func, // Arguments: {string} value, {string} country, {Event} e, {string} formattedValue
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	onEnterKey: PropTypes.func,
};

/**
 * Define component default values for own props
 */
PhoneInput.defaultProps = {
	id: '',
	className: '',
	useAppLocaleCode: true,
	currentCountryAsDefault: true,
	placeholder: '',
	autoFormat: true,
	disabled: false,
	disableDropdown: false,
	disableCountryCode: false,
	enableAreaCodes: false,
	enableTerritories: false, 
	enableLongNumbers: false,
	countryCodeEditable: true,
	enableSearch: true,
	nullClearsCountry: false,
	cleanInput: true,
	defaultMask: '... ... ... ... ..',
	alwaysDefaultMask: false,
	prefix: '+',
	copyNumbersOnly: true,
	autocompleteSearch: false,
	jumpCursorToEnd: false,
	enableClickOutside: true,
	showDropdown: false,
	specialLabel: '',
	disableInitialCountryGuess: false,
	disableCountryGuess: false,
};

export {default as i18nRu} from "react-phone-input-2/lang/ru.json";
export {default as i18nFr} from "react-phone-input-2/lang/fr.json";
export {default as i18nJp} from "react-phone-input-2/lang/jp.json";
export {default as i18nCn} from "react-phone-input-2/lang/cn.json";
export {default as i18nPt} from "react-phone-input-2/lang/pt.json";
export {default as i18nIt} from "react-phone-input-2/lang/it.json";
export {default as i18nIr} from "react-phone-input-2/lang/ir.json";
export {default as i18nAr} from "react-phone-input-2/lang/ar.json";
export {default as i18nTr} from "react-phone-input-2/lang/tr.json";
export {default as i18nId} from "react-phone-input-2/lang/id.json";
export {default as i18nHu} from "react-phone-input-2/lang/hu.json";
export {default as i18nPl} from "react-phone-input-2/lang/pl.json";
export {default as i18nKo} from "react-phone-input-2/lang/ko.json";
export {default as i18nRs} from "./i18n/rs.json";
export {default as i18nRsCyr} from "./i18n/rs-cyr.json";
export default PhoneInput;