import "./index.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import SelectInput from "Core/components/input/SelectInput";
import TextInput from "Core/components/input/TextInput";
import {getObject, getString} from "Core/helpers/data";

class CustomerCustomFieldFilter extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				field: '',
				value: '',
			},
		}, {
			enableLoadOnDataPropChange: true,
			dataPropAlias: 'value',
		});
	}

	/**
	 * @inheritDoc
	 */
	getDataToLoad(rawData) {
		const fields = Object.keys(getObject(rawData));
		
		if (fields.length === 0) return this.getInitialData();
		return {
			field: getString(fields, '0'),
			value: getString(rawData, getString(fields, '0'))
		};
	}

	/**
	 * @inheritDoc
	 */
	getDataToReturn() {
		const field = this.getValue('field');
		
		if (!field) return {};
		return {[this.getValue('field')]: getString(this.getValue('value'))};
	}

	/**
	 * @inheritDoc
	 */
	update(event) { return executeComponentCallback(this.props.onChange, this.getDataToReturn(), event); }

	/**
	 * @inheritDoc
	 */
	render() {
		const {options} = this.props;
		
		return (
			<>
				<SelectInput
					classNamePrefix="customer-custom-field-filter-select select-input"
					isClearable={true}
					options={options}
					value={this.getValue('field')}
					onChange={o => this.handleValueChangeAndUpdate('field', o)}
				/>
				<TextInput
					name="value"
					value={this.getValue('value')}
					onChange={this.handleInputChangeAndUpdate}
				/>
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CustomerCustomFieldFilter.propTypes = {
	options: PropTypes.array,
	value: PropTypes.object,
	
	onChange: PropTypes.func,
};

export default CustomerCustomFieldFilter;