/** @typedef {string} MessagePreviewType */
export const MESSAGE_PREVIEW_TYPE_SMS = 'SMS';
export const MESSAGE_PREVIEW_TYPE_EMAIL = 'EMAIL';
export const MESSAGE_PREVIEW_TYPE_VIBER = 'VIBER';
export const MESSAGE_PREVIEW_TYPE_PUSH = 'PUSH_NOTIFICATION';
/** @enum {MessagePreviewType} */
export const MESSAGE_PREVIEW_TYPE = {
	SMS: MESSAGE_PREVIEW_TYPE_SMS,
	EMAIL: MESSAGE_PREVIEW_TYPE_EMAIL,
	VIBER: MESSAGE_PREVIEW_TYPE_VIBER,
	PUSH: MESSAGE_PREVIEW_TYPE_PUSH,
};
/** @type {MessagePreviewType[]} */
export const MESSAGE_PREVIEW_TYPES = [
	MESSAGE_PREVIEW_TYPE_SMS,
	MESSAGE_PREVIEW_TYPE_EMAIL,
	MESSAGE_PREVIEW_TYPE_VIBER,
	MESSAGE_PREVIEW_TYPE_PUSH,
];

/** @type {Object<MessagePreviewType, {symbol: string, [symbolPrefix]: string}>} */
export const MESSAGE_PREVIEW_TYPE_ICONS = {
	[MESSAGE_PREVIEW_TYPE_SMS]: {symbol: 'ui-messaging', symbolPrefix: 'icofont-'},
	[MESSAGE_PREVIEW_TYPE_VIBER]: {symbol: 'brand-viber', symbolPrefix: 'icofont-'},
	[MESSAGE_PREVIEW_TYPE_PUSH]: {symbol: 'notification', symbolPrefix: 'icofont-'},
	[MESSAGE_PREVIEW_TYPE_EMAIL]: {symbol: 'email', symbolPrefix: 'icofont-'},
};