import {cloneDeep, get} from 'lodash';
import {getArray, getString, resetObjectValues} from "Core/helpers/data";
import {CustomersItemDataObject} from "../dataObjects";
import * as customFieldDataMap from "./customField";
import {getDate, getDateString} from 'Core/helpers/datetime';
import {STANDARD_DATE_TIME_FORMAT} from 'Core/const/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CustomersItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CustomersItemDataObject(
		getString(result, 'id'),
		getString(result, 'clientId'),
		getString(result, 'externalCrmId'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getString(result, 'mobilePhoneNumber'),
		getString(result, 'email'),
		getDate(get(result, 'dateOfBirth', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getArray(result, 'customerCustomFields').map(ccf => customFieldDataMap.input(ccf))
	);

	return result;
}

/**
 * Get output data from input data
 * @param {CustomersItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		firstName: result.firstName,
		lastName: result.lastName,
		email: result.email,
		mobilePhoneNumber: result.phone,
		externalCrmId: result.crmId,
		clientId: result.clientId,
		dateOfBirth: getDateString(result.dateOfBirth, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		customerCustomFields: getArray(result, 'customFields').map(ccf => customFieldDataMap.output(ccf)),
		override: true,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}