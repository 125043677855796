import React from "react";
import {connect} from "react-redux";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
import * as actions from "./actions";

import style from "./index.module.css";
import {cloneDeep, isEqual} from "lodash";

class Dialog extends BaseComponent {
	constructor(props) {
		super(props, props.options);

		// Dialog methods
		this.close = this.close.bind(this);

		// Custom component methods
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.getDialogMaxWidthString = this.getDialogMaxWidthString.bind(this);

		// Register event listeners
		this.registerEventListener('keydown', this.handleKeyDown);
	}

	/**
	 * Close the dialog
	 */
	close(){
		const { GUIID, closeDialogAction, innerComponentProps } = this.props;

		// Trigger component's onClose event and close the dialog if allowed
		// @note Dialog will not be closed if event callback returns false.
		if (executeComponentCallback(innerComponentProps?.onClose, GUIID) !== false) closeDialogAction(GUIID);
	}

	/**
	 * Keyboard key down event handler
	 *
	 * @param {KeyboardEvent} event - Keyboard key down event.
	 */
	handleKeyDown(event){
		const {dialogsCount, ordinal} = this.props;
		if (this.getOption('closeOnEscape') === true && event.key === 'Escape' && ordinal === dialogsCount) this.close();
	}

	/**
	 * Get dialog's max width string with unit ('px' or '%') suffix at the end or the keywords 'min-content', 
	 * 'max-content' and 'fit-content'
	 * @note This method uses 'maxWidth' component option. If 'maxWidth' is defined as a number or a number string 
	 * without a unit ('px' or '%') it will be interpreted as a pixel value.
	 * @return {string} Dialog's max width string with unit ('px' or '%') suffix at the end or the keywords 
	 * 'min-content', 'max-content' and 'fit-content'.
	 */
	getDialogMaxWidthString() {
		let result = '';
		const maxWidthOption = this.getOption('maxWidth');
		if (typeof maxWidthOption === 'string') {
			if (
				maxWidthOption.endsWith('px') || maxWidthOption.endsWith('%') || 
				['min-content', 'max-content', 'fit-content'].includes(maxWidthOption)
			) {
				result = maxWidthOption;
			} else {
				const maxWidthInt = parseInt(maxWidthOption);
				if(!isNaN(maxWidthInt) && maxWidthInt > 0) result = `${maxWidthInt}px`;
			}
		} else if (typeof maxWidthOption === 'number' && maxWidthOption > 0) {
			result = `${maxWidthOption}px`;
		}
		
		return result;
	}

	render() {
		const { GUIID, innerComponent, innerComponentProps, forwardedRef, ordinal, dialogsCount } = this.props;
		const hasInnerComponent = (typeof innerComponent !== 'undefined' && innerComponent);

		// Do not render dialog if inner component is not specified
		if(!hasInnerComponent) return null;
		
		return (
			<FocusTrap 
				active={ordinal === dialogsCount}
				focusTrapOptions={{
					escapeDeactivates: false,
					allowOutsideClick: true,
					fallbackFocus: '#' + this.getOption('id', `dialog-${GUIID}`)
				}}
			>
				<div
					id={this.getOption('id', `dialog-${GUIID}`)}
					className={`dialog-component ${style['dialog']} ` + this.getOption('className', '')}
					ref={forwardedRef}
				>
					<div
						className={`dialog-overlay ${style['overlay']}`}
						onClick={this.getOption('closeOnClickOutside') === true ? this.close : null}
					/>
					<div
						className={`dialog-content ${style['content']}`}
						style={{maxWidth: this.getDialogMaxWidthString()}}
					>
						{
							this.getOption('hideCloseBtn') !== true ?
								<div className={`dialog-close ${style['close']}`} onClick={this.close}>
									<i className={`fa fa-times icon ${style['icon']}`} />
								</div>
								: null
						}
						<DialogInnerComponent
							innerComponent={innerComponent}
							dialogGUIID={GUIID} 
							dialogOptions={this.getOptions()}
							dialogCloseAction={this.close}
							{...innerComponentProps} 
						/>
					</div>
				</div>
			</FocusTrap>
		);
	}
}

/**
 * Dialogs inner component
 * @description This component will be rendered inside the dialog.
 * @note This component is created because dialogs inner component is loaded dynamically. If dynamic components are used
 * in render methods they will unmount and mount again on every local state change which creates problems. This is why
 * dynamic components should be rendered using some locale state which this component will do.
 */
class DialogInnerComponent extends BaseComponent {
	constructor(props) {
		super(props, {});

		// Define component's initial state
		this.initialState = {
			InnerComponent: this.renderInnerComponent(props)
		};

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Render methods
		this.renderInnerComponent = this.renderInnerComponent.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Update inner component in local state if inner component changes
		if (!isEqual(prevProps.innerComponent, this.props.innerComponent)) {
			this.setState({InnerComponent: this.renderInnerComponent(this.props)});
		}
	}
	
	renderInnerComponent(props) {
		const propToUse = (typeof props !== 'undefined' ? props : this.props);
		const {innerComponent, ...otherProps} = propToUse;
		return React.createElement(innerComponent, {...otherProps});
	}

	render() {
		return this.state.InnerComponent;
	}
}


/**
 * Define component own props that can be passed to it by parent components
 */
Dialog.propTypes = {
	// Unique dialog ID used to identify the dialog in the multi-dialog environment
	GUIID: PropTypes.string,
	// Component that will be shown inside the dialog
	innerComponent: PropTypes.any,
	// Props to pass tho the component that will be shown inside the dialog
	innerComponentProps: PropTypes.object,
	// Dialog options
	// @note These options are primarily used by the Dialogs component when rendering each individual dialog.
	options: PropTypes.shape({
		// Dialog's 'id' attribute
		id: PropTypes.string,
		// Dialog's 'class' attribute
		className: PropTypes.string,
		// Dialog's max width
		maxWidth: PropTypes.oneOfType([
			// String with or without unit suffix ('px' or '%'). If value is without unit suffix it will be interpreted as
			// a pixel value except for keywords 'min-content', 'max-content' and 'fit-content' which will be used as-is.
			PropTypes.string,
			// Number of pixels
			PropTypes.number
		]),
		// TODO: minHeight
		// Flag that determines if dialog will be closed when escape keyboard button is presses
		closeOnEscape: PropTypes.bool,
		// Flag that determines if dialog will be closed when clicking outside it
		closeOnClickOutside: PropTypes.bool,
		// Flag that determines if dialog's close button will be shown
		hideCloseBtn: PropTypes.bool,
	}),
	// Forwarded ref used by CSSTransition
	// @description CSSTransition 'nodeRef' prop must point to the DOM node (element). Because Dialog component is a 
	// class component some internal DOM node should be assigned to the forwarded ref passed in this prop. Usually the 
	// first DOM node (wrapper element) is used.
	forwardedRef: PropTypes.any,
	// Dialog ordinal
	// @note Starts from 1.
	ordinal: PropTypes.number,
	// Total number of opened dialogs
	dialogsCount: PropTypes.number,
};

/**
 * Define component default values for own props
 */
Dialog.defaultProps = {
	options: {
		id: '',
		className: '',
		closeOnEscape: false,
		closeOnClickOutside: false,
		hideCloseBtn: false,
		maxWidth: '80%',
	},
};

export default connect(null, actions)(Dialog);
export {default as reducer, reducerStoreKey, selectors, actionCreators} from "./reducer";
export * from "./actions";