/** @typedef {string} AdvancedDropdownPosition */
export const ADVANCED_DROPDOWN_POSITION_LEFT = 'LEFT';
export const ADVANCED_DROPDOWN_POSITION_RIGHT = 'RIGHT';
/** @enum {AdvancedDropdownPosition} */
export const ADVANCED_DROPDOWN_POSITION = {
	LEFT: ADVANCED_DROPDOWN_POSITION_LEFT,
	RIGHT: ADVANCED_DROPDOWN_POSITION_RIGHT,
};
/** @type {AdvancedDropdownPosition[]} */
export const ADVANCED_DROPDOWN_POSITIONS = [
	ADVANCED_DROPDOWN_POSITION_LEFT,
	ADVANCED_DROPDOWN_POSITION_RIGHT,
];