import {get} from "lodash";
import {getBoolean, getInteger, getObject, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'pushNotificationCustomersSearch';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@pushNotificationCustomersSearch/reset',
	SET_CUSTOMER_LIST_DATA: '@pushNotificationCustomersSearch/set_customer_list_data',
	SET_CUSTOMER_LIST_FILTER: '@pushNotificationCustomersSearch/set_customer_list_filter',
	CLEAR_CUSTOMER_LIST_DATA: '@pushNotificationCustomersSearch/clear_customer_list_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	setCustomersListData: customersListData => ({
		type: REDUCER_TYPES.SET_CUSTOMER_LIST_DATA,
		listData: customersListData
	}),
	setCustomersListFilter: filter => ({type: REDUCER_TYPES.SET_CUSTOMER_LIST_FILTER, filter}),
	clearCustomersListData: () => ({type: REDUCER_TYPES.CLEAR_CUSTOMER_LIST_DATA}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	customerListData: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_CUSTOMER_LIST_DATA: return {...state, customerListData: action.listData};
		case REDUCER_TYPES.SET_CUSTOMER_LIST_FILTER: return {
			...state,
			customerListData: {...getObject(state.customerListData), filter: action.filter}
		};
		case REDUCER_TYPES.CLEAR_CUSTOMER_LIST_DATA: return {...state, customerListData: initialState.listData};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getCustomersListForPushTest: state => get(state, [reducerStoreKey, 'customerListData', 'data']),
	getCustomersListPaginationForPushTest: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'customerListData', 'perPage'], 10),
		pageNo: getInteger(state, [reducerStoreKey, 'customerListData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'customerListData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'customerListData', 'isLastPage'])
	}),
	getCustomersListSortForPushTest: state => ({
		sortBy: getString(state, [reducerStoreKey, 'customerListData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'customerListData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getCustomersListFilterForPushTest: state => get(state, [reducerStoreKey, 'customerListData', 'filter'], null),
};

export default reducer;