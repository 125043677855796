/** @typedef {string} MessageDefinitionPopupMode */
export const MESSAGE_DEFINITION_POPUP_MODE_DEFAULT = '';
export const MESSAGE_DEFINITION_POPUP_MODE_WIZARD = '#wizard';
/** @enum {MessageDefinitionPopupMode} */
export const MESSAGE_DEFINITION_POPUP_MODE = {
	DEFAULT: MESSAGE_DEFINITION_POPUP_MODE_DEFAULT,
	WIZARD: MESSAGE_DEFINITION_POPUP_MODE_WIZARD,
};
/** @type {MessageDefinitionPopupMode[]} */
export const MESSAGE_DEFINITION_POPUP_MODES = [
	MESSAGE_DEFINITION_POPUP_MODE_DEFAULT,
	MESSAGE_DEFINITION_POPUP_MODE_WIZARD,
];
