import {
	ioJsonAction,
	ioJsonDeleteItemAction,
	ioJsonFetchAction,
	ioJsonFetchItemAction, ioJsonManualAction,
	ioJsonSaveAction
} from 'Core/store/actions/io';
import {hideLoading, hideLoadingFunction, showContentLoading, showLoading} from 'Core/helpers/loading';
import {getArray, getString, isset} from 'Core/helpers/data';
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Reset blacklists list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetBlacklistsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch blacklists list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchBlacklistsListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'blacklist/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load blacklists list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadBlacklistsListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchBlacklistsListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setBlacklistsListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set blacklists list filter in Redux store
 * 
 * @param {Object} filter - Filter to set.
 * @return {(function(*): void)|*}
 */
export const setBlacklistsFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setBlacklistsListFilter(filter));
};

/**
 * Clear blacklists list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearBlacklistsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearBlacklistsListData());
}

/**
 * Fetch blacklists item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the blacklists item to fetch.
 * @return {function(*=): Promise<BlacklistsItemDataObject|undefined>}
 */
export const fetchBlacklistsItemAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'blacklist/fetch-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isSuccessful(responseData) ? itemDataMap.input(responseData?.data) : undefined);
};

/**
 * Load blacklists item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the blacklists item to load.
 * @return {function(*=): Promise<BlacklistsItemDataObject|undefined>}
 */
export const loadBlacklistsItemAction = (abortCallback, id) => dispatch => {
	const loading = showContentLoading();
	return fetchBlacklistsItemAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setBlacklistsItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear blacklists item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearBlacklistsItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearBlacklistsItem());
};

/**
 * Create blacklists item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {BlacklistsItemDataObject} item - Blacklists item to save.
 * @return {function(*=): Promise<BlacklistsItemDataObject>} Promise that will resolve with the created item 
 * received from IO or undefined if creation failed.
 */
export const createBlacklistsItemAction = (abortCallback, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'blacklist/create',
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined);
};

/**
 * Update blacklists item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the blacklists item to update.
 * @param {BlacklistsItemDataObject} item - Blacklists item to save.
 * @return {function(*=): Promise<BlacklistsItemDataObject>} Promise that will resolve with the updated item 
 * received from IO or undefined if updating failed.
 */
export const updateBlacklistsItemAction = (abortCallback, id, item) => dispatch => {
	const loading = showLoading('#item-popup');
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'blacklist/update-by-id',
		{
			id,
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setBlacklistsItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => { 
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setBlacklistsItem(updatedItem));
			return updatedItem; 
		});
};

/**
 * Delete blacklists item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the blacklists item that will be deleted.
 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const deleteBlacklistsItemAction = (abortCallback, id, clientId = null) => dispatch => {
	const loading = showLoading('#item-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'blacklist/delete-by-ids',
		id,
		{clientId},
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Check if customer are being imported/exported from a file for a given blacklist
 * @note Importing customers is done asynchronously on backend.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} blacklistId - DB ID of the associated blacklist.
 * @param {boolean} [ignoreErrors=false] - Flag that specifies if response errors will be ignored.
 * @param {?string} [clientId=null] - Client ID.
 * @return {function(*): Promise<ImportCustomersFromFileType|''|undefined>} Import type if import is in progress 
 * otherwise an empty string.
 */
export const checkBlacklistImportProgressAction = (
	abortCallback, blacklistId, ignoreErrors = false, clientId = null
) => dispatch => {
	const action = (ignoreErrors ? ioJsonManualAction : ioJsonAction);
	return action(
		abortCallback,
		'defaultAuthorizedApi',
		'blacklist/fetch-import-requests',
		{clientId, blacklistId}
	)(dispatch)
		.then(res => isSuccessful(res) ? getString(res, 'data[0].type') : '')
		.catch(() => { return undefined; });
};