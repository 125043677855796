import {CUSTOMER_MESSAGE_STATUS} from 'Pages/apps/default/const';

export const STATISTICS_FILTER_NO_CAMPAIGN = '-1';

export const STATISTICS_SUB_SECTION_MAP = {
	[CUSTOMER_MESSAGE_STATUS.DELIVERED]: [
		CUSTOMER_MESSAGE_STATUS.OPENED,
		CUSTOMER_MESSAGE_STATUS.LINK_OPENED,
		// TODO: ...
	],
	[CUSTOMER_MESSAGE_STATUS.OPENED]: [
		CUSTOMER_MESSAGE_STATUS.LINK_OPENED,
		// TODO: ...
	],
	// TODO: ...
};