import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import SelectInput, {SelectDynamicValueFunction} from 'Core/components/input/SelectInput';
import {find, omit} from 'lodash';
import PropTypes from 'prop-types';
import {
	CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS,
	CAMPAIGN_MESSAGE_CONTENT_TYPES
} from 'Pages/apps/default/projectPages/campaign/const';
import {getArray, getString, isset} from 'Core/helpers/data';
import IconOptionComponent from 'Core/components/input/SelectInput/options/IconOption';
import IconSingleValueComponent from 'Core/components/input/SelectInput/singleValues/IconSingleValue';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions();

class SenderSelectInput extends BaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			/** @type {ChannelListItemDataObject[]|null|undefined} */
			channelList: null,
			/** @type {boolean} */
			channelListLoading: false,
		};

		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		// Load select options
		await this.loadOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Re-load select options and clear the value if it does not exist in the list when certain props change
		if (
			this.props.clientId !== prevProps.clientId ||
			this.props.projectId !== prevProps.projectId ||
			this.props.messageType !== prevProps.messageType
		) {
			this.loadOptions()
				// Clear the value if it does not exist in the list
				.then(() => {
					const channel = find(getArray(this.state, 'channelList'), {value: this.getProp('value')});
					if (!isset(channel)) executeComponentCallback(this.props.onChange, null);
				});
		}
	}

	/**
	 * Load select options
	 * @param {boolean} [onMount=false] - Specifies if this method is called as part of the component mount cycle.
	 * @return {Promise<void>}
	 */
	async loadOptions(onMount = false) {
		const {clientId, projectId, messageType, fetchChannelsCodebookAction} = this.props;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);

		await this.setState({channelListLoading: true})
			.then(() => executeAbortableAction(fetchChannelsCodebookAction, projectId, messageType, clientId))
			.then(channelList => this.setState({channelList}))
			.finally(() => this.setState({channelListLoading: false}));
	}

	render() {
		const {messageType, channelSeparator} = this.props;
		const {channelList, channelListLoading} = this.state;

		return (
			<SelectInput
				{...omit(this.props, ['clientId', 'projectId', 'messageType', ...Object.keys(allActions)])}
				options={(
					!!messageType ?
						getArray(channelList).reduce(
							(acc, channel) => (
								channel.messageType === messageType ? 
									[
										...acc, 
										...getArray(channel, 'senders').map(s => ({
											value: channel.value + channelSeparator + s.sender, 
											label: `${channel.label} - ${s.sender}${!!s.senderName ? ` (${s.senderName})` : ''}`,
											messageType: channel.messageType,
										}))
									]
									: 
									acc
							), 
							[]
						)
						:
						getArray(channelList).reduce(
							(acc, channel) => (
								[
									...acc,
									...getArray(channel, 'senders').map(s => ({
										value: channel.value + channelSeparator + s.sender,
										label: `${channel.label} - ${s.sender}${!!s.senderName ? ` (${s.senderName})` : ''}`,
										messageType: channel.messageType,
									}))
								]
							),
							[]
						)
				)}
				components={{Option: IconOptionComponent, SingleValue: IconSingleValueComponent}}
				labelProps={{
					symbolPrefix: new SelectDynamicValueFunction(data =>
						getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.messageType, 'symbolPrefix'])
					),
					symbol: new SelectDynamicValueFunction(data =>
						getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.messageType, 'symbol'])
					),
				}}
				singleValueProps={{
					symbolPrefix: new SelectDynamicValueFunction(data =>
						getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.messageType, 'symbolPrefix'])
					), 
					symbol: new SelectDynamicValueFunction(data =>
						getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.messageType, 'symbol'])
					),
				}}
				isLoading={channelListLoading}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
SenderSelectInput.propTypes = {
	...SelectInput.propTypes,

	clientId: PropTypes.string,
	projectId: PropTypes.string,
	messageType: PropTypes.oneOf([...CAMPAIGN_MESSAGE_CONTENT_TYPES, '']),
	channelSeparator: PropTypes.string,
};

/**
 * Define component default values for own props
 */
SenderSelectInput.defaultProps = {
	...SelectInput.defaultProps,
	
	channelSeparator: ':', 
};

export default connect(null, allActions, null, {forwardRef: true})(SenderSelectInput);