import {get} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'tags';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@tags/reset',
	SET: '@tags/set',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * 
	 * @param {string[]} tags
	 * @return {{type: string, tags: string[]}}
	 */
	set: tags => ({type: REDUCER_TYPES.SET, tags}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/**
	 * List of all available tags that can be used for personalization of customer messages (notifications)
	 * @note null means that tags are not yet loaded and undefined means that there was an error while loading.
	 * @type {string[]|null|undefined}
	 */
	tags: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET: return {...state, tags: action.tags};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getTags: state => get(state, [reducerStoreKey, 'tags']),
};

export default reducer;