import styles from "./index.module.css";

import React from 'react';
import DataComponent, {executeComponentCallback} from 'Core/components/DataComponent';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from './actions';
import {find, get} from 'lodash';
import {getArray, trimArray} from 'Core/helpers/data';
import {ChannelDataObject} from 'Components/advanced/Channels/dataObjects';
import ConfirmDialog from 'Core/components/dialogs/ConfirmDialog';
import FormWrapper, {FormField} from 'Core/components/advanced/FormWrapper';
import Channel from './Channel';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {icon_font_create_symbol} from 'Config/app';
import {
	CAMPAIGN_MESSAGE_CONTENT_TYPE,
	CAMPAIGN_MESSAGE_CONTENT_TYPES
} from 'Pages/apps/default/projectPages/campaign/const';

class Channels extends DataComponent {
	constructor(props) {
		super(props, {
			/** @type {?ChannelDataObject[]} */
			data: null,
			/**
			 * List of all channels
			 * @note null means that the list is not loaded yet and undefined means that the list failed to load.
			 * @type {?ChannelSelectOptionDataObject[]|undefined}
			 */
			allChannelOptions: null,
		}, {
			translationPath: 'Channels',
			domPrefix: 'channels',
			enableLoadOnDataPropChange: true,
		});
		
		// Refs
		this.mainChannelRef = null;

		// Data methods
		this.getChannel = this.getChannel.bind(this);
		this.getAvailableChannelSelectOptions = this.getAvailableChannelSelectOptions.bind(this);
		this.getUsedChannelSelectOptions = this.getUsedChannelSelectOptions.bind(this);
		this.canAddChannel = this.canAddChannel.bind(this);

		// Data change handling methods
		this.handleChannelChangeAndUpdate = this.handleChannelChangeAndUpdate.bind(this);
		this.handleChannelDeleteAndUpdate = this.handleChannelDeleteAndUpdate.bind(this);
		this.handleChannelAddAndUpdate = this.handleChannelAddAndUpdate.bind(this);
		
		// Validation methods
		this.validatePublish = this.validatePublish.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);

		const {
			projectId, messageTypeFilter, autoSelectSingleChannelMessageTypeFilter, fetchAvailableChannelOptionsAction
		} = this.props;

		// Load available channels
		executeComponentCallback(this.props.onLoadingStart)
		this.executeAbortableActionMount(fetchAvailableChannelOptionsAction, projectId)
			.then(allChannelOptions =>
				!!allChannelOptions ? 
					allChannelOptions.filter(i => !messageTypeFilter || i.messageType === messageTypeFilter) :
					allChannelOptions
			)
			.then(allChannelOptions => this.setState({allChannelOptions}))
			.then(state => {
				const channels = getArray(state, 'allChannelOptions');
				const singleChannel = (channels.length === 1);
				const singleChannelSenders = (singleChannel ? getArray(channels[0], 'senders') : []);
				const singleSender = (singleChannelSenders.length === 1);
				const channelsForType = channels.filter(i =>
					!autoSelectSingleChannelMessageTypeFilter ||
					i.messageType === autoSelectSingleChannelMessageTypeFilter
				);
				const singleChannelForType = (channelsForType.length === 1);
				
				// Automatically select the main channel if there is only one channel in the list and 
				// 'autoSelectSingleChannel' is set to true
				if (!!this.getProp('autoSelectSingleChannel') && (singleChannel || singleChannelForType)) {
					if (!!this.mainChannelRef) {
						// Select the channel as the main channel if it is the only one
						if (singleChannel) this.mainChannelRef.handleChannelSelectAndUpdate(channels[0]);
						else if (singleChannelForType) this.mainChannelRef.handleChannelSelectAndUpdate(channelsForType[0]);
						
						// Open channel settings
						this.mainChannelRef.setState({showSettingsForm: true})
							// Select the channel sender if it is the only one
							.then(() => this.mainChannelRef.selectSingleSender());
					}
				}
				
				return executeComponentCallback(
					this.props.onLoadingEnd, 
					singleChannel ? channels[0] : null, 
					singleSender ? singleChannelSenders[0] : null,
				);
			});
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get selected channel by GUI ID
	 *
	 * @param {string|number} selector - GUI ID or index of the channel to get.
	 * @return {ChannelDataObject|undefined}
	 */
	getChannel(selector) {
		if (typeof selector === 'string') return find(getArray(this.getData()), {GUIID: selector});
		else if (typeof selector === 'number') return get(getArray(this.getData()), `[${selector}]`);
		return undefined;
	}

	/**
	 * Get the list of channel select options that have not been used yet
	 *
	 * @param {string} [GUIID] - GUIID of the currently selected channel that will also be included. This is done so that
	 * the select input will always have the currently selected item in options.
	 * @return {ChannelSelectOptionDataObject[]}
	 */
	getAvailableChannelSelectOptions(GUIID) {
		const currentChannel = this.getChannel(GUIID);
		/** @type {ChannelDataObject[]} */
		const usedChannels = getArray(this.getData());
		/** @type {ChannelSelectOptionDataObject[]} */
		const allChannelOptions = getArray(this.state, 'allChannelOptions');

		return allChannelOptions.filter(item =>
			(currentChannel && currentChannel.channelId === item.value) || !find(usedChannels, {channelId: item.value})
		);
	}

	/**
	 * Get the list of channels that are in use
	 * @return {ChannelSelectOptionDataObject[]}
	 */
	getUsedChannelSelectOptions() {
		/** @type {ChannelDataObject[]} */
		const usedChannels = getArray(this.getData());
		/** @type {ChannelSelectOptionDataObject[]} */
		const allChannelOptions = getArray(this.state, 'allChannelOptions');

		return allChannelOptions.filter(item => find(usedChannels, {channelId: item.value}));
	}

	/**
	 * Check if new channel can be added
	 * @return {boolean}
	 */
	canAddChannel() {
		/** @type {ChannelDataObject[]} */
		const usedChannels = getArray(this.getData());
		/** @type {ChannelSelectOptionDataObject[]} */
		const allChannelOptions = getArray(this.state, 'allChannelOptions');

		return usedChannels.length < allChannelOptions.length;
	}


	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle changes of the channel component
	 *
	 * @type {ChannelDataObject} channel - Updated channel data.
	 * @return {Promise<void>}
	 */
	async handleChannelChangeAndUpdate(channel) {
		this.clearValidationErrors(channel.GUIID)
			.then(() => {
				const errors = this.getValidationErrors();
				executeComponentCallback(
					this.props.onValidate,
					!(errors.constructor === Object && Object.keys(errors).length > 0)
				);
			});
		await this.updateStateArrayItem('data', {GUIID: channel.GUIID}, channel);
		
		// Update component so that other tabs can detect the changes on this tab
		this.update();
	}

	/**
	 * Handle deleting of the channel
	 *
	 * @param {string} GUIID - GUI ID of the channel to delete.
	 * @return {Promise<void>}
	 */
	handleChannelDeleteAndUpdate(GUIID) {
		const {openDialogAction, closeDialogAction} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('channelDeleteConfirmMessage'),
				onYes: async dialogGUIID => {
					closeDialogAction(dialogGUIID);

					this.clearValidationErrors(GUIID).then();
					await this.removeStateArrayItem('data', {GUIID});
					// Update component so that other tabs can detect the changes on this tab
					this.update();

					resolve();
				},
				onNo: dialogGUIID => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'channel-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 550
			})
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}

	/**
	 * Handle clicking on the add chanel button
	 * @note This method check if channel can be added to determine if it should actually add it or not.
	 * @return {Promise<void>}
	 */
	async handleChannelAddAndUpdate() {
		if (this.canAddChannel()) {
			await this.addStateArrayItem('data', new ChannelDataObject());
			this.update();
		}
	}


	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * Validate data for publishing
	 * @note This method is not called anywhere inside the component. It can be called from parent components to validate
	 * channels before publishing a message definition using this component's ref.
	 * @return {boolean}
	 */
	validatePublish() {
		let errors = {};

		/** @type {ChannelDataObject[]} */
		const channels = getArray(this.getData());

		// Check all channels for required additional settings (sender, senderName, ...)
		channels.forEach((channel, idx) => {
			// Check if main channel ID has been defined
			if (idx === 0 && !get(channel, 'channelId')) {
				if (!errors.hasOwnProperty(channel.GUIID)) errors[channel.GUIID] = {};
				errors[channel.GUIID]['mainChannelId'] = [this.t('required', 'validation')];
			}

			// Check all channels for required additional settings (sender, senderName, ...)
			switch (channel.messageType) {
				// For SMS and Viber channels, 'sender' is mandatory
				case CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS:
				case CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER:
					if (!channel.sender) {
						if (!errors.hasOwnProperty(channel.GUIID)) errors[channel.GUIID] = {};
						errors[channel.GUIID]['sender'] = [this.t('required', 'validation')];
					}
					break;
				// For email channels, 'sender' and 'senderName' are mandatory
				case CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL:
					if (!channel.sender) {
						if (!errors.hasOwnProperty(channel.GUIID)) errors[channel.GUIID] = {};
						errors[channel.GUIID]['sender'] = [this.t('required', 'validation')];
					}
					if (!channel.senderName) {
						if (!errors.hasOwnProperty(channel.GUIID)) errors[channel.GUIID] = {};
						errors[channel.GUIID]['senderName'] = [this.t('required', 'validation')];
					}
					break;
				// no default
			}
		});

		const validationErrors = (errors.constructor === Object && Object.keys(errors).length > 0) ? errors : false;
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		return !validationErrors;
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const data = this.getData();
		const allChannelOptions = get(this.state, 'allChannelOptions');

		// Render only if data and all channel select options are loaded. Channel options are needed in order to properly 
		// select initial channel ID values (channel select inputs)
		return (data !== null && allChannelOptions !== null);
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;

		const {className, readOnly, readOnlyNotice, supportPublish, messageContents, messageContentsLabel} = this.props;
		/** @type {ChannelDataObject[]} */
		const channels = getArray(this.getData());

		return (
			<div
				id={this.getDomId()}
				className={
					`${this.getOption('domPrefix')} ${styles['wrapper']} ${readOnly ? styles['readOnly'] : ''} ${className}`
				}
			>
				{readOnly ?
					<FormWrapper className="labels-only">
						{!!readOnlyNotice ? readOnlyNotice : null}
						{channels.map((c, idx) =>
							<Channel
								key={c.GUIID}
								className={styles['item']}
								index={idx}
								isMainChannel={idx === 0}
								usedChannels={channels}
								usedChannelOptions={this.getUsedChannelSelectOptions()}
								channelOptions={this.getAvailableChannelSelectOptions(c.GUIID)}
								value={c}
								readOnly={true}
								supportPublish={supportPublish}
								messageContents={messageContents}
								messageContentsLabel={messageContentsLabel}
							/>
						)}
					</FormWrapper>
				:
					<FormWrapper>
						<div className={styles['list']}>
							{channels.map((c, idx) =>
								<Channel
									key={c.GUIID}
									className={styles['item']}
									index={idx}
									isMainChannel={idx === 0}
									usedChannels={channels}
									usedChannelOptions={this.getUsedChannelSelectOptions()}
									channelOptions={this.getAvailableChannelSelectOptions(c.GUIID)}
									value={c}
									onChange={this.handleChannelChangeAndUpdate}
									onDelete={this.handleChannelDeleteAndUpdate}
									validationErrors={this.getValidationErrors(c.GUIID)}
									supportPublish={supportPublish}
									ref={node => { if (idx === 0 && !!node) this.mainChannelRef = node; }}
								/>
							)}
						</div>
						{this.canAddChannel() ?
							<FormField className={`channel-actions ${styles['actions']}`}>
								<Button
									big={true}
									icon={icon_font_create_symbol}
									label={this.t('addChannelBtn')}
									displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
									displayStyle={BUTTON_STYLE.SUBTLE}
									onClick={this.handleChannelAddAndUpdate}
								/>
							</FormField>
							: null
						}
					</FormWrapper>
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Channels.propTypes = {
	...DataComponent.propTypes,
	
	projectId: PropTypes.string,
	readOnly: PropTypes.bool,
	readOnlyNotice: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	messageTypeFilter: PropTypes.oneOf(CAMPAIGN_MESSAGE_CONTENT_TYPES),
	supportPublish: PropTypes.bool,
	autoSelectSingleChannel: PropTypes.bool,
	autoSelectSingleChannelMessageTypeFilter: PropTypes.oneOf(CAMPAIGN_MESSAGE_CONTENT_TYPES),
	// Content of all the message types
	// @note Used only in read-only mode to render a content preview button that will open a preview dialog.
	// @type {MessageContentDataObject[]}
	messageContents: PropTypes.arrayOf(PropTypes.object),
	// Message contents button label
	messageContentsLabel: PropTypes.string,
	
	onLoadingStart: PropTypes.func,
	// Arguments: {?ChannelDataObject} singleChannel, {?ChannelSelectOptionSenderDataObject} singleSender
	onLoadingEnd: PropTypes.func,
};

export default connect(null, getGlobalActions(actions), null, {forwardRef: true})(Channels);