import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getGlobalActions} from 'Core/helpers/redux';
import * as  actions from './actions';
import {cloneDeep, get} from 'lodash';
import {getArray, getNumber, isset} from 'Core/helpers/data';
import Icon from 'Core/components/display/Icon';
import {icon_font_error_symbol} from 'Config/app';
import Spinner from 'Core/components/display/Spinner';
import Label from 'Core/components/display/Label';
import NumberLabel from 'Core/components/display/NumberLabel';

import {CUSTOMER_MESSAGE_STATUS_CLASS_MAP} from 'Pages/apps/default/const';

class CampaignCustomerMessageCount extends BaseComponent {
	initialState = {
		/**
		 * Campaign customer messages count
		 * @type {?number|undefined}
		 */
		campaignCustomerMessagesCount: null,
	};
	
	constructor(props) {
		super(props);
		
		// Initialize local state
		this.state = cloneDeep(this.initialState);
		
		// Actions methods
		this.loadCampaignCustomerMessagesCount = this.loadCampaignCustomerMessagesCount.bind(this);
	}

	/**
	 * @inheritDoc
	 */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		const {campaignId} = this.props;
		
		await this.loadCampaignCustomerMessagesCount(campaignId, true);
		
		return Promise.resolve();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {campaignId} = this.props;
		const prevCampaignId = prevProps.campaignId;
		
		// Load campaign customer messages count if campaign ID changes
		if (campaignId !== prevCampaignId) this.loadCampaignCustomerMessagesCount(campaignId).then();
	}
	
	/**
	 * Fetch and load campaign customer messages count to local state
	 * 
	 * @param {string} campaignId - Campaign ID.
	 * @param {boolean} [mount] - Flag that specifies if this method was called as a part of the mount cycle.
	 * @return {Promise<Object>}
	 */
	loadCampaignCustomerMessagesCount(campaignId, mount) {
		const {fetchCampaignCustomerMessagesCountAction} = this.props
		const executeAbortableAction = (mount ? this.executeAbortableActionMount : this.executeAbortableAction);

		// Fetch campaign customer messages count
		return executeAbortableAction(fetchCampaignCustomerMessagesCountAction, campaignId)
			// Load campaign customer messages count to local state
			.then(response => this.setState({campaignCustomerMessagesCount: get(response, 'data')}));
	}

	render() {
		const {campaignCustomerMessagesCount} = this.state;
		
		return (
			<div className="campaign-customer-message-count-content">
				{
					campaignCustomerMessagesCount === null ?
						<div className="text-center"><Spinner /></div>
					: !isset(campaignCustomerMessagesCount) ?
						<div className="text-center"><Icon symbol={icon_font_error_symbol} className="error-color" /></div> 
					: 
						getArray(campaignCustomerMessagesCount).map(i =>
							<span key={i.status} className={`no-wrap tag ${CUSTOMER_MESSAGE_STATUS_CLASS_MAP[i.status]}`}>
								<Label content={this.t(i.status, 'constants.customerMessageStatus')} />:&nbsp;
								<NumberLabel number={getNumber(i, 'messageCount')} />
							</span>
						)
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CampaignCustomerMessageCount.propTypes = {
	campaignId: PropTypes.string,
};

export default connect(null, getGlobalActions(actions))(CampaignCustomerMessageCount);