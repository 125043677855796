/** @typedef {string} CampaignWizardPage */
export const CAMPAIGN_WIZARD_PAGE_INTRO = 'INTRO';
export const CAMPAIGN_WIZARD_PAGE_GENERAL = 'GENERAL';
export const CAMPAIGN_WIZARD_PAGE_CHANNELS = 'CHANNELS';
/** @enum {CampaignWizardPage} */
export const CAMPAIGN_WIZARD_PAGE = {
	INTRO: CAMPAIGN_WIZARD_PAGE_INTRO,
	GENERAL: CAMPAIGN_WIZARD_PAGE_GENERAL,
	CHANNELS: CAMPAIGN_WIZARD_PAGE_CHANNELS,
};
/**
 * @note Index of the pages is used as the page order in the wizard. Some pages might be skipped in certain conditions.
 * @type {CampaignWizardPage[]}
 */
export const CAMPAIGN_WIZARD_PAGES = [
	CAMPAIGN_WIZARD_PAGE_INTRO,
	CAMPAIGN_WIZARD_PAGE_GENERAL,
	CAMPAIGN_WIZARD_PAGE_CHANNELS,
];
