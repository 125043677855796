/** @typedef {string} ImportCustomersFromFileType */
export const IMPORT_CUSTOMERS_FROM_FILE_TYPE_DEFAULT = 'DEFAULT';
export const IMPORT_CUSTOMERS_FROM_FILE_TYPE_CUSTOMER_MESSAGES = 'CUSTOMER_MESSAGES';
export const IMPORT_CUSTOMERS_FROM_FILE_TYPE_BLACKLIST_ADD = 'BLACKLIST_ADD';
export const IMPORT_CUSTOMERS_FROM_FILE_TYPE_BLACKLIST_REMOVE = 'BLACKLIST_REMOVE';
/** @enum {ImportCustomersFromFileType} */
export const IMPORT_CUSTOMERS_FROM_FILE_TYPE = {
	DEFAULT: IMPORT_CUSTOMERS_FROM_FILE_TYPE_DEFAULT,
	CUSTOMER_MESSAGES: IMPORT_CUSTOMERS_FROM_FILE_TYPE_CUSTOMER_MESSAGES,
	BLACKLIST_ADD: IMPORT_CUSTOMERS_FROM_FILE_TYPE_BLACKLIST_ADD,
	BLACKLIST_REMOVE: IMPORT_CUSTOMERS_FROM_FILE_TYPE_BLACKLIST_REMOVE,
};
/** @type {ImportCustomersFromFileType[]} */
export const IMPORT_CUSTOMERS_FROM_FILE_TYPES = [
	IMPORT_CUSTOMERS_FROM_FILE_TYPE_DEFAULT,
	IMPORT_CUSTOMERS_FROM_FILE_TYPE_CUSTOMER_MESSAGES,
	IMPORT_CUSTOMERS_FROM_FILE_TYPE_BLACKLIST_ADD,
	IMPORT_CUSTOMERS_FROM_FILE_TYPE_BLACKLIST_REMOVE,
];


/** @typedef {string} ImportCustomersFileError */
export const IMPORT_CUSTOMERS_FILE_ERROR_COLUMN_VALIDATION_FAILED = 'COLUMN_VALIDATION_FAILED';
export const IMPORT_CUSTOMERS_FILE_ERROR_LOADING_CUSTOM_FIELDS_FAILED = 'LOADING_CUSTOM_FIELDS_FAILED';
export const IMPORT_CUSTOMERS_FILE_ERROR_FILE_VALIDATION_FAILED = 'FILE_VALIDATION_FAILED';
export const IMPORT_CUSTOMERS_FILE_ERROR_CUSTOMER_MESSAGES_CREATE_REQUEST_FAILED = 'MESSAGES_CREATE_REQUEST_FAILED';
/** @enum {ImportCustomersFileError} */
export const IMPORT_CUSTOMERS_FILE_ERROR = {
	COLUMN_VALIDATION_FAILED: IMPORT_CUSTOMERS_FILE_ERROR_COLUMN_VALIDATION_FAILED,
	LOADING_CUSTOM_FIELDS_FAILED: IMPORT_CUSTOMERS_FILE_ERROR_LOADING_CUSTOM_FIELDS_FAILED,
	FILE_VALIDATION_FAILED: IMPORT_CUSTOMERS_FILE_ERROR_FILE_VALIDATION_FAILED,
	MESSAGES_CREATE_REQUEST_FAILED: IMPORT_CUSTOMERS_FILE_ERROR_CUSTOMER_MESSAGES_CREATE_REQUEST_FAILED,
};