import {get} from "lodash";
import {getBoolean, getInteger, getObject, getString} from 'Core/helpers/data';
import {SORT_ORDER} from 'Core/const/global';

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'customerSegmentPreviewDialog';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@customerSegmentPreviewDialog/reset',
	SET_SEGMENT_DATA: '@customerSegmentPreviewDialog/set_segment_data',
	SET_SEGMENT_FILTER: '@customerSegmentPreviewDialog/set_segment_filter',
	CLEAR_SEGMENT_DATA: '@customerSegmentPreviewDialog/clear_segment_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	setCustomersSegmentData: customersSegmentData => ({
		type: REDUCER_TYPES.SET_SEGMENT_DATA,
		segmentData: customersSegmentData
	}),
	setCustomersSegmentFilter: filter => ({type: REDUCER_TYPES.SET_SEGMENT_FILTER, filter}),
	clearCustomersSegmentData: () => ({type: REDUCER_TYPES.CLEAR_SEGMENT_DATA}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	segmentData: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_SEGMENT_DATA: return {...state, segmentData: action.segmentData};
		case REDUCER_TYPES.SET_SEGMENT_FILTER: return {
			...state,
			segmentData: {...getObject(state.segmentData), filter: action.filter}
		};
		case REDUCER_TYPES.CLEAR_SEGMENT_DATA: return {...state, segmentData: initialState.segmentData};		

		default: return state;
	}
};

// Selectors
export const selectors = {
	getCustomersSegmentForImport: state => get(state, [reducerStoreKey, 'segmentData', 'data']),
	getCustomersSegmentPaginationForImport: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'segmentData', 'perPage'], 10),
		pageNo: getInteger(state, [reducerStoreKey, 'segmentData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'segmentData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'segmentData', 'isLastPage'])
	}),
	getCustomersSegmentSortForImport: state => ({
		sortBy: getString(state, [reducerStoreKey, 'segmentData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'segmentData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getCustomersSegmentFilterForImport: state => get(state, [reducerStoreKey, 'segmentData', 'filter'], null),
};

export default reducer;