import {hideLoading, hideLoadingFunction, showLoading} from 'Core/helpers/loading';
import {ioJsonManualAction} from 'Core/store/actions/io';

/**
 * Unsubscribe
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} token - Unsubscribe JWT token.
 * @return {function(*): *}
 */
export const unsubscribeAction = (abortCallback, token) => dispatch => {
	const loading = showLoading('.unsubscribe-page .unsubscribe-form');
	return ioJsonManualAction(
		abortCallback, 
		'defaultApi',
		`unsubscribe?token=${token}`,
		undefined,
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => {
			hideLoading(loading);
			return res;
		})
		.catch(() => {
			hideLoading(loading);
		});
};