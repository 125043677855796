import {v4} from 'uuid';

export class StatisticsItemDataObject {
	/**
	 * @param {Object} filter - Applied filter for statistics item.
	 * @param {StatisticsItemResultDataObject} result - Statistics result for the applied filter.
	 * @param {string} [GUIID] - GUI ID of the statistics item.
	 */
	constructor(filter = {}, result = null, GUIID = v4()) {
		this.filter = filter;
		this.result = result;
		this.GUIID = GUIID;
	}
}

export class StatisticsItemResultDataObject {
	/**
	 * @param {StatisticsItemResultByStatusDataObject[]} byStatusList - List of customer messages count by message 
	 * status.
	 * @param {number} clickThroughRate - Customer messages click-through rate.
	 * @param {number} total - Total number of customer messages.
	 * @param {number} [actualTotal] - Total number of customer messages that were actually sent. This is relevant only
	 * for SMS messages since they can have multiple parts and actual messages sent can be more than the total.
	 */
	constructor(byStatusList, clickThroughRate, total, actualTotal) {
		this.byStatusList = byStatusList;
		this.clickThroughRate = clickThroughRate;
		this.total = total;
		this.actualTotal = actualTotal;
	}
}

export class StatisticsItemResultByStatusDataObject {
	/**
	 * @param {CustomerMessageStatus} status - Customer message status.
	 * @param {number} count - Number of messages for customer message with specific status.
	 * @param {number} [actualCount] - Actual number of sent messages for customer message with specific status. This is 
	 * relevant only for SMS messages since they can have multiple parts and actual messages sent can be more than the 
	 * total.
	 */
	constructor(status, count, actualCount) {
		this.status = status;
		this.count = count;
		this.actualCount = actualCount;
	}
}

export class StatisticsItemResultSectionDataObject {
	/**
	 * @param {string} name - Section name and identifier.
	 * @param {number} value - Number of messages for the section.
	 * @param {StatisticsItemResultSectionDataObject[]} [sub=[]] - Sub-sections. Only top level sections can have subs.
	 */
	constructor(name, value, sub = []) {
		this.name = name;
		this.value = value;
		this.sub = sub;
	}
}