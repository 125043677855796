/** @typedef {string} CustomerCustomFiledType */
export const CUSTOMER_CUSTOM_FIELD_TYPE_TEXT = 'TEXT';
export const CUSTOMER_CUSTOM_FIELD_TYPE_INTEGER = 'INTEGER';
export const CUSTOMER_CUSTOM_FIELD_TYPE_DECIMAL = 'DECIMAL';
export const CUSTOMER_CUSTOM_FIELD_TYPE_DATE = 'DATE';
export const CUSTOMER_CUSTOM_FIELD_TYPE_DATE_TIME = 'DATE_TIME';
export const CUSTOMER_CUSTOM_FIELD_TYPE_YES_NO = 'YES_NO';
/** @enum {CustomerCustomFiledType} */
export const CUSTOMER_CUSTOM_FIELD_TYPE = {
	TEXT: CUSTOMER_CUSTOM_FIELD_TYPE_TEXT,
	INTEGER: CUSTOMER_CUSTOM_FIELD_TYPE_INTEGER,
	DECIMAL: CUSTOMER_CUSTOM_FIELD_TYPE_DECIMAL,
	DATE: CUSTOMER_CUSTOM_FIELD_TYPE_DATE,
	DATE_TIME: CUSTOMER_CUSTOM_FIELD_TYPE_DATE_TIME,
	YES_NO: CUSTOMER_CUSTOM_FIELD_TYPE_YES_NO,
};
/** @type {CustomerCustomFiledType[]} */
export const CUSTOMER_CUSTOM_FIELD_TYPES = [
	CUSTOMER_CUSTOM_FIELD_TYPE_TEXT,
	CUSTOMER_CUSTOM_FIELD_TYPE_INTEGER,
	CUSTOMER_CUSTOM_FIELD_TYPE_DECIMAL,
	CUSTOMER_CUSTOM_FIELD_TYPE_DATE,
	CUSTOMER_CUSTOM_FIELD_TYPE_DATE_TIME,
	CUSTOMER_CUSTOM_FIELD_TYPE_YES_NO,
];