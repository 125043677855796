import React from "react";
import PropTypes from "prop-types";
import PopupComponent, {PopupActionDataObject, PopupTabDataObject} from "Core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {cloneDeep, get, orderBy, pick, set} from 'lodash';
import {icon_font_close_symbol, icon_font_delete_symbol, icon_font_save_symbol} from "Config/app";
import {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../../../actions";
import {getArray, getBool, getNumber, getObject, getString, isset, trimArray} from 'Core/helpers/data';
import {MessageDefinitionDataObject} from "../../../../dataObjects";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {CAMPAIGN_STATUS, MESSAGE_DEFINITION_STATUS} from "../../../../const";
import Label, {LABEL_ICON_POSITION} from 'Core/components/display/Label';
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "../../../../reducer";
import {MESSAGE_DEFINITION_POPUP_MODE} from './const';
import {waitingFunctionCallback} from 'Core/helpers/function';
import ConfirmDialog from 'Core/components/dialogs/ConfirmDialog';
import {getCurrentProjectFromSession} from 'Helpers/project';
import {isSuccessful} from 'Core/helpers/io';
import {getCampaignItemTo} from 'Pages/apps/default/projectPages/campaign/helper';
import {reducerStoreKey as projectSelectorReducerStoreKey} from 'Layout/elements/ProjectSelector/reducer';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isGenerating: selectors[reducerStoreKey].areCustomerMessagesBeingGenerated(state),
	projectList: selectors[projectSelectorReducerStoreKey].getProjectList(state),
});

class MessageDefinitionPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	/**
	 * Map data fields to individual tabs
	 */
	tabDataMap = {
		MainTab: [
			'id', 'campaignId', 'status', 'startDate', 'messageCount', 'interval', 'deliveryStartTime', 'deliveryEndTime',
			'timeToLive', 'numberOfRetries', 'pauseBetweenRetries', 'timeZoneId', 'ordinal'
		],
		ChannelsTab: ['id', 'campaignId', 'status', 'channels', 'ordinal'],
		ContentTab: ['id', 'campaignId', 'status', 'personalized', 'messageContents', 'ordinal'],
		RecipientsTab: ['campaignId', 'status', 'ordinal'], // id is replaced by 'messageDefinitionId'
	};

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.MessageDefinitionPopup`,
			domPrefix: 'message-definition-popup',
			hideSingleTab: false,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',

			/**
			 * Flag that tels if dynamic action buttons are being calculated
			 * @type {boolean}
			 */
			dynamicActionButtonsLoading: false,
		};

		this.state = cloneDeep(this.initialState);
		
		// Data methods
		this.getItem = this.getItem.bind(this);

		// Action methods
		this.save = this.save.bind(this);
		this.saveAndClose = this.saveAndClose.bind(this);
		this.delete = this.delete.bind(this);
		this.publish = this.publish.bind(this);
		this.launchCampaign = this.launchCampaign.bind(this);
		this.deleteCampaign = this.deleteCampaign.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {data, isGenerating} = this.props;
		/** @type {MessageDefinitionStatus|''} */
		const status = getString(data, 'status');
		
		if (prevProps.isGenerating !== isGenerating) {
			this.dynamicActionButtons({status, isGenerating}).then();
			this.updateTab({...this.getTab('RecipientsTab'), loading: isGenerating}).then();
		}
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }

	
	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Set current tab
	 *
	 * @param {string} id - Tab ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	setCurrentTab(id) {
		const {data, isGenerating} = this.props;
		/** @type {MessageDefinitionStatus|''} */
		const status = getString(data, 'status');
		const {currentTabId} = this.state;
		
		if (currentTabId !== id) {
			return this.setState({currentTabId: id})
				.then(() => {
					if (!getBool(this.state, 'dynamicActionButtonsLoading')) {
						return this.dynamicActionButtons({status, isGenerating});
					} else {
						return waitingFunctionCallback(intervalId => {
							if (!getBool(this.state, 'dynamicActionButtonsLoading')) {
								clearInterval(intervalId);
								this.dynamicActionButtons({status, isGenerating}).then();
							}
						}, () => {}, 6, 10000);
					}
				})
				.then(() => this.state);
		}
		return Promise.resolve(this.state);
	}
	

	// Dynamic methods --------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {MessageDefinitionStatus|''} [status] - Message definition status.
	 * @param {boolean} [isGenerating] - Flag that specifies if customer messages are being generated. 
	 * @return {Promise<unknown>}
	 */
	async dynamicActionButtons({status, isGenerating}) {
		await this.setState({dynamicActionButtonsLoading: true});
		
		const mode = window.location.hash;
		const isWizardMode = (mode === MESSAGE_DEFINITION_POPUP_MODE.WIZARD);
		/** @type {CampaignItemDataObject} */
		const campaign = this.getProp('campaign');
		const {currentTabId} = this.state;
		
		// Remove actions before deciding which ones to display
		await this.removeActions([
			'close', 'delete', 'publish', 'save', 'save_and_close', 'delete_campaign', 'prev', 'next', 'launch_campaign',
		]);

		let actionsToAdd = [];
		if (
			campaign.status !== CAMPAIGN_STATUS.EXPIRED &&
			campaign.status !== CAMPAIGN_STATUS.COMPLETED &&
			status === MESSAGE_DEFINITION_STATUS.IN_PREPARATION
		) {
			if (!isWizardMode) {
				actionsToAdd.push(
					new PopupActionDataObject({
						id: 'close',
						action: this.close,
						buttonProps: {
							label: 'general.Close',
							icon: icon_font_close_symbol,
							displayStyle: BUTTON_STYLE.DEFAULT,
							displayType: BUTTON_DISPLAY_TYPE.SOLID,
						},
						ordinal: 0
					}),
					new PopupActionDataObject({
						id: 'delete',
						action: this.delete,
						buttonProps: {
							label: 'general.Delete',
							icon: icon_font_delete_symbol,
							displayStyle: BUTTON_STYLE.DEFAULT,
							displayType: BUTTON_DISPLAY_TYPE.SOLID,
						},
						ordinal: 10
					}),
					new PopupActionDataObject({
						id: 'save_and_close',
						action: this.saveAndClose,
						buttonProps: {
							label: this.getTranslationPath('update_and_close_action'),
							icon: icon_font_save_symbol,
							displayStyle: BUTTON_STYLE.ACTION,
						},
						ordinal: 20,
					}),
					new PopupActionDataObject({
						id: 'save',
						action: this.save,
						buttonProps: {
							label: this.getTranslationPath('update_action'),
							icon: icon_font_save_symbol,
							displayStyle: isWizardMode ? BUTTON_STYLE.DEFAULT : BUTTON_STYLE.ACTION,
						},
						ordinal: 30
					}),
					new PopupActionDataObject({
						id: 'publish',
						action: this.publish,
						buttonProps: {
							label: this.getTranslationPath('publish_action'),
							icon: 'bell-o',
							displayStyle: BUTTON_STYLE.ACTION,
							displayType: BUTTON_DISPLAY_TYPE.SOLID,
							tooltip: (
								isGenerating ?
									this.t('publish_customer_messages_generating_tooltip', pageConfig.translationPath) :
									!campaign.startDate || campaign.status !== CAMPAIGN_STATUS.ACTIVE ?
										this.t('cannot_publish_definition_inactive_campaign') :
										''
							),
						},
						disabled: (!campaign.startDate || campaign.status !== CAMPAIGN_STATUS.ACTIVE || isGenerating),
						ordinal: 40
					}),
				);
			} else {
				actionsToAdd = [
					new PopupActionDataObject({
						id: 'delete_campaign',
						action: this.deleteCampaign,
						buttonProps: {
							label: 'general.Cancel',
							icon: 'times',
							displayStyle: BUTTON_STYLE.DEFAULT,
							displayType: BUTTON_DISPLAY_TYPE.SOLID,
						},
						ordinal: 10
					}),
					new PopupActionDataObject({
						id: 'save',
						action: this.save,
						buttonProps: {
							label: this.getTranslationPath('update_action'),
							icon: icon_font_save_symbol,
							displayStyle: isWizardMode ? BUTTON_STYLE.DEFAULT : BUTTON_STYLE.ACTION,
						},
						ordinal: 30
					}),
					new PopupActionDataObject({
						id: 'prev',
						action: this.goToPreviousTab,
						buttonProps: {
							label: 'CampaignWizardDialog.goToPrevPageBtn',
							icon: 'arrow-left',
							displayStyle: BUTTON_STYLE.DEFAULT,
							displayType: BUTTON_DISPLAY_TYPE.SOLID,
						},
						ordinal: 34,
						disabled: !currentTabId || currentTabId === 'MainTab',
					}),
				];
				if (currentTabId !== 'OverviewTab') {
					actionsToAdd.push(
						new PopupActionDataObject({
							id: 'next',
							action: this.goToNextTab,
							buttonProps: {
								label: <Label
									icon="arrow-right"
									iconPosition={LABEL_ICON_POSITION.RIGHT}
									content={this.t('goToNextPageBtn', 'CampaignWizardDialog')}
								/>,
								displayStyle: BUTTON_STYLE.ACTION,
								displayType: BUTTON_DISPLAY_TYPE.SOLID,
							},
							ordinal: 38
						}),
					);
				} else {
					actionsToAdd.push(
						new PopupActionDataObject({
							id: 'launch_campaign',
							action: this.launchCampaign,
							buttonProps: {
								label: this.getTranslationPath('launch_campaign_action'),
								icon: 'rocket',
								displayStyle: BUTTON_STYLE.ACTION,
								displayType: BUTTON_DISPLAY_TYPE.SOLID,
								tooltip: (
									isGenerating ?
										this.t('publish_customer_messages_generating_tooltip', pageConfig.translationPath) :
										''
								),
							},
							disabled: isGenerating,
							ordinal: 40
						}),
					);
				}
			}
		} else {
			actionsToAdd.push(
				new PopupActionDataObject({
					id: 'close',
					action: this.close,
					buttonProps: {
						label: 'general.Close',
						icon: icon_font_close_symbol,
						displayStyle: BUTTON_STYLE.DEFAULT,
						displayType: BUTTON_DISPLAY_TYPE.SOLID,
					},
					ordinal: 0
				}),
			);
		}
		
		await this.addActions(actionsToAdd);
		await this.setState({dynamicActionButtonsLoading: false});

		return Promise.resolve();
	}

	/**
	 * Update dynamic tabs that depend on current state and props
	 * @param {MessageDefinitionStatus|''} [status] - Message definition status.
	 * @param {boolean} [isGenerating] - Flag that specifies if customer messages are being generated.
	 * @return {Promise<unknown>}
	 */
	dynamicTabs({status, isGenerating}) {
		/** @type {CampaignItemDataObject} */
		const campaign = this.getProp('campaign');
		const mode = window.location.hash;
		const isWizardMode = (mode === MESSAGE_DEFINITION_POPUP_MODE.WIZARD);

		return Promise.allSettled(
			this.getTabs().map(tab => this.updateTab({
				...tab,
				componentProps: {...tab.componentProps},
				visible: (
					tab.id !== 'OverviewTab' ||
					(
						campaign.status !== CAMPAIGN_STATUS.EXPIRED &&
						campaign.status !== CAMPAIGN_STATUS.COMPLETED &&
						status === MESSAGE_DEFINITION_STATUS.IN_PREPARATION && isWizardMode
					)
				)
			}))
		);
	}
	

	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const loading = this.showLoading();
		
		const {isGenerating} = this.props;
		/** @type {MessageDefinitionDataObject} */
		const data = this.getProp('data');
		/** @type {MessageDefinitionStatus|''} */
		const status = getString(data, 'status');
		
		// Add static actions that don't depend on current state or props
		let actions = [];
		await this.setActions(actions);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({status, isGenerating});
		
		// Add tabs
		let tabs = [
			new PopupTabDataObject({
				id: 'MainTab',
				icon: 'bell-o',
				label: this.getTranslationPath('MainTab.tab_title'),
				data: pick(data, this.tabDataMap.MainTab),
			}),
			new PopupTabDataObject({
				id: 'ChannelsTab',
				icon: 'code-fork',
				label: this.getTranslationPath('ChannelsTab.tab_title'),
				data: pick(data, this.tabDataMap.ChannelsTab),
			}),
			new PopupTabDataObject({
				id: 'RecipientsTab',
				icon: 'users',
				label: this.getTranslationPath('RecipientsTab.tab_title'),
				componentProps: {
					...pick(data, this.tabDataMap.RecipientsTab),
					messageDefinitionId: data.id,
				},
			}),
			new PopupTabDataObject({
				id: 'ContentTab',
				icon: 'file-text-o',
				label: this.getTranslationPath('ContentTab.tab_title'),
				data: pick(data, this.tabDataMap.ContentTab),
			}),
			new PopupTabDataObject({
				id: 'OverviewTab',
				icon: 'rocket',
				label: this.getTranslationPath('OverviewTab.tab_title'),
				preload: false,
				data,
				componentProps: {
					launchAction: this.launchCampaign,
				},
				visible: false,
			}),
		];
		await this.setTabs(tabs).then(this.importTabComponents);
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs({status, isGenerating});

		this.hideLoading(loading);
		
		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}
	
	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get current item data aggregated form all tabs
	 * @param {Object} [allTabsData] - All tabs data. If not specified, current data will be used.
	 * @return {MessageDefinitionDataObject}
	 */
	getItem(allTabsData) {
		const _allTabsData = (allTabsData ? allTabsData : this.getAllTabsData());
		return new MessageDefinitionDataObject(
			getString(_allTabsData, 'MainTab.id'),
			getString(_allTabsData, 'MainTab.campaignId'),
			getBool(_allTabsData, 'ContentTab.personalized'),
			getArray(_allTabsData, 'ChannelsTab.channels'),
			get(_allTabsData, 'MainTab.startDate'),
			getNumber(_allTabsData, 'MainTab.messageCount'),
			getString(_allTabsData, 'MainTab.interval'),
			getArray(_allTabsData, 'ContentTab.messageContents'),
			getString(_allTabsData, 'MainTab.deliveryStartTime'),
			getString(_allTabsData, 'MainTab.deliveryEndTime'),
			getNumber(_allTabsData, 'MainTab.timeToLive'),
			getNumber(_allTabsData, 'MainTab.numberOfRetries'),
			getNumber(_allTabsData, 'MainTab.pauseBetweenRetries'),
			getString(_allTabsData, 'MainTab.timeZoneId'),
			getString(_allTabsData, 'MainTab.status'),
			getNumber(_allTabsData, 'ordinal')
		);
	}
	

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'save'|string} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	save(allTabsData, event, actionId) {
		const {isGenerating, updateMessageDefinitionItemAction, addSuccessMessageAction} = this.props;

		return Promise.all([
			this.getTabRef('MainTab').validateTab(),
			this.getTabRef('ChannelsTab').validateTab(),
			this.getTabRef('ContentTab').validateTab()
		])
			.then(validArray => {
				if (!validArray.includes(false)) {
					/** @type {MessageDefinitionDataObject} */
					const item = this.getItem(allTabsData);
					
					if (actionId === 'save') {
						const loading = showLoading('#message-definition-popup'); 
						return this.executeAbortableAction(updateMessageDefinitionItemAction, item)
							.then(async response => {
								if (response) {
									// Update data in all tabs
									const tabs = this.getTabs();
									for (let i = 0; i < tabs.length; i++) {
										const tab = tabs[i];
										
										// Update recipients tab component props
										// @note Recipients tab uses props instead of props.data (like on other tabs) for basic 
										// message definition data (like id and status) because it handles its own data 
										// internally.
										if (tab.id === 'RecipientsTab') {
											// Update tab component props with the data from the response
											await this.updateTab({
												...tab, 
												componentProps: {
													...getObject(tab, 'componentProps'),
													...pick(response, this.tabDataMap[tab.id]),
													messageDefinitionId: response.id,
												}
											});
										}
										// Update tab data prop for all other tabs
										else {
											// Update tab data prop with current tab data so that changes will be detected
											await this.updateTab({...tab, data: pick(item, this.tabDataMap[tab.id])});
											// Update tab data prop with the data from the response
											await this.updateTab({...tab, data: pick(response, this.tabDataMap[tab.id])});
										}
									}

									// Update dynamic action buttons
									await this.dynamicActionButtons({status: response.status, isGenerating});

									addSuccessMessageAction(this.t('update_success_msg'));
								}
								hideLoading(loading);
								return response;
							})
							.catch(e => {
								hideLoading(loading);
								throw e;
							});
					} else {
						console.error(`Invalid message definition item popup save method: ${actionId}`);
					}
				}
				return Promise.resolve();
			});
	}

	/**
	 * Save item and close the popup if successful
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @return {Promise<*>}
	 */
	saveAndClose(allTabsData, event) {
		return this.save(allTabsData, event, 'save')
			.then(response => {
				if (isset(response)) this.close();
				return response;
			});
	}

	/**
	 * Delete item
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	delete(allTabsData, event, actionId) {
		const {deleteItemAction} = this.props;
		const item = this.getItem(allTabsData);
		if (item && item.id) return deleteItemAction(item).then(success => { if (success) this.close(); });
		return Promise.resolve();
	}

	/**
	 * Publish item
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'publish'|string} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	publish(allTabsData, event, actionId) {
		const {
			isGenerating, updateMessageDefinitionItemAction, publishMessageDefinitionAction, addSuccessMessageAction
		} = this.props;

		return Promise.all([
			this.getTabRef('MainTab').validatePublishTab(),
			this.getTabRef('ChannelsTab').validatePublishTab(),
			this.getTabRef('ContentTab').validatePublishTab()
		])
			.then(async validArray => {
				if (!validArray.includes(false)) {
					/** @type {MessageDefinitionDataObject} */
					const item = this.getItem(allTabsData);
					
					if (actionId === 'publish') {
						const loading = showLoading('#message-definition-popup');
						// Save the message definition before publishing it
						const saveResponse = await this.executeAbortableAction(updateMessageDefinitionItemAction, item);
						// If saving message definitions was successful
						if (saveResponse) {
							// Try to publish the message definition
							const publishResponse = await this.executeAbortableAction(
								publishMessageDefinitionAction, item.campaignId, item.id
							);
							// If publishing the message definition was successful
							if (publishResponse) {
								set(saveResponse, 'status', MESSAGE_DEFINITION_STATUS.PUBLISHED);
								
								// Update data in all tabs
								const tabs = this.getTabs();
								for (let i = 0; i < tabs.length; i++) {
									const tab = tabs[i];

									// Update recipients tab component props
									// @note Recipients tab uses props instead of props.data (like on other tabs) for basic 
									// message definition data (like id and status) because it handles its own data internally.
									if (tab.id === 'RecipientsTab') {
										// Update tab component props with the data from the response
										await this.updateTab({
											...tab,
											componentProps: {
												...getObject(tab, 'componentProps'),
												status: MESSAGE_DEFINITION_STATUS.PUBLISHED,
											}
										});
									}
									// Update tab data prop for all other tabs
									else {
										// Update tab data prop with current tab data so that changes will be detected
										await this.updateTab({...tab, data: pick(item, this.tabDataMap[tab.id])});
										// Update tab data prop with the data from the response
										await this.updateTab({...tab, data: pick(saveResponse, this.tabDataMap[tab.id])});
									}
								}

								// Update dynamic action buttons
								await this.dynamicActionButtons({status: saveResponse.status, isGenerating});

								addSuccessMessageAction(this.t('publish_success_msg'));
								
								hideLoading(loading);
								return publishResponse;
							}
						}

						hideLoading(loading);
						return undefined;
					} else {
						console.error(`Invalid message definition item popup publish method: ${actionId}`);
					}
				}
				return Promise.resolve();
			});
	}

	/**
	 * Launch the campaign and message definition at the same time
	 * @note This is used in the campaign creation wizard.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'launch_campaign'|string} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	launchCampaign(allTabsData, event, actionId) {
		const {
			projectList, redirectTo, launchCampaignAction, addSuccessMessageAction, openDialogAction, closeDialogAction
		} = this.props;
		/** @type {CampaignItemDataObject} */
		const campaign = this.getProp('campaign');
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromSession(projectList);
		
		// Validate all tabs for publishing
		return Promise.all([
			this.getTabRef('MainTab').validatePublishTab(),
			this.getTabRef('ChannelsTab').validatePublishTab(),
			this.getTabRef('ContentTab').validatePublishTab()
		])
			.then(async validArray => {
				if (!validArray.includes(false)) {
					if (actionId !== 'launch_campaign') {
						console.error(`Invalid message definition item popup launch method: ${actionId}`);
						return;
					}

					// Show confirm dialog
					return new Promise(resolve => {
						const dialogGUIID = openDialogAction('', ConfirmDialog, {
							message: this.t(
								'confirm_campaign_publish_message', '', '', {campaign: getString(campaign, 'name')}
							),
							supportHtml: true,
							onYes: async dialogGUIID => {
								// Close the confirm dialog
								closeDialogAction(dialogGUIID);
								
								const loading = showLoading('#message-definition-popup');

								/** @type {MessageDefinitionDataObject} */
								const item = this.getItem(allTabsData);
								const res = await this.executeAbortableAction(launchCampaignAction, campaign.id, item);
								if (isset(res)) addSuccessMessageAction(this.t('campaign_publish_success_msg'));
								
								hideLoading(loading);
								redirectTo(getCampaignItemTo(currentProject));
								this.close();
							},
							onNo: dialogGUIID => {
								closeDialogAction(dialogGUIID);
								resolve();
							}
						}, {
							id: 'launch-campaign-confirm-dialog',
							closeOnEscape: true,
							closeOnClickOutside: true,
							hideCloseBtn: true,
							maxWidth: 550
						})
						this.setOption(
							'dialogsToCloseOnUnmount',
							trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
						);
					});
				}
				return Promise.resolve();
			});
	}

	/**
	 * Delete campaign
	 * @note This is used in the campaign creation wizard.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'delete_campaign'|string} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	deleteCampaign(allTabsData, event, actionId) {
		const {
			projectList, redirectTo, deleteCampaignItemAction, openDialogAction, closeDialogAction, addSuccessMessageAction
		} = this.props;
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromSession(projectList);
		/** @type {CampaignItemDataObject} */
		const campaign = this.getProp('campaign');
		
		if (actionId !== 'delete_campaign') {
			console.error(`Invalid message definition item popup delete campaign method: ${actionId}`);
			return Promise.resolve();
		}

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_campaign_delete_message'),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteCampaignItemAction, campaign.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.t('campaign_delete_success_msg'));
								closeDialogAction(dialogGUIID);
								redirectTo(getCampaignItemTo(currentProject));
								this.close();
							} else {
								closeDialogAction(dialogGUIID);
							}
							return Promise.resolve(response);
						})
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'campaign-delete-confirm-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 600,
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * @inheritDoc
	 */
	renderGlobalActionLegend() {
		const mode = window.location.hash;
		const isWizardMode = (mode === MESSAGE_DEFINITION_POPUP_MODE.WIZARD);
		
		if (getString(this.props, 'data.status') === MESSAGE_DEFINITION_STATUS.PUBLISHED) return null;
		else return (
			<div className="legend">
				<span>
					<sup><strong>1</strong></sup>&nbsp;
					<Label content={this.t(`required_for_publish_legend${isWizardMode ? '_wizard' : ''}`)} />
				</span>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MessageDefinitionPopup.propTypes = {
	// Message definition campaign data
	// @type {CampaignItemDataObject}
	campaign: PropTypes.object,
	// Message definition data
	// @type {MessageDefinitionDataObject}
	data: PropTypes.object,
	// Function that will redirect to any URL
	redirectTo: PropTypes.func, // Arguments: {string} - URL path

	// Actions
	// @param {MessageDefinitionDataObject} item - Item to delete.
	// @return {Promise<void>}
	deleteItemAction: PropTypes.func,
	
	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(mapStateToProps, getPageActions(actions), null, {forwardRef: true})(MessageDefinitionPopup);