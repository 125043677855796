import styles from "./index.module.css";

import React from "react";
import PageDataComponent from 'Core/components/PageDataComponent';
import * as appConfig from "../config";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {getPageActions} from "Core/helpers/redux";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import * as actions from "./actions";
import Button, {BUTTON_STYLE} from 'Core/components/display/Button';
import {StatisticsItemDataObject} from 'Pages/apps/default/statistics/dataObjects';
import {getArray, trimArray} from 'Core/helpers/data';
import Label from 'Core/components/display/Label';
import {icon_font_create_symbol} from 'Config/app';
import {blinkOpacitySlowAnimation} from 'Core/helpers/animation';
import {hideLoading, showLoading} from 'Core/helpers/loading';
import StatisticsItem from 'Pages/apps/default/statistics/components/StatisticsItem';
import ConfirmDialog from 'Core/components/dialogs/ConfirmDialog';
import {reducerStoreKey} from './reducer';
import {scrollToElement} from 'Core/helpers/dom';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	data: selectors[reducerStoreKey].getStatistics(state),
	singleProject: selectors.projectSelector.getSingleProject(state),
});

class StatisticsPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			/** @type {StatisticsItemDataObject[]} */
			data: [],
		}, {
			layout: 'height',
			domPrefix: 'statistics-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			enableLoadOnDataPropChange: true,
			disableLoad: false,
			
			// Custom component options
			blinkSpeed: 500, // in ms
		}, 'page_title');
		
		// Data methods
		this.addStatisticsItem = this.addStatisticsItem.bind(this);
		this.moveStatisticsItem = this.moveStatisticsItem.bind(this);
		this.removeStatisticsItem = this.removeStatisticsItem.bind(this);
		this.updateStatisticsItem = this.updateStatisticsItem.bind(this);
	}

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }

	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Trigger component's onChange event with component's main data as param
	 * @param {Event} [event] - Optional JavaScript event that triggered the update.
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	update(event) {
		const {storeStatisticsAction} = this.props;
		storeStatisticsAction(this.getDataToReturn());
		
		return super.update(event);
	}

	/**
	 * Add new statistics card
	 * @return {Promise<void>}
	 */
	addStatisticsItem() {
		const {fetchStatisticsResultItemAction} = this.props;
		
		return this.insertItem(new StatisticsItemDataObject(), 0)
			.then(i => {
				blinkOpacitySlowAnimation(
					document.querySelector(`.statistics-item[data-guiid='${i.GUIID}']`), this.getOption('blinkSpeed')
				);
				scrollToElement(
					document.querySelector(`.statistics-item[data-guiid='${i.GUIID}']`), false, 0, '.page-content'
				);
				scrollToElement(
					document.querySelector(`.statistics-item[data-guiid='${i.GUIID}']`), false, 0, '.layout-content'
				);
				const loading = showLoading(`.statistics-item[data-guiid='${i.GUIID}']`);
				return this.executeAbortableAction(fetchStatisticsResultItemAction, {})
					.then(res => this.updateStatisticsItem(i, res))
					.then(() => hideLoading(loading));
			})
			.then(() => this.update())
	}

	/**
	 * Move statistics item in the list (change its index)
	 * 
	 * @param {StatisticsItemDataObject} item - Statistics item to move.
	 * @param {number} index - Index in the list where statistics item should move to.
	 */
	moveStatisticsItem(item, index) {
		const count = getArray(this.getData()).length;
		
		let indexToUse = index;
		// Prevent negative index
		if (index < 0) indexToUse = 0;
		// Prevent out-of-bounds index
		if (index > count - 1) indexToUse = count - 1;
			
		return this.updateStateArrayItemIndex('data', {GUIID: item.GUIID}, indexToUse)
			.then(() => blinkOpacitySlowAnimation(
				document.querySelector(`.statistics-item[data-guiid='${item.GUIID}']`), this.getOption('blinkSpeed')
			))
			.then(() => this.update());
	}

	/**
	 * Remove a statistics item
	 * @param {StatisticsItemDataObject} item - Statistics item to remove.
	 * @return {Promise<void>}
	 */
	removeStatisticsItem(item) {
		const {openDialogAction, closeDialogAction} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete_message'),
				onYes: async dialogGUIID => {
					closeDialogAction(dialogGUIID);

					return new Promise(resolve => {
						blinkOpacitySlowAnimation(
							document.querySelector(`.statistics-item[data-guiid='${item.GUIID}']`), this.getOption('blinkSpeed')
						);
						setTimeout(resolve, this.getOption('blinkSpeed'));
					})
						.then(() => this.removeItem({GUIID: item.GUIID}))
						.then(() => this.update())
						.then(() => resolve());
				},
				onNo: dialogGUIID => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'statistics-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 550
			})
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}

	/**
	 * Update a statistics item
	 *
	 * @param {StatisticsItemDataObject} item - Statistics item to update.
	 * @param {StatisticsItemDataObject} data - New data.
	 * @return {Promise<any>}
	 */
	updateStatisticsItem(item, data) {
		return this.setState(prevState => ({
			data: prevState.data.map(i => i.GUIID === item.GUIID ? {
				...i, filter: data?.filter || {}, 
				result: data?.result || null
			} : i)
		}))
			.then(() => this.update());
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button main-button">
						<Button
							big={true}
							icon={icon_font_create_symbol}
							label={this.t('create_new')}
							displayStyle={BUTTON_STYLE.ACTION}
							onClick={() => this.addStatisticsItem()}
						/>
					</div>
				</div>
			</h1>
		);
	}
	
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction, singleProject} = this.props;
		/** @type {StatisticsItemDataObject[]} */
		const data = getArray(this.getData());
	
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<div 
					className={`${styles['content']} no-select`}
					ref={node => { this.contentRef = node; }}
				>
					{data.length === 0 ?
						<div className={`card ${styles['createItem']}`}>
							<div className="card-content center-content" onClick={() => this.addStatisticsItem()}>
								<Label icon={icon_font_create_symbol} content={this.t('create_new')} />
							</div>
						</div>
						:
						data.map((i, idx) => 
							<StatisticsItem key={i.GUIID} 
								data={i} 
								index={idx} 
								itemsCount={data.length}
								updateStatisticsItem={this.updateStatisticsItem}
								moveStatisticsItem={this.moveStatisticsItem}
								removeStatisticsItem={this.removeStatisticsItem}
							/>
						)
					}
				</div>
			</div>
		), (!singleProject ? 'no-project-selector' : undefined), undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default withRouter(connect(mapStateToProps, getPageActions(actions))(StatisticsPage));