import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {reducerStoreKey} from "./reducer";
import {actionCreators} from "Core/store/reducers";
import {ioJsonAction, ioJsonFetchAction} from "Core/store/actions/io";
import * as itemFilterDataMap from "./dataMap/filter";
import * as listItemDataMap from "./dataMap/listItem";
import {getArray} from "Core/helpers/data";
import {isSuccessful} from "Core/helpers/io";
import {get} from "lodash";

/**
 * Reset customers list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetCustomersListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch customers list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchCustomersListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'customer/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};


/**
 * Load customers list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadCustomersListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#import-customers-from-list-table") : null);
	return fetchCustomersListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setCustomersListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set customer list filter in Redux store
 *
 * @param {Object} filter - Customer list filter to set.
 * @return {(function(*): void)|*}
 */
export const setCustomersListFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setCustomersListFilter(filter));
};

/**
 * Add recipients from customers list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string[]} customerIds - List of customer IDs.
 * @param {string} messageDefinitionId - DB ID of the messaged definition.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @return {function(*): *}
 */
export const submitCustomersListAction = (
	abortCallback, customerIds, messageDefinitionId, loadingOverlaySelector
) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/add-customer-messages',
		{customerIds, messageDefinitionId},
		hideLoadingFunction(loading),
	)(dispatch);
};

export {fetchCustomerCustomFieldsAction} from "Pages/apps/default/customers/actions";