import * as sectionConfig from "../config";
import {AclCheckDataObject} from "Core/acl";
import {getArray} from "Core/helpers/data";
import {rtrimChar} from "Core/helpers/string";

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = null;

/**
 * Access control settings
 * @type {AclCheckDataObject}
 */
export const access = new AclCheckDataObject(
	[...getArray(sectionConfig, 'access.permissions'), ],
	[...getArray(sectionConfig, 'access.userTypes'), ],
	undefined
);

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = `${rtrimChar(sectionConfig.routerPath, '/')}/request-demo`;
/**
 * Page router options used for router matching
 * @type {{[exact]: boolean, [strict]: boolean, [sensitive]: boolean}}
 */
export const routerOptions = {exact: true};

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = `${sectionConfig.translationPath}.RequestDemoPage`;