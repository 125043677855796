import {ioJsonAction} from 'Core/store/actions/io';

/**
 * Fetch customer message count per status for a specified campaign.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - Campaign ID.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchCampaignCustomerMessagesCountAction = (abortCallback, campaignId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-customer-messages-count',
		{campaignId}
	)(dispatch);
};