import React from "react";
import PageComponent from "Core/components/PageComponent";
import {getArray, getString, isset} from "Core/helpers/data";
import {find} from "lodash";
import Icon from "Core/components/display/Icon";
import Label, {LABEL_ICON_POSITION} from "Core/components/display/Label";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";
import {ProjectListItemDataObject} from 'DataObjects/project';
import * as projectPageConfig from "./dashboard/config";
import {getCurrentProjectFromUrl} from 'Helpers/project';
import CookieConsent from "Core/dataProtection/cookieConsent";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {deleteStorageKey, STORAGE_TYPE} from "Core/storage";

/**
 * An abstract component used to create project pages
 * @important Child components must have 'projectList' props defined!
 */
class ProjectPageComponent extends PageComponent {
	constructor(props, options = {}, title, titlePathPrefix = undefined) {
		super(props, options, title, titlePathPrefix);

		// Data methods
		this.projectExists = this.projectExists.bind(this);
		this.getProjectTo = this.getProjectTo.bind(this);
		
		// Render methods
		this.renderMissingProject = this.renderMissingProject.bind(this);
	}
	
	
	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Check if a project with the specified ID exists
	 * @return {boolean}
	 */
	projectExists() {
		/** @type {ProjectListItemDataObject[]} */
		const projectList = this.getProp('projectList');
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromUrl(projectList, this.getProp('location'));
		
		return (
			currentProject && currentProject instanceof ProjectListItemDataObject && 
			isset(find(getArray(projectList), {id: currentProject.id}))
		);
	}

	/**
	 * Get current project router 'to' value
	 * @note Router 'to' values is a router path with dynamic values replaced with actual values.
	 * @return {string}
	 */
	getProjectTo() {
		/** @type {ProjectListItemDataObject[]} */
		const projectList = this.getProp('projectList');
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromUrl(projectList, this.getProp('location'));
		
		return projectPageConfig.routerPath.replace(':projectId', getString(currentProject, 'id'));
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 * 
	 * @override Render empty title content if project does not exist.
	 */
	renderPageTitle() {
		const {title} = this.state;
		
		return (
			<h1 className="page-title">
				{this.projectExists() && title ? this.translate(title, this.titlePathPrefix) : ''}
			</h1>
		);
	}
	
	/**
	 * Content that will be rendered project page is opened for the project that does not exist in the project list
	 * @return {JSX.Element}
	 */
	renderMissingProject() {
		return (
			<div className="page-notice-wrapper">
				<Icon symbol="chain-broken" className="page-notice-title-icon" />
				<Label
					element="p"
					elementProps={{className: 'page-notice-title bold'}}
					content={this.t('project_error_title', 'AppsSection.DefaultApp.projectPages')}
				/>
				<Label
					element="p"
					elementProps={{className: 'page-notice'}}
					content={this.t('project_error_message', 'AppsSection.DefaultApp.projectPages')}
				/>
				<Button
					big={true}
					label={(
						<Label
							content={this.t('project_error_close_button', 'AppsSection.DefaultApp.projectPages')}
							icon="angle-right"
							iconPosition={LABEL_ICON_POSITION.RIGHT}
						/>
					)}
					displayStyle={BUTTON_STYLE.ACTION}
					onClick={() => {
						// Delete project ID session storage value (cookie)
						const projectIdCookie = new CookieData('necessary', 'projectId', STORAGE_TYPE.SESSION);
						if (CookieConsent.isAllowed(projectIdCookie)) deleteStorageKey('projectId', STORAGE_TYPE.SESSION);
						
						this.redirectToAuthorizedHome(); 
					}}
				/>
			</div>
		);
	}
}

export default ProjectPageComponent;