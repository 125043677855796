import {cloneDeep} from "lodash";
import {ChannelSelectOptionDataObject, ChannelSelectOptionSenderDataObject} from '../dataObjects';
import {getArray, getString} from 'Core/helpers/data';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ChannelSelectOptionDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new ChannelSelectOptionDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getString(result, 'messageType'),
		getString(result, 'mobileApplicationName'),
		getArray(result, 'senderDataList').map(s => new ChannelSelectOptionSenderDataObject(
			getString(s, 'sender', null, true),
			getString(s, 'senderName'),
		)),
		getString(result, 'providerId'),
	);

	return result;
}