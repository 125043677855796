import {cloneDeep} from "lodash";
import {resetObjectValues} from 'Core/helpers/data';

/**
 * Get output data from input data
 * @param {CappingItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		id: inputData.id,
		name: inputData.name,
		active: inputData.active,
		clientId: inputData.clientId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}