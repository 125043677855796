import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {v4} from "uuid";
import {cloneDeep, filter, find, sortBy} from "lodash";
import {isset} from "Core/helpers/data";
import Button from "Core/components/display/Button";

class SidebarComponent extends DataComponent {
	/**
	 *
	 * @param {object} props - Component props.
	 * @param {object} [initialState={}] - Initial state from child class that will override the default initial state.
	 * @param {SidebarComponentOptions} [options={}] - Component options from child class that will override the default
	 * options.
	 */
	constructor(props, initialState, options) {
		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {SidebarComponentOptions}
		 * @private
		 */
		const _options = {
			enableLoadOnDataPropChange: true,
			wholePropAsData: true,
			
			...(options ? cloneDeep(options) : {})
		}

		// Initialize initial state
		const _initialState = {
			/**
			 * Main component's date
			 */
			data: {
				/**
				 * Sidebar width
				 * @note This value will be changed on resize if 'resizable' prop is true.
				 * @type {number}
				 */
				width: undefined
			},
			
			/**
			 * List of all sidebar actions
			 * @type {SidebarActionDataObject[]}
			 */
			actions: [],

			...(initialState ? cloneDeep(initialState) : {})
		};
		
		super(props, _initialState, _options);

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Sidebar methods
		this.init = this.init.bind(this);
		this.hide = this.hide.bind(this);

		// Action methods
		this.getActions = this.getActions.bind(this);
		this.getSortedActions = this.getSortedActions.bind(this);
		this.getVisibleActions = this.getVisibleActions.bind(this);
		this.actionExists = this.actionExists.bind(this);
		this.getAction = this.getAction.bind(this);
		this.addAction = this.addAction.bind(this);
		this.removeAction = this.removeAction.bind(this);
		this.showAction = this.showAction.bind(this);
		this.hideAction = this.hideAction.bind(this);
		this.enableAction = this.enableAction.bind(this);
		this.disableAction = this.disableAction.bind(this);
		this.setActions = this.setActions.bind(this);
		this.addActions = this.addActions.bind(this);
		this.removeActions = this.removeActions.bind(this);
		this.showActions = this.showActions.bind(this);
		this.hideActions = this.hideActions.bind(this);
		this.enableActions = this.enableActions.bind(this);
		this.disableActions = this.disableActions.bind(this);
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this);

		// Render methods
		this.renderActionButtons = this.renderActionButtons.bind(this);
		this.renderSidebar = this.renderSidebar.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|void>} Promise that will resolve with the updated mount count that will be set in the 
	 * 'componentDidMount' method or undefined for default functionality where 'componentDidMount' will just reset the 
	 * mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);

		// Call the 'init' method to initialize the sidebar
		await this.init();

		return Promise.resolve();
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) { return { width: rawData?.width }; }

	// Sidebar methods --------------------------------------------------------------------------------------------------
	/**
	 * Initialize sidebar by specifying initial actions
	 *
	 * @note This is just a placeholder method, and it should be defined in child class if it is required. See the code
	 * reference to get an idea of how to implement the actual method.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 * @throws {AsyncMountError}
	 */
	async init() {
		return Promise.resolve(this.state);

		// Code reference for actual method implementation
		/*
		await this.setActions([
			new SidebarActionDataObject(...),
			new SidebarActionDataObject(...)
		]);
		
		return Promise.resolve(this.state);
		 */
	}

	/**
	 * Hide sidebar method
	 */
	hide() {
		console.error('SidebarComponent: "hide" method called but not implemented!');
		// Implement this method in the component that extends this abstract component
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get all actions
	 * @return {SidebarActionDataObject[]}
	 */
	getActions() { return this.state.actions; }

	/**
	 * Get properly sorted list of actions
	 * @return {SidebarActionDataObject[]}
	 */
	getSortedActions() { return sortBy(this.state.actions, ['ordinal']); }

	/**
	 * Get the properly sorted list of visible actions
	 * @return {SidebarActionDataObject[]}
	 */
	getVisibleActions() { return filter(this.getSortedActions(), {visible: true}); }

	/**
	 * Check if action exists in the list of actions
	 * @note This will check the whole list.
	 *
	 * @param {string} id - Action ID.
	 * @return {boolean} True if action exists in the list of actions, false otherwise.
	 */
	actionExists(id) { return !!find(this.getActions(), {id}); }

	/**
	 * Get action by ID
	 *
	 * @param {string} id - Action ID.
	 * @return {SidebarActionDataObject|null} - Action or null if action does not exist.
	 */
	getAction(id) {
		const action = find(this.getActions(), {id});
		return (action ? action : null);
	}

	/**
	 * Add new action
	 *
	 * @param {SidebarActionDataObject} action - Action to add to the list of actions.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	addAction(action) {
		if (this.actionExists(action.id)) {
			console.log(
				`%cCould not add sidebar action because acton with the same ID '${action.id}' already exists!`, 'color: red'
			);
			return Promise.resolve(this.state);
		} else {
			return this.addStateArrayItem('actions', action);
		}
	}

	/**
	 * Remove action from the action list
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	removeAction(id) { return this.removeStateArrayItem('actions', {id}); }

	/**
	 * Show action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	showAction(id) { return this.updateStateArrayItem('actions', {id}, {visible: true}); }

	/**
	 * Hide action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	hideAction(id) { return this.updateStateArrayItem('actions', {id}, {visible: false}); }

	/**
	 * Enable action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	enableAction(id) { return this.updateStateArrayItem('actions', {id}, {disabled: false}); }

	/**
	 * Disable action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	disableAction(id) { return this.updateStateArrayItem('actions', {id}, {disabled: true}); }

	/**
	 * Set actions
	 * @note This will clear the action list before adding new actions to it.
	 *
	 * @param {SidebarActionDataObject[]} [actions=[]] - List of actions to add. If not specified or empty array, action
	 * list will just be cleared.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'addAction' methods for each added 
	 * action.
	 */
	setActions(actions = []) {
		return this.setState({actions: []}).then(() => this.addActions(actions));
	}

	/**
	 * Add multiple actions
	 *
	 * @param {SidebarActionDataObject[]} actions - List of actions to add.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'addAction' methods for each added
	 * action.
	 */
	addActions(actions) { return Promise.all(actions.map(action => this.addAction(action))); }

	/**
	 * Remove multiple actions from the list of actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'removeAction' methods for each removed
	 * action.
	 */
	removeActions(ids) { return Promise.all(ids.map(id => this.removeAction(id))); }

	/**
	 * Show multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'showAction' methods for each showed
	 * action.
	 */
	showActions(ids) { return Promise.all(ids.map(id => this.showAction(id))); }

	/**
	 * Hide multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'hideAction' methods for each hidden
	 * action.
	 */
	hideActions(ids) { return Promise.all(ids.map(id => this.hideAction(id))); }

	/**
	 * Enable multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'enableAction' methods for each enabled
	 * action.
	 */
	enableActions(ids) { return Promise.all(ids.map(id => this.enableAction(id))); }

	/**
	 * Disable multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'disableAction' methods for each disabled
	 * action.
	 */
	disableActions(ids) { return Promise.all(ids.map(id => this.disableAction(id))); }

	/**
	 * Handle sidebar action button click
	 * @note This method will trigger 'onAction' event with following arguments: action id, action function result or 
	 * resolved data if action function returns a promise.
	 *
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {SidebarActionDataObject} action - Action's 'action' method will be called with following arguments: action 
	 * button's click event, action 'id'.
	 */
	handleActionButtonClick(event, action) {
		const actionResult = action.action(event, action.id);

		// If action function result is a promise
		if (actionResult instanceof Promise) {
			actionResult.then((...resolveData) => {
				executeComponentCallback(this.props.onAction, action.id, ...resolveData);
			})
		}
		// If action function result is not a promise
		else {
			executeComponentCallback(this.props.onAction, action.id, actionResult);
		}
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() { return (this.getProp('visible', null) !== null); }
	
	/**
	 * Render action buttons
	 * @return {JSX.Element|null}
	 */
	renderActionButtons() {
		const actions = this.getVisibleActions();
		
		return (
			actions.length > 0 ?
				<div className="sidebar-action-buttons">
					{actions.map((action, index) =>
						<Button
							key={index}
							{...action.buttonProps}
							disabled={action.disabled}
							onClick={e => this.handleActionButtonClick(e, action)}
						/>
					)}
				</div>
				: null
		);
	}
	
	/**
	 * Use this method to render sidebar structure with action buttons
	 * 
	 * @param {React.Component|JSX.Element|string} content - Main sidebar content to render.
	 * @param {string} className - Additional sidebar component CSS class name.
	 * @return {JSX.Element}
	 */
	renderSidebar(content, className = '') {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		return (
			<div 
				id={this.getProp('id', `sidebar-${this.getId()}`)}
				className={
					`sidebar-component ${className}` +
					(this.getProp('onTop') === true ? ' on-top' : '') +
					(this.getProp('visible') === false ? ' hidden' : '') +
					(this.getProp('shrank') === true ? ' shrank' : '')
				}
				ref={this.setWrapperRef}
			>
				<div className="sidebar-overlay" onClick={() => this.hide()} />
				{this.renderActionButtons()}
				<div className="sidebar-content">
					{content ? content : null}
				</div>
			</div>
		);
	}
}


/**
 * Sidebar action data object
 * @note Data object are objects use only for defining data formats and storing data.
 */
export class SidebarActionDataObject {
	/**
	 * @param {string} id - Unique ID of the sidebar action. This is a required param.
	 * @param {Function} action - Action function to execute on action button click. This is a required param.
	 * @param {{
	 *    [id]: string, [className]: string, [type]: string, [displayType]: string, [displayStyle]: string, 
	 *    [big]: boolean, [name]: string, [value]: string, [autofocus]: boolean, [disabled]: boolean, [hide]: boolean,
	 *    [label]: string, [icon]: string, [spinIcon]: boolean, [onClick]: Function
	 * }} buttonProps - Action Button component props. This is a required param.
	 * @param {number} ordinal - Order position of the action button.
	 * @param {boolean} visible - Flag that determines if action button for this action will be visible.
	 * @param {boolean} disabled - Flag that determines if action button for this action will be disabled.
	 * @param {string} GUIID - Unique GUI ID of the sidebar action.
	 */
	constructor(id, action, buttonProps, ordinal = 0, visible = true, disabled = false, GUIID = v4()) {
		// Check for missing params
		if (!isset(id) || !id) {
			throw new Error(`${this.constructor.name} error! Required 'id' param is missing or empty.`);
		}
		if (!isset(action) || !action) {
			throw new Error(`${this.constructor.name} error! Required 'action' param is missing or empty.`);
		}
		if (!isset(buttonProps) || !buttonProps) {
			throw new Error(`${this.constructor.name} error! Required 'buttonProps' param is missing or empty.`);
		}

		this.id = id;
		this.action = action;
		this.buttonProps = buttonProps;
		this.visible = visible;
		this.disabled = disabled;
		this.ordinal = ordinal;
		this.GUIID = GUIID;
	}
}

// Type definitions
/**
 * @typedef {Object} SidebarComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {boolean} [forceFastLoad=false] - Flag that determines if load queue will use the fast mode. WARNING: Load
 * queue fast mode does not guarantee linear loads for non async load calls, for example if load method s called within
 * a for loop. Async calls should work properly.
 * @property {boolean} [disableLoad=false] - Flag that determines if load functionality is disabled. If true 'load'
 * method will not load data from props into local state.
 * @property {boolean} [enableLoadOnDataPropChange=false] - Flag that determines if data will be loaded from props to
 * local state every time data prop changes. This flag will be ignored if 'disableLoad' is true.
 * @property {string} [dataPropAlias=''] - Main data prop alisa. This is used by child components that need to have a
 * different prop field for main data, like input components that use 'value' instead of 'data'.
 * @property {string} [originalDataPropAlias=''] - Original data prop alisa. This is used by child components that need
 * to have a different prop field for original data, like input components that use 'originalValue' instead of
 * 'originalData'.
 * @property {boolean} [wholePropAsData=false] - Flag that determines if whole props will be used as main data on load
 * instead of 'data' prop or 'dataPropAlias' options.
 */


/**
 * Define component's own props that can be passed to it by parent components
 */
SidebarComponent.propTypes = {
	// Sidebar wrapper element id attribute
	id: PropTypes.string,
	// Sidebar wrapper element class attribute
	className: PropTypes.string,
	// Flag that determines if sidebar is visible or hidden
	// @note If null, 'renderSidebar' method will return null (component should not be rendered).
	visible: PropTypes.bool,
	// Flag that determines if sidebar is on top of other layout elements
	// @note If on top, sidebar won't displace other layout elements. It will be rendered on top of them.
	onTop: PropTypes.bool,
	// Flag that determines if sidebar will be shrank
	shrank: PropTypes.bool,
	// TODO: Flag that determines if sidebar will be resizable
	// @description If true, sidebar border can be used to resize the sidebar. 'onResize' event will be triggered after 
	// sidebar has been resized (onMouseUp) and new sidebar width will be passed as an argument.
	resizable: PropTypes.bool,
	// TODO: Sidebar width
	// @note Changing this value after component mounts will override previous value set by resize method if 'resizable'
	// is set to true.
	width: PropTypes.number,

	// Events
	onAction: PropTypes.func,
	onResize: PropTypes.func,
};

/**
 * Define component default values for own props
 */
SidebarComponent.defaultProps = {
	id: '',
	className: '',
	visible: true,
	onTop: false,
	shrank: false,
	resizable: false
};

export default SidebarComponent;