/**
 * Data object representing the customers list item for import
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class ImportCustomersListItemDataObject {
	/**
	 * @param {string} id - Customer's DB ID.
	 * @param {string} crmId - Customer's external CRM ID.
	 * @param {string} firstName - Customer's first name.
	 * @param {string} lastName - Customer's last name.
	 * @param {string} phone - Customer's mobile phone number.
	 * @param {string} email - Customer's email address.
	 */
	constructor(id, crmId, firstName, lastName, phone, email) {
		this.id = id;
		this.crmId = crmId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.phone = phone;
		this.email = email;
	}
}