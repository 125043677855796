import {ltrimChar, rtrimChar, trimChar} from './string';
import {app_authorized_home_page_router_path, app_home_page_router_path} from "Config/app";
import Country from "../country";
import {getArray, getObject, getString} from "Core/helpers/data";

/**
 * Get URL based on root URL retrieved by env variable or current location if env variable is empty (webpack dev server)
 * @note This function will always return a URL with the slash ('/') at the end.
 *
 * This is a JavaScript function that does not use any React or router methods or variables.
 *
 * @param {string} [path=''] - URL path that will be added to the root URL. It accepts paths with or without the slash
 * ('/') at the beginning and both will be treated the same. If empty, this function will return the root path.
 * @return {string} URL with slash ('/') at the end.
 */
export const getUrl = (path = '') => {
	const root = (
		process.env.NODE_ENV === 'development' ?
			(
				window.location.origin.toString().endsWith('/') ?
					window.location.origin.toString() :
					`${window.location.origin.toString()}/`
			) + (
				process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME && process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME !== '/' ?
					ltrimChar(process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME, '/') + '/' : ''
			)
			:
			(
				process.env.PUBLIC_URL.endsWith('/') ?
					process.env.PUBLIC_URL :
					`${process.env.PUBLIC_URL}/`
			)
	);
	return root + ltrimChar(path, '/');
}

/**
 * Get URL based on root URL of the home app assessable when user is logged in retrieved by env variable or current 
 * location if env variable is empty (webpack dev server)
 * @note This function will always return a URL with the slash ('/') at the end.
 *
 * This is a JavaScript function that does not use any React or router methods or variables.
 *
 * @param {string} [path=''] - URL path that will be added to the root app URL. It accepts paths with or without the 
 * slash ('/') at the beginning and both will be treated the same. If empty, this function will return the root path.
 * @return {string} URL with slash ('/') at the end.
 */
export const getAuthorizedUrl = (path = '') => {
	const root = (
		process.env.NODE_ENV === 'development' ?
			(
				window.location.origin.toString().endsWith('/') ?
					window.location.origin.toString() :
					`${window.location.origin.toString()}/`
			) + (
				process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME && process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME !== '/' ?
					ltrimChar(process.env.REACT_APP_BROWSER_ROUTER_BASE_NAME, '/') + '/' : ''
			)
			:
			(
				process.env.PUBLIC_URL.endsWith('/') ?
					process.env.PUBLIC_URL :
					`${process.env.PUBLIC_URL}/`
			)
	);
	return `${trimChar(root, '/')}/${trimChar(app_authorized_home_page_router_path, '/')}/${ltrimChar(path, '/')}`;
}

/**
 * Get current full URL
 * @note This is a JavaScript function that does not use any React or router methods or variables.
 * @return {string}
 */
export const getCurrentUrl = () => window.location.href;

/**
 * Get country based image URL
 * @note Images should be located inside a public directory under lowercase country code subdirectory (for example 
 * 'public/srb').
 * 
 * @param {CountryObj} country - Country to use.
 * @param {string} path - Image path relative to the country img directory.
 * @param {string} [imgDirPath='img'] - Path of the main img directory where country based subdirectories will be 
 * located.
 * @return {string}
 */
export const getCountryImgUrl = (country, path, imgDirPath = 'img') => 
	`${getUrl(`${rtrimChar(imgDirPath, '/')}/${country.code.toLowerCase()}`)}/${path}`;

/**
 * Get app's current country based image URL
 * @note Images should be located inside a public directory under lowercase country code subdirectory (for example
 * 'public/srb').
 *
 * @param {string} path - Image path relative to the country img directory.
 * @param {string} [imgDirPath='img'] - Path of the main img directory where country based subdirectories will be
 * located.
 * @return {string}
 */
export const getAppCountryImgUrl = (path, imgDirPath = 'img') => 
	getCountryImgUrl(Country.getAppCountry(), path, imgDirPath);

/**
 * Redirect to a specific URL
 * @note This is a pure JavaScript redirect function, and it does not use any router functionality.
 * @param {string} url - URL to redirect to
 * @param {boolean} [newTab=false] - Flag that determines if url will be opened in a new browser tab.
 */
export const redirectToUrl = (url, newTab = false) => {
	if (newTab) window.open(url, '_blank').focus();
	else window.location.href = url; 
};

/**
 * Redirect to a path relative to the root URL
 * @note This is a pure JavaScript redirect function, and it does not use any router functionality.
 * @param {string} [path=''] - URL path that will be added to be root URL before redirecting. It accepts paths with or 
 * without the slash ('/') at the beginning and both will be treated the same. If empty, this function will redirect to 
 * the root path.
 * @param {boolean} [newTab=false] - Flag that determines if url will be opened in a new browser tab.
 */
export const redirectToPath = (path = '', newTab = false) => {
	redirectToUrl(getUrl(ltrimChar(path, '/')), newTab);
};

/**
 * Redirect to app's home page
 * @note This is a JavaScript redirect function, and it does not use any router functionality.
 * @see app config 'app_home_page_router_path'.
 * @param {boolean} [newTab=false] - Flag that determines if url will be opened in a new browser tab.
 */
export const redirectToHome = (newTab = false) => {
	redirectToPath(rtrimChar(app_home_page_router_path, '/'), newTab);
}

/**
 * Redirect to app's home page if user is logged in
 * @note This is a JavaScript redirect function, and it does not use any router functionality.
 * @see app config 'app_authorized_home_page_router_path'.
 * @param {boolean} [newTab=false] - Flag that determines if url will be opened in a new browser tab.
 */
export const redirectToAuthorizedHome = (newTab = false) => {
	redirectToPath(rtrimChar(app_authorized_home_page_router_path, '/'), newTab);
}

/**
 * Get router path
 * @note This method will replace any route param definitions (like :id) with the actual param values from the match
 * object.
 * @return {string} Router path where dynamic values are replaced with actual values without the trailing slash.
 */
export const getRouterPathUrl = (rawRouterPath, matchObject) => {
	const routeParams = getObject(matchObject, 'params');

	let result = getString(rawRouterPath);
	// Replace all dynamic params (like :id) with the param value from the router match object
	getArray(Object.keys(routeParams)).forEach(paramName => {
		result = result.replace(`:${paramName}`, getString(routeParams, paramName));
	});
	return rtrimChar(result, '/');
};