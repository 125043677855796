import {cloneDeep} from 'lodash';
import {getArray, getNumber} from 'Core/helpers/data';
import {StatisticsItemResultDataObject} from "../dataObjects";
import * as statusDataMap from "./status";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {StatisticsItemResultDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new StatisticsItemResultDataObject(
		getArray(result, 'customerMessageCountByStatusList').map(statusDataMap.input),
		getNumber(result, 'clickThroughRate'),
		getNumber(result, 'totalMessageCount'),
		getNumber(result, 'totalActualMessageCount'),
	);

	return result;
}

// @note There is no output function since list is read only.