import {ioJsonAction, ioJsonFetchAction, ioJsonManualAction} from "Core/store/actions/io";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {getArray, getNumber} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as customerMessageListDataMap from "./dataMap/customerMessageList";
import * as customerMessageFilterDataMap from "./dataMap/customerMessageFilter";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";
import {getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Reset all recipients Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetRecipientsAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
}

/**
 * Fetch customer message list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} messageDefinitionId - DB ID of the messaged definition.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchCustomerMessageListAction = (
	abortCallback, messageDefinitionId, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/search-customer-message',
		'',
		filter,
		{},
		pageNo,
		perPage,
		sortBy,
		sortDir,
		{messageDefinitionId},
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				// Load the previous page if there are no items on the page after one has been deleted
				if (pageNo > 1 && getArray(responseData, 'data').length === 0) {
					return fetchCustomerMessageListAction(
						abortCallback, messageDefinitionId, filter, (pageNo - 1), perPage, sortBy, sortDir
					)(dispatch);
				}

				return ({
					...responseData,
					filter: customerMessageFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => customerMessageListDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load customer message list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} messageDefinitionId - DB ID of the messaged definition.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadCustomerMessageListAction = (
	abortCallback, messageDefinitionId, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchCustomerMessageListAction(
		abortCallback, messageDefinitionId, filter, pageNo, perPage, sortBy, sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setCustomerMessageListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear customer message list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearCustomerMessageListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearCustomerMessageListData());
}

/**
 * Check if customer messages are being generated
 * @note Messages are being generated asynchronously on backend.
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} messageDefinitionId - DB ID of the messaged definition to check for customer messages generating.
 * @param {boolean} [ignoreErrors=false] - Flag that specifies if response errors will be ignored.
 * @return {function(*): Promise<boolean>}
 */
export const areCustomerMessagesGeneratingAction = (
	abortCallback, messageDefinitionId, ignoreErrors = false
) => dispatch => {
	const action = (ignoreErrors ? ioJsonManualAction : ioJsonAction);
	return action(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-import-customer-message-requests',
		{id: messageDefinitionId},
	)(dispatch)
		.then(res => isSuccessful(res) ? (getArray(res, 'data').length > 0) : undefined)
		.catch(() => { /* Do nothing */ });
};

/**
 * Delete a single customer message
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Customer message DB ID.
 * @param {Date} creationDate - Customer message creation date.
 * @return {function(*): *}
 */
export const deleteCustomerMessageAction = (abortCallback, id, creationDate) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/delete-message-for-id-and-creation-date',
		{
			customerMessageId: getNumber(id),
			creationDate: getDateString(creationDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		},
	)(dispatch);
};

/**
 * Delete multiple customer messages
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {CustomerMessageListItemDataObject[]} list - Customer messages list.
 * @return {function(*): *}
 */
export const deleteCustomerMessagesAction = (abortCallback, list) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/delete-multiple-customer-messages',
		{
			customerMessages: getArray(list).map(i => ({
				customerMessageId: getNumber(i.id),
				creationDate: getDateString(i.creationDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
			}))
		}
	)(dispatch);
};

/**
 * Delete all customer messages that are in status "IN_PREPARATION"
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} messageDefinitionId - DB ID of the messaged definition.
 * @return {function(*): *}
 */
export const deleteCustomerMessagesInPreparationAction = (abortCallback, messageDefinitionId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/delete-messages-for-message-def',
		{messageDefinitionId},
	)(dispatch);
};