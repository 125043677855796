import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "../../BaseComponent";
import PropTypes from "prop-types";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import {RADIO_INPUT_LAYOUT} from "./const";
import {getString} from "Core/helpers/data";
import {omit} from "lodash";
import {getCssSizeString} from "Core/helpers/dom";

/**
 * Radio input component
 * @description Radio input component using Button component as radio buttons allowing easy configuration and styling.
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 */
class RadioInput extends BaseComponent {
	constructor(props) {
		super(props, {
			dataPropAlias: 'value',
			domPrefix: 'radio-input-component'
		});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, options, value, emptyValue, layout, formControlStyle, buttonProps, isClearable, clearValue, size
		} = this.props;
		
		return (
			<div 
				id={this.getDomId()}
				className={
					`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className}` +
					` ${styles['wrapper']} ${layout} ${styles[layout]}`
				}
				style={{fontSize: getCssSizeString(size)}}
			>
				{options.map((option, index) =>
					<Button 
						key={index}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						displayStyle={BUTTON_STYLE.ACTION}
						icon={
							(option.value === value || (!value && option.value === emptyValue)) ? 
								['circle-thin', 'circle'] : 
								'circle-thin'
						}
						iconProps={{className: styles['icon']}}
						label={option.label}
						className={
							`${this.getOption('domPrefix')}-option ${styles['option']} ` +
							(option.value === value || (!value && option.value === emptyValue) ? 'selected ' : ' ') +
							`${getString(buttonProps, 'className')}`
						}
						onClick={() => {executeComponentCallback(this.props.onChange, option.value)}}
						{...omit(buttonProps, ['className'])}
					/>
				)}

				{
					isClearable ?
						<Button
							className={
								`${this.getOption('domPrefix')}-clear-btn ${styles['clearBtn']} ` +
								`${this.getOption('domPrefix')}-option ${styles['option']} ` +
								`${getString(buttonProps, 'className')}`
							}
							icon="times-circle"
							iconProps={{className: styles['clearIcon']}}
							label={layout === RADIO_INPUT_LAYOUT.STACKED ? this.t('Clear', 'general') : undefined}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							displayStyle={BUTTON_STYLE.SUBTLE}
							onClick={() => {executeComponentCallback(this.props.onChange, clearValue)}}
						/>
						: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
RadioInput.propTypes = {
	// Component's wrapper element id
	id: PropTypes.string,
	// Component's wrapper element class
	className: PropTypes.string,
	// Radio options that will be rendered as buttons
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any,
		label: PropTypes.string
	})),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	// Option value of the option that will be selected if value is empty
	emptyValue: PropTypes.any,
	// Radio button layout
	// @see RADIO_INPUT_LAYOUT const.
	layout: PropTypes.string,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Radio button props
	buttonProps: PropTypes.object,
	// Is the radio value clearable
	isClearable: PropTypes.bool,
	// Value to be used when clearing the radio value
	// @note Only relevant if 'isClearable' prop is true.
	clearValue: PropTypes.any,
	// Size of the wrapper text
	// @note All style values are relative to this size (em used in CSS).
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	
	// Events
	onChange: PropTypes.func, // Arguments: {any} - 'value' property of the selected RadioInputOptionObject object.
};

/**
 * Define component default values for own props
 */
RadioInput.defaultProps = {
	className: '',
	options: [],
	layout: RADIO_INPUT_LAYOUT.ALIGNED,
	formControlStyle: true,
	buttonProps: {},
	isClearable: false,
	clearValue: undefined,
	size: 'inherit',
};

/**
 * RadioInput component option object
 * @note Use this object to easily create RadioInput component options (passed as 'options' prop). Each option will be
 * rendered as a button.
 */
export class RadioInputOptionObject {
	/**
	 * Constructor
	 * @param {string} label - Radio option (button) label.
	 * @param {any} value - Radio option value.
	 */
	constructor(label, value) {
		this.label = label;
		this.value = value;
	}
}
export * from "./const";
export default RadioInput;