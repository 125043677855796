import styles from '../../index.module.css';
import '../../index.css';

import React from 'react';
import DataComponent from 'Core/components/DataComponent';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getPageActions} from 'Core/helpers/redux';
import * as actions from '../../actions';
import * as pageConfig from '../../config';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import SimpleStaticSearch, {
	DynamicPropFunction,
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from 'Core/components/advanced/SimpleStaticSearch';
import {STANDARD_DATE_TIME_FORMAT} from 'Core/const/datetime';
import ProjectSelectInput from 'Components/input/ProjectSelectInput';
import {hideLoading, showLoading} from 'Core/helpers/loading';
import {getArray, getBoolean, getNumber, getString, sortStringsByMap} from 'Core/helpers/data';
import Label from 'Core/components/display/Label';
import {getStatisticsItemResultData} from 'Pages/apps/default/statistics/helpers';
import NumberLabel from 'Core/components/display/NumberLabel';
import {getColorFromPalette} from 'Helpers/color';
import Icon from 'Core/components/display/Icon';
import {CUSTOMER_MESSAGE_STATUS, CUSTOMER_MESSAGE_STATUSES} from 'Pages/apps/default/const';
import CampaignSelectInput from 'Components/input/CampaignSelectInput';
import MessageDefinitionSelectInput from 'Components/input/MessageDefinitionSelectInput';
import {get} from 'lodash';
import ChannelSelectInput from 'Components/input/ChannelSelectInput';
import DateLabel from 'Core/components/display/DateLabel';
import {
	ChannelListItemDataObject,
	MessageDefinitionDataObject
} from 'Pages/apps/default/projectPages/campaign/dataObjects';
import {STATISTICS_FILTER_NO_CAMPAIGN} from 'Pages/apps/default/statistics/const';
import {icon_font_help_circle_symbol} from 'Config/app';

class StatisticsItem extends DataComponent {
	constructor(props) {
		super(props, {
			/** @type {StatisticsItemDataObject} */
			data: null,
			/**
			 * Total number of customer messages used to calculate bars scale
			 * @type {number}
			 */
			total: 0,
			/**
			 * Flag that specifies if filter is collapsed
			 * @type {boolean}
			 */
			collapsed: false,
		}, {
			translationPath: pageConfig.translationPath,
			enableLoadOnDataPropChange: true,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: [
				'onChange', 'onEnterKey', 'moveStatisticsItem', 'removeStatisticsItem', 'updateStatisticsItem'
			],
			
			// Custom component options
			colorSeed: '2',
		});

		// Refs
		this.filterRef = [];
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/** @inheritDoc */
	defaultLoad(data) {
		return super.defaultLoad(data)
			.then(() => this.setState({total: getNumber(data, 'result.total')}));
	}

	/**
	 * Calculate percentage of the specified customer messages count based on total messages
	 * 
	 * @type {number} value - Customer messages count to calculate percentage of the total for.
	 * @return {number|NaN}
	 */
	calculatePercentage(value) {
		const {total} = this.state;
		if (typeof value !== 'number') {
			console.error(
				`Failed to calculate percentage of ${this.getValue('name')} notifications! ` +
				`Invalid input. Please provide valid numeric values.`, value
			);
			return 0;
		}
		return (value / total) * 100;
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const data = this.getData();
		// Data must be set in order for the component to be rendered. New items have an empty data object and existing 
		// items have a fully loaded data object. Data can be null while existing items' data is being loaded in which 
		// case noting should be rendered (loading overlay should be handled by the action that is loading the data). 
		return (data !== null);
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		const {
			index, itemsCount, moveStatisticsItem, removeStatisticsItem, updateStatisticsItem, 
			fetchStatisticsResultItemAction
		} = this.props;
		/** @type {StatisticsItemDataObject} */
		const data = this.getData();
		
		const getSectionTotal = section => section.sub.reduce(
			(prev, cur) => prev + getNumber(cur.value), getNumber(section.value)
		);
		
		return (
			<div className={`statistics-item card`} data-guiid={data.GUIID}>
				<div className="card-header with-toolbar">
					<div className="card-title">&nbsp;</div>
					<div className={`toolbar ${styles['toolbar']}`}>
						<Button
							id={styles['movePrevBtn']}
							tooltip={this.t('move_prev_tooltip')}
							icon="chevron-left"
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => moveStatisticsItem(data, index - 1)}
							disabled={index === 0}
						/>
						<Button
							id={styles['moveNextBtn']}
							tooltip={this.t('move_next_tooltip')}
							icon="chevron-right"
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => moveStatisticsItem(data, index + 1)}
							disabled={index === itemsCount - 1}
						/>
						<Button
							tooltip={this.t('clear_filter_tooltip')}
							icon="eraser"
							iconProps={{symbolPrefix: 'icofont-'}}
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => {
								if (this.filterRef) this.filterRef.clearFields();
							}}
							hide={getBoolean(this.state, 'collapsed')}
						/>
						<Button
							tooltip={this.t('filter_tooltip')}
							icon="search"
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => {
								if (this.filterRef) this.filterRef.toggleContent();
							}}
						/>
						<Button
							tooltip={this.t('remove_tooltip')}
							icon="trash"
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => removeStatisticsItem(data)}
						/>
					</div>
				</div>
				<div className="card-filter">
					<SimpleStaticSearch
						showTitle={false}
						styleName="compact"
						className={"main-search statistics-item-main-search"}
						layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
						buttonProps={{
							displayStyle: BUTTON_STYLE.DEFAULT
						}}
						options={[
							new SimpleStaticSearchOptionObject(
								'dateFrom',
								this.t('dateFromField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
								}
							),
							new SimpleStaticSearchOptionObject(
								'dateTo',
								this.t('dateToField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
								}
							),
							new SimpleStaticSearchOptionObject(
								'deliveryChannel',
								this.t('deliveryChannelField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
								{
									simpleValue: false,
									isClearable: true,
								},
								ChannelSelectInput,
							),
							new SimpleStaticSearchOptionObject(
								'messageStatus',
								this.t('messageStatusField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
								{
									options: CUSTOMER_MESSAGE_STATUSES.map(s => ({
										label: this.t(s, 'constants.customerMessageStatus'),
										value: s
									})),
									isClearable: true,
								},
							),
							new SimpleStaticSearchOptionObject(
								'project',
								this.t('projectField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
								{
									simpleValue: false,
									useGlobalProjectList: true,
									isClearable: true,
								},
								ProjectSelectInput,
							),
							new SimpleStaticSearchOptionObject(
								'campaign',
								this.t('campaignField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
								{
									simpleValue: false,
									isClearable: true,
									projectIdRequired: true,
									projectId: new DynamicPropFunction(currentFilter => getString(currentFilter, 'project.id')),
									includeNonCampaignOption: true,
									nonCampaignOption: {name: this.t('no_campaign'), id: STATISTICS_FILTER_NO_CAMPAIGN}
								},
								CampaignSelectInput,
							),
							new SimpleStaticSearchOptionObject(
								'messageDefinition',
								this.t('messageDefinitionField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
								{
									simpleValue: false,
									isClearable: true,
									campaign: new DynamicPropFunction(currentFilter => get(currentFilter, 'campaign')),
								},
								MessageDefinitionSelectInput
							),
						]}
						value={data.filter}
						enableToolbar={false}
						enableResetButton={false}
						enableClearButton={false}
						onChange={filter => {
							const loading = showLoading(`.statistics-item[data-guiid='${data.GUIID}']`, true, false, '2em');
							return this.executeAbortableAction(fetchStatisticsResultItemAction, filter)
								.then(res => updateStatisticsItem(data, res))
								.then(() => hideLoading(loading));
						}}
						onToggle={collapsed => this.setState({collapsed})}
						ref={node => {
							this.filterRef = node;
						}}
					/>
				</div>
				<div className={`${styles['appliedFilter']} tags`}>
					{sortStringsByMap(
						getArray(Object.keys(data.filter)), 
						[
							'dateFrom', 
							'dateTo', 
							'deliveryChannel', 
							'messageStatus', 
							'project', 
							'campaign', 
							'messageDefinition'
						]
					).map(filterKey =>
						get(data, `filter.${filterKey}`, null) === null ? null :
							<span key={filterKey} className={`${styles['appliedFilterItem']} tag`}>
								<Label content={this.t(`${filterKey}Field`)}/>:&nbsp;
									<strong>{
										CUSTOMER_MESSAGE_STATUSES.includes(getString(data, `filter.${filterKey}`)) ?
											<Label content={this.t(
												getString(data, `filter.${filterKey}`), 'constants.customerMessageStatus')
											}/>
										: get(data, `filter.${filterKey}`) instanceof Date ?
											<DateLabel inputDate={get(data, `filter.${filterKey}`)}/>
										: typeof get(data, `filter.${filterKey}`) === 'object' ?
											get(data, `filter.${filterKey}`) instanceof ChannelListItemDataObject ?
												<Label content={getString(data, `filter.${filterKey}.label`)}/>
											: get(data, `filter.${filterKey}`) instanceof MessageDefinitionDataObject ?
												<Label content={
													getString(data, `filter.campaign.name`) + ' - ' +
													getString(data, `filter.${filterKey}.ordinal`)
												}/>
											:
												<Label content={getString(data, `filter.${filterKey}.name`)}/>
										:
											<Label content={getString(data, `filter.${filterKey}`)}/>
									}</strong>&nbsp;
								</span>
					)}
				</div>
				<div className="card-content">
					{data.result ?
						<>
							{getNumber(data, 'result.total') > 0 ?
								<div className={styles['result']}>
									{getStatisticsItemResultData(
										data.result, 
										!!getString(data, 'filter.messageStatus'),
										(
											!!getString(data, 'filter.messageStatus') || 
											[
												CUSTOMER_MESSAGE_STATUS.DELIVERED,
												CUSTOMER_MESSAGE_STATUS.OPENED,
											].includes(getString(data, 'filter.messageStatus'))
										)
									).map(section =>
										<div key={section.name} className={styles['resultSection']}>
											<div className={styles['title']}>
												<Label
													icon={
														section.name === 'clickThroughRate' ?
															icon_font_help_circle_symbol :
															undefined
													}
													tooltip={
														section.name === 'clickThroughRate' ?
															this.t('clickThroughRateFieldTooltip') : 
															undefined
													}
													content={
														section.name === 'total' ? this.t('total') :
														section.name === 'clickThroughRate' ? this.t('clickThroughRateField') :
														this.t(section.name, 'constants.customerMessageStatusResult')
													}
												/>: <NumberLabel number={getSectionTotal(section)}/>{
													section.name !== 'total' && section.name !== 'clickThroughRate' ?
														<> (<NumberLabel
															number={this.calculatePercentage(getSectionTotal(section))}
														/>%)</>
													: section.name === 'clickThroughRate' ? <>%</>
													: null
											}
											</div>

											<div className={styles['content']}>
												<div className={styles['barWrapper']}>
													<div className={styles['bar']}>
														<div
															key={section.name}
															className={styles['subBar']}
															style={{
																width: `${this.calculatePercentage(section.value)}%`,
																backgroundColor: getColorFromPalette(
																	section.sub.length + 1, 0, this.getOption('colorSeed')
																),
															}}
														/>
														{section.sub.map((sub, subIdx) =>
															<div
																key={sub.name}
																className={styles['subBar']}
																style={{
																	width: `${this.calculatePercentage(sub.value)}%`,
																	backgroundColor: getColorFromPalette(
																		section.sub.length + 1, subIdx, this.getOption('colorSeed')
																	)
																}}
															/>
														)}
													</div>
												</div>
											</div>

											{
												section.sub.length > 0 ?
													<div className={styles['legend']}>
														<div key={section.name} className={styles['legendItem']}>
															<span style={{
																backgroundColor: getColorFromPalette(
																	section.sub.length + 1, 0, this.getOption('colorSeed')
																)
															}}/>
															<label>
																<Label
																	content={
																		this.t(section.name, 'constants.customerMessageStatusResult') +
																		': '
																	}
																/><NumberLabel number={section.value}/>
															</label>
														</div>
														{section.sub.map((sub, subIdx) =>
															<div key={sub.name} className={styles['legendItem']}>
																<span style={{
																	backgroundColor: getColorFromPalette(
																		section.sub.length + 1, subIdx, this.getOption('colorSeed')
																	)
																}}/>
																<label>
																	<Label
																		content={
																			`${sub.name === 'total' ? this.t('total') :
																			this.t(sub.name, 'constants.customerMessageStatusResult')}: `
																		}
																	/><NumberLabel number={sub.value}/>
																</label>
															</div>
														)}
													</div>
													: null
											}
										</div>
									)}
								</div>
								:
								<div className={`${styles['noResult']}`}>
									<div className={styles['noResultInner']}>
										<Icon
											symbol="search-stock"
											symbolPrefix="icofont-"
											className={`${styles['noResultIcon']}`}
										/>
										<Label
											element="p"
											elementProps={{className: styles['noResultText']}}
											content={this.t('no_results_title')}
										/>
									</div>
								</div>
							}
						</>
						: null
					}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
StatisticsItem.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number.isRequired,
	itemsCount: PropTypes.number.isRequired,

	moveStatisticsItem: PropTypes.func.isRequired,
	removeStatisticsItem: PropTypes.func.isRequired,
	updateStatisticsItem: PropTypes.func.isRequired,
};

export default connect(null, getPageActions(actions))(StatisticsItem);