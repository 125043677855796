import "./default.style.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import CheckboxInput from "Core/components/input/CheckboxInput";
import {getBoolean, getString} from "Core/helpers/data";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_delete_symbol} from "Config/app";
import {KEY_VALUE_LIST_DISPLAY_TYPE, KEY_VALUE_LIST_DISPLAY_TYPES} from "../const";
import {FormField} from "Core/components/advanced/FormWrapper";
import SelectInput from "Core/components/input/SelectInput";
import TextInput from "Core/components/input/TextInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {v4} from "uuid";

class KeyValueListItem extends DataComponent {
	constructor(props) {
		super(props, {
			/**
			 * @type {KeyValueListItemDataObject}
			 */
			data: new KeyValueListItemDataObject(),
		}, {
			translationPath: 'KeyValueListComponent.ListItem',
			domPrefix: 'key-value-list-item-component',
			enableLoadOnDataPropChange: true,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: [
				// Ignore event props because anonymous functions (that are usually used for them) are generated on each 
				// parent component render, so they are always different and optimization will not work. This is true for 
				// any function prop.
				'onChange', 'onEnterKey', 'onSelect', 'onDelete'
			],
			// Include only state fields relevant for rendering the component
			// @note This component extends and abstract component that might have some state fields.
			optimizedUpdateIncludeState: ['data']
		});

		// Render methods
		this.renderCustomComponent = this.renderCustomComponent.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Trigger component's onChange event with component's main data as param
	 * @param {Event} [event] - Optional JavaScript event that triggered the update.
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	update(event) { return executeComponentCallback(this.props.onChange, this.getDataToReturn(), event); }


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render custom option component
	 *
	 * @param {JSX.Element|Element} Component - Custom component to render.
	 * @param {'key'|'value'} field - Field name.
	 * @param {Object} props - Custom component props.
	 * @return {JSX.Element}
	 */
	renderCustomComponent(Component, field, props) {
		return (
			<Component
				value={this.getValue(field, null)}
				onChange={v => this.handleValueChangeAndUpdate(field, v)}
				{...props}
			/>
		);
	}

	render() {
		const {
			styleName, className, selectable, selected, keyDisplayType, keyDisplayOptions, keyCustomComponent,
			valueDisplayType, valueDisplayOptions, valueCustomComponent, canRemove, showLabels, keyLabel, valueLabel, 
		} = this.props;

		return (
			<div
				id={this.getDomId()}
				className={
					`${this.getOption('domPrefix')} ${styleName}-style ${className} ` +
					`${selectable ? 'selectable' : 'not-selectable'}`
				}
			>
				{
					selectable ?
						<div className={`select-wrapper`}>
							<CheckboxInput
								asButton={true}
								checked={getBoolean(selected)}
								size={18}
								onChange={() => executeComponentCallback(this.props.onSelect, this.getValue('GUIID'))}
							/>
						</div>
						: null
				}

				<div className={`key-value-wrapper`}>
					<div className={`key-wrapper`}>
						{showLabels && keyLabel ? <div className="key-label-inset">{keyLabel}</div> : null}
						<FormField
							labelPosition={FORM_FIELD_LABEL_POSITION.NONE}
						>
							{
								keyDisplayType === KEY_VALUE_LIST_DISPLAY_TYPE.TEXT ?
									<TextInput
										name={'key'}
										value={getString(this.getValue('key'), '', '', true)}
										onChange={this.handleInputChangeAndUpdate}
										{...keyDisplayOptions}
									/>
								: keyDisplayType === KEY_VALUE_LIST_DISPLAY_TYPE.SELECT ?
									getBoolean(keyDisplayOptions, 'isCreatable') ?
										<SelectInput
											simpleValue={true}
											value={this.getValue('key', null)}
											onChange={v => this.handleValueChangeAndUpdate('key', v)}
											onCreateOption={v => this.handleValueChangeAndUpdate('key', v)}
											{...keyDisplayOptions}
										/>
										:
										<SelectInput
											simpleValue={true}
											value={this.getValue('key', null)}
											onChange={v => this.handleValueChangeAndUpdate('key', v)}
											{...keyDisplayOptions}
										/>
								: keyDisplayType === KEY_VALUE_LIST_DISPLAY_TYPE.CUSTOM ?
									this.renderCustomComponent(keyCustomComponent, 'key', keyDisplayOptions)
								: null
							}
						</FormField>
					</div>

					<div className={`value-wrapper`}>
						{showLabels && valueLabel ? <div className="value-label-inset">{valueLabel}</div> : null}
						<FormField
							labelPosition={FORM_FIELD_LABEL_POSITION.NONE}
						>
							{
								valueDisplayType === KEY_VALUE_LIST_DISPLAY_TYPE.TEXT ?
									<TextInput
										name={'value'}
										value={getString(this.getValue('value'), '', '', true)}
										onChange={this.handleInputChangeAndUpdate}
										{...valueDisplayOptions}
									/>
								: valueDisplayType === KEY_VALUE_LIST_DISPLAY_TYPE.SELECT ?
									<SelectInput
										simpleValue={true}
										menuPlacement="auto"
										value={this.getValue('value', null)}
										onChange={v => this.handleValueChangeAndUpdate('value', v)}
										onCreateOption={v => this.handleValueChangeAndUpdate('value', v)}
										{...valueDisplayOptions}
									/>
								: valueDisplayType === KEY_VALUE_LIST_DISPLAY_TYPE.CUSTOM ?
									this.renderCustomComponent(valueCustomComponent, 'value', valueDisplayOptions)
								: null
							}
						</FormField>
					</div>
				</div>

				{!canRemove ? null :
					<div className={`remove-wrapper`}>
						<Button
							icon={icon_font_delete_symbol}
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							displayStyle={BUTTON_STYLE.SUBTLE}
							onClick={() => executeComponentCallback(this.props.onDelete, this.getValue('GUIID'))}
						/>
					</div>
				}
			</div>
		);
	}
}

// Data objects
/**
 * Key/value list item data object
 */
export class KeyValueListItemDataObject {
	/**
	 * @param {string|null} [GUIID] - GUI ID. If not specified or empty, unique GUID will be used.
	 * @param {any} [key] - Key depends on key data type.
	 * @param {any} [value] - Value depends on value data type.
	 * @param {Object} [data = {}] - Full key/value pair data object. This is only relevant if key/value data is an array
	 * of objects.
	 */
	constructor(GUIID, key = undefined, value = undefined, data = {}) {
		this.GUIID = (GUIID ? GUIID : v4());
		this.key = key;
		this.value = value;
		this.data = data;
	}
}


/**
 * Define component's own props that can be passed to it by parent components
 */
KeyValueListItem.propTypes = {
	...DataComponent.propTypes,
	
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,

	// Flag that determines if key and value labels will be rendered
	showLabels: PropTypes.bool,
	// Key column label
	keyLabel: PropTypes.any,
	// Value column label
	valueLabel: PropTypes.any,
	// Flag that determines if key/value pairs will be selectable
	// @note Selectable items can be removed or other actions can be performed on them by adding custom toolbar buttons.
	selectable: PropTypes.bool,
	// Flag that determines if this list item is selected or not
	selected: PropTypes.bool,
	// Flag that specifies if items can be removed from the list
	canRemove: PropTypes.bool,

	// Key column display type
	keyDisplayType: PropTypes.oneOf(KEY_VALUE_LIST_DISPLAY_TYPES).isRequired,
	// Key column component props
	// @note This will depend on key column display type ('keyDisplayType' prop).
	keyDisplayOptions: PropTypes.object,
	// Key column custom component if display type is custom (KEY_VALUE_LIST_DISPLAY_TYPE_CUSTOM)
	keyCustomComponent: PropTypes.elementType,

	// Value column display type
	valueDisplayType: PropTypes.oneOf(KEY_VALUE_LIST_DISPLAY_TYPES).isRequired,
	// Value column component props
	// @note This will depend on value column display type ('keyDisplayType' prop).
	valueDisplayOptions: PropTypes.object,
	// Value column custom component if display type is custom (KEY_VALUE_LIST_DISPLAY_TYPE_CUSTOM)
	valueCustomComponent: PropTypes.elementType,

	// Main component data
	// @type {KeyValueListItemDataObject}
	data: PropTypes.object,

	// Events
	onSelect: PropTypes.func, // Arguments: GUI ID of the item.
	onChange: PropTypes.func, // Arguments: {KeyValueListItemDataObject} item - Key/value item object, {Event} [event]
	onDelete: PropTypes.func, // Arguments: GUI ID of the item.
};

/**
 * Define component default values for own props
 */
KeyValueListItem.defaultProps = {
	styleName: 'default',
	className: '',
	selectable: true,
	selected: false,
	canRemove: true,

	keyDisplayType: KEY_VALUE_LIST_DISPLAY_TYPE.TEXT,
	keyDisplayOptions: null,
	keyCustomComponent: null,

	valueDisplayType: KEY_VALUE_LIST_DISPLAY_TYPE.TEXT,
	valueDisplayOptions: null,
	valueCustomComponent: null,
};

export default KeyValueListItem;