import "./index.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE} from "Core/components/DialogComponent";
import PropTypes from 'prop-types';
import CodeEditorInput from 'Core/components/input/CodeEditorInput';
import {get, omit, pick} from 'lodash';
import * as optionObjects from 'Core/components/display/MessagePreview/optionObjects';

class NotificationSourceDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'notification-source-dialog',
			translationPath: 'NotificationSourceDialog',
			dialogType: DIALOG_TYPE.NONE,
		});
	}

	render() {
		/** @type {MessageContentDataObject} */
		const data = this.getProp('data');

		const optionsClass = get(optionObjects, `MessagePreviewOptions${data.messageType}`);
		let messageOptions = null;
		if (optionsClass) messageOptions = pick(data, Object.getOwnPropertyNames(new optionsClass()));
		
		return this.renderDialog(
			this.renderTitle(this.t('title')),
			(
				<div>
					<CodeEditorInput
						value={JSON.stringify(omit(messageOptions, ['bodySource']), null, 2)}
						readOnly={true}
						mode="json"
						width="100%"
						height="70vh"
						wrapEnabled={true}
						showPrintMargin={false}
						fontSize={14}
					/>
				</div>
			)
		); 
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
NotificationSourceDialog.propTypes = {
	// @type {MessageContentDataObject}
	data: PropTypes.object,
};

export default NotificationSourceDialog;