import seedrandom from 'seedrandom';

/**
 * Generates a color palette with a specified number of colors and returns the color at the specified index.
 * @param {number} numColors - The number of colors in the palette.
 * @param {number} index - The index of the color to retrieve from the palette.
 * @param {string} seed - Optional. The seed for deterministic randomness. If not provided, randomness is not 
 * deterministic.
 * @returns {string|null} The color at the specified index in the palette, or null if the index is out of range.
 */
export const getColorFromPalette = (numColors, index, seed) => {
	seedrandom(seed, { global: true }); // Set the seed for deterministic randomness

	const palette = [];
	const startingHue = Math.random() * 360; // Random starting hue value within the seed context

	for (let i = 0; i < numColors; i++) {
		const hue = (startingHue + (i * (360 / numColors))) % 360; // Calculate hue for each color
		const saturation = 40 + Math.random() * 20; // Random saturation between 40% and 60%
		const lightness = 50 + Math.random() * 10; // Random lightness between 50% and 60%
		const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
		palette.push(color);
	}

	if (index < 0 || index >= palette.length) {
		return null; // Index out of range
	}

	return palette[index];
}