import {ioJsonAction} from 'Core/store/actions/io';
import * as resultDataMap from './dataMap/result';
import * as filterDataMap from './dataMap/filter';
import {hideLoadingFunction, showLoading} from 'Core/helpers/loading';
import {isSuccessful} from 'Core/helpers/io';
import {StatisticsItemDataObject} from 'Pages/apps/default/statistics/dataObjects';
import {actionCreators} from 'Core/store/reducers';
import {reducerStoreKey} from './reducer';

/**
 * Fetch a single statistics item
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} filter - Statistics filter.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @param {string} clientId - DB ID of the client (used by super admin).
 * @return {function(*): Promise<StatisticsItemDataObject|undefined>}
 */
export const fetchStatisticsResultItemAction = (
	abortCallback, filter, loadingOverlaySelector, clientId
) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'customer-message/search-customer-message-statistics',
		{filter: filterDataMap.output(filter), clientId},
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		.then(res => isSuccessful(res) ? new StatisticsItemDataObject(filter, resultDataMap.input(res.data)) : undefined);
};

/**
 * Store statistics into Redux
 * 
 * @param {StatisticsItemDataObject[]} statistics
 * @return {(function(*): void)|*}
 */
export const storeStatisticsAction = statistics => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setStatistics(statistics));
}