import {cloneDeep, get} from 'lodash';
import {getNumber, getString, resetObjectValues} from 'Core/helpers/data';
import {CappingItemRuleDataObject} from "../dataObjects";
import {CAMPAIGN_MESSAGE_CONTENT_TYPE} from 'Pages/apps/default/projectPages/campaign/const';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CappingItemRuleDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CappingItemRuleDataObject(
		getString(result, 'id'),
		get(result, 'messageType') === null ? CAMPAIGN_MESSAGE_CONTENT_TYPE.ALL : getString(result, 'messageType'),
		getNumber(result, 'messageLimit', null),
		getString(result, 'timeUnit'),
		getString(result, 'clientId'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {CappingItemRuleDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		id: inputData.id,
		messageType: inputData.messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.ALL ? null : inputData.messageType,
		messageLimit: inputData.messageLimit,
		timeUnit: inputData.timeUnit,
		clientId: inputData.clientId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}