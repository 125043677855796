import {cloneDeep, get} from "lodash";
import {CustomerMessageCustomerDataObject, CustomerMessageListItemDataObject} from "../dataObjects";
import {getBool, getString} from "Core/helpers/data";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {ltrimString} from 'Core/helpers/string';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CustomerMessageListItemDataObject}
 * 
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CustomerMessageListItemDataObject(
		getString(result, 'id'),
		getString(result, 'messageDefinitionId'),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'deliveryDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'status'),
		getBool(result, 'immediate'),
		getDate(get(result, 'scheduledDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		inputCustomer(get(result, 'customer')),
	);

	return result;
}

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CustomerMessageCustomerDataObject}
 */
export const inputCustomer = rawData => {
	let result = cloneDeep(rawData);
	const mobilePhoneNumber = getString(result, 'mobilePhoneNumber');

	result = new CustomerMessageCustomerDataObject(
		getString(result, 'id'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getString(result, 'email'),
		(mobilePhoneNumber ? `+${ltrimString(mobilePhoneNumber, '+')}` : ''),
		getString(result, 'externalCrmId')
	);
	
	return result;
}