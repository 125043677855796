import "./index.css";

import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import SelectInput from 'Core/components/input/SelectInput';
import {omit} from 'lodash';
import {getArray} from 'Core/helpers/data';
import StatusOptionComponent from './options/StatusOption';
import StatusSingleValueComponent from './singleValues/StatusSingleValue';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions();

class CappingPlanSelectInput extends BaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			/** @type {{label: string, value: string, active: boolean}|null|undefined} */
			cappingPlanList: null,
			/** @type {boolean} */
			cappingPlanListLoading: false,
		};

		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		// Load select options
		await this.loadOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Re-load select options when project ID or client ID changes
		if (this.props.projectId !== prevProps.projectId || this.props.clientId !== prevProps.clientId) {
			this.loadOptions().then();
		}
	}

	/**
	 * Load select options
	 * @param {boolean} [onMount=false] - Specifies if this method is called as part of the component mount cycle.
	 * @return {Promise<void>}
	 */
	async loadOptions(onMount = false) {
		const {projectId, clientId, fetchCappingPlansCodebookAction} = this.props;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);

		await this.setState({cappingPlanListLoading: true})
			.then(() => executeAbortableAction(fetchCappingPlansCodebookAction, projectId, clientId))
			.then(res => this.setState({cappingPlanList: getArray(res)}))
			.finally(() => this.setState({cappingPlanListLoading: false}));
	}

	render() { 
		const {cappingPlanList, cappingPlanListLoading} = this.state;

		return (
			<SelectInput
				{...omit(this.props, ['projectId', 'clientId', ...Object.keys(allActions)])}
				options={!!cappingPlanList ? cappingPlanList : []}
				isLoading={cappingPlanListLoading}
				components={{Option: StatusOptionComponent, SingleValue: StatusSingleValueComponent}}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CappingPlanSelectInput.propTypes = {
	...SelectInput.propTypes,
	projectId: PropTypes.string.isRequired,
	clientId: PropTypes.string,
};

export default connect(null, allActions, null, {forwardRef: true})(CappingPlanSelectInput);