import {get} from "lodash";
import {getBoolean, getInteger, getString} from 'Core/helpers/data';
import {SORT_ORDER} from 'Core/const/global';

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'blacklistCustomers';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@blacklistCustomers/reset',
	SET_LIST_DATA: '@blacklistCustomers/set_list_data',
	CLEAR_LIST_DATA: '@blacklistCustomers/clear_list_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	/**
	 * @param {IoJsonFetchResponseObject} customersListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	setCustomersListData: customersListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA,
		listData: customersListData
	}),
	clearCustomersListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getCustomersList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getCustomersListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getCustomersListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getCustomersListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),
};

export default reducer;