import styles from "./index.module.css";
import "./index.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as actions from "./actions";
import {fetchCustomerCustomFieldsAction} from 'Pages/apps/default/customers/actions';
import KeyValueList from "Core/components/advanced/KeyValueList";
import {KEY_VALUE_LIST_DISPLAY_TYPE} from "Core/components/advanced/KeyValueList/const";
import {CUSTOMER_FIELDS} from 'Pages/apps/default/const';
import {forOwn, get, omit, set, uniqBy} from 'lodash';
import {getArray, getBool, getString, isset, keyValueArrayToObject, trimArray} from "Core/helpers/data";
import ToggleInput from "Core/components/input/ToggleInput";
import Label from "Core/components/display/Label";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_create_symbol} from "Config/app";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import FileInput from "Core/components/input/FileInput";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {getGlobalActions} from "Core/helpers/redux";
import {IMPORT_CUSTOMERS_FILE_ERROR, IMPORT_CUSTOMERS_FROM_FILE_TYPE, IMPORT_CUSTOMERS_FROM_FILE_TYPES} from './const';
import SelectInput from 'Core/components/input/SelectInput';

class ImportCustomersFromFile extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'ImportCustomersFromFile',
			domPrefix: 'import-customers-from-file',
		});

		// Initialize local component state
		this.state = {
			/**
			 * Customer custom fields
			 * @type {
			 * 	{id: number, name: string, code: string, description: string, messageType: CampaignMessageContentType}[]|
			 * 	null
			 * }
			 */
			customerCustomFields: null,
			
			/**
			 * Map of recipients import file columns where keys are file columns and values are customer fields or customer
			 * custom fields
			 * @type {Object<string, string>}
			 */
			fromFileColumMap: null,
			/**
			 * Flag that specifies if customer data should be overridden by the data from the import recipients file
			 */
			fromFileOverrideCustomerData: getBool(props, 'defaultOverride'),
			/**
			 * Recipients import file validation errors from IO
			 * @type {{row: number, property: string, errorType: CustomerListFileError, errorValue: string}|null}
			 */
			fromFileErrors: null,
			/**
			 * User selected unique ID used for identifying a customer
			 * @type {string}
			 */
			uniqueIdColumn: '',
		};
		
		/**
		 * Map actions to the type of customers import
		 * @type {Object<ImportCustomersFromFileType, function>}
		 */
		this.actionMap = {
			[IMPORT_CUSTOMERS_FROM_FILE_TYPE.DEFAULT]: get(props, 'addCustomersFromFileAction'),
			[IMPORT_CUSTOMERS_FROM_FILE_TYPE.CUSTOMER_MESSAGES]: get(props, 'addCustomerMessagesFromFileAction'),
			[IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_ADD]: get(props, 'addToBlacklistFromFileAction'),
			[IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_REMOVE]: get(props, 'removeFromBlacklistFromFileAction'),
		};

		// Data methods
		this.getImportFromFileFormData = this.getImportFromFileFormData.bind(this);

		// Action methods
		this.handleImportFromFileInputChange = this.handleImportFromFileInputChange.bind(this);
		this.handleImportFromFileButtonClick = this.handleImportFromFileButtonClick.bind(this);

		// Render methods
		this.renderImportFilePreview = this.renderImportFilePreview.bind(this);
		this.renderImportFromFileErrors = this.renderImportFromFileErrors.bind(this);
	}
	
	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get FormData for importing recipients from file based on current file input
	 *
	 * @param {Element|EventTarget} input - File input element.
	 * @param {string} fieldName - Field name of the file in FormData.
	 * @param {Object} additionalData - Any additional data that will be included in the final FormData.
	 * @return {FormData|null}
	 */
	getImportFromFileFormData(input, fieldName, additionalData) {
		// Generate FormData from selected files
		const files = Array.from(get(input, 'files', []));
		let formData = null;
		if (files.length > 0) {
			formData = new FormData();
			if (input.multiple) {
				files.forEach(file => { formData.append(`${fieldName}[]`, file); });
			} else {
				const file = get(files, '[0]');
				if (file) formData.append(fieldName, file);
			}
			// Add additional FormData fields
			if (additionalData) {
				forOwn(additionalData, (value, field) => formData.append(field, value));
			}
		}
		return formData;
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Handle file input component change event for recipients import from file
	 *
	 * @param {Event} event - Change event of the file input.
	 * @param {string} fieldName - Field name of the file in FormData.
	 * @param {Object} additionalData - Any additional data that will be included in the final FormData.
	 */
	handleImportFromFileInputChange(event, fieldName, additionalData) {
		const {loadingSelector, validateCustomerListFileColumnsAction, fetchCustomerCustomFieldsAction} = this.props;
		const data = this.getImportFromFileFormData(event.target, fieldName, additionalData);

		// Clear import from file section
		let loading;
		if (loadingSelector) loading = showLoading(loadingSelector);
		return this.setState({
			customerCustomFields: null,
			fromFileColumMap: null,
			fromFileOverrideCustomerData: getBool(this.props, 'defaultOverride'),
			fromFileErrors: null,
		})
			.then(() => {
				if (data) {
					// Validate file columns and store the result map in local state
					return this.executeAbortableAction(validateCustomerListFileColumnsAction, data)
						// Load column map into local state
						.then(fromFileColumMap => {
							if (!isset(fromFileColumMap)) {
								executeComponentCallback(
									this.props.onError, 
									IMPORT_CUSTOMERS_FILE_ERROR.COLUMN_VALIDATION_FAILED
								);
							}
							return this.setState({fromFileColumMap});
						})
						// Fetch customer custom fields for column map
						.then(() => this.executeAbortableAction(fetchCustomerCustomFieldsAction))
						// Load customer custom fields into local state
						.then(ccf => {
							if (!isset(ccf)) {
								executeComponentCallback(
									this.props.onError,
									IMPORT_CUSTOMERS_FILE_ERROR.LOADING_CUSTOM_FIELDS_FAILED
								);
							}
							return this.setState({customerCustomFields: ccf});
						});
				} else {
					executeComponentCallback(this.props.onClear);
					return Promise.resolve();
				}
			})
			.finally(() => { if (loading) hideLoading(loading); });
	}

	/**
	 * Handle button click event for recipients import from file
	 * @param {MouseEvent} event - Button mouse click event.
	 */
	handleImportFromFileButtonClick(event) {
		const {
			importType, successMessage, loadingSelector, messageDefinitionId, blacklistId, validateCustomerListFileAction, 
			addSuccessMessageAction, 
		} = this.props;
		const {fromFileColumMap, uniqueIdColumn} = this.state;
		const dataToValidate = this.getImportFromFileFormData(
			this.fromFileInputRef.wrapperRef, 'customers', {header: JSON.stringify(fromFileColumMap)}
		);

		let loading;
		if (loadingSelector) loading = showLoading(loadingSelector);
		this.setState({fromFileErrors: null})
			.then(() => this.executeAbortableAction(validateCustomerListFileAction, dataToValidate))
			.then(errors => {
				if (!isset(errors)) return Promise.resolve(this.state);
				
				if (Array.isArray(errors) && errors.length > 0) {
					executeComponentCallback(this.props.onError, IMPORT_CUSTOMERS_FILE_ERROR.FILE_VALIDATION_FAILED);
					return this.setState({fromFileErrors: errors});
				}

				const {fromFileOverrideCustomerData} = this.state;
				let additionalData = {
					// clientId: null,
					header: JSON.stringify(fromFileColumMap),
				};
				
				switch (importType) {
					case IMPORT_CUSTOMERS_FROM_FILE_TYPE.DEFAULT:
						set(additionalData, 'override', fromFileOverrideCustomerData);
						set(additionalData, 'uniqueIdColumn', getString(uniqueIdColumn));
						break;
					case IMPORT_CUSTOMERS_FROM_FILE_TYPE.CUSTOMER_MESSAGES:
						set(additionalData, 'override', fromFileOverrideCustomerData);
						set(additionalData, 'messageDefinitionId', getString(messageDefinitionId));
						break;
					case IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_ADD:
						set(additionalData, 'blacklistId', blacklistId);
						set(additionalData, 'uniqueIdColumn', getString(uniqueIdColumn));
						break;
					case IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_REMOVE:
						set(additionalData, 'overrideCustomerWill', fromFileOverrideCustomerData);
						set(additionalData, 'blacklistId', blacklistId);
						set(additionalData, 'uniqueIdColumn', getString(uniqueIdColumn));
						break;
					// no default
				}
				const data = this.getImportFromFileFormData(this.fromFileInputRef.wrapperRef, 'customers', additionalData);

				// Do the import based on "importType" prop
				return this.setState({fromFileErrors: []})
					.then(() => this.executeAbortableAction(this.actionMap[importType], data))
					.then(res => {
						if (res) {
							if (successMessage) addSuccessMessageAction(successMessage);

							// Clear import from file section
							this.fromFileInputRef.clear();
							return this.setState({
								customerCustomFields: null,
								fromFileColumMap: null,
								fromFileOverrideCustomerData: getBool(this.props, 'defaultOverride'),
								fromFileErrors: null,
							})
								.then(() => { executeComponentCallback(this.props.onSuccess); });
						} else {
							executeComponentCallback(
								this.props.onError, 
								IMPORT_CUSTOMERS_FILE_ERROR.MESSAGES_CREATE_REQUEST_FAILED
							);
							return Promise.resolve();
						}
					});
			})
			.finally(() => { if (loading) hideLoading(loading); });
	}


	// Render methods ------------------------------------------------------------------------------------------------
	/**
	 * Render preview for recipients import from file input
	 * @param {Array} fileList - Currently selected list of files.
	 * @return {null|JSX.Element}
	 */
	renderImportFilePreview(fileList) {
		const {buttonLabel, buttonProps, importType, showUniqueIdColumn} = this.props;
		const {fromFileColumMap, customerCustomFields, fromFileOverrideCustomerData, uniqueIdColumn} = this.state;
		const usedFields = (fromFileColumMap ? Object.values(fromFileColumMap).filter(i => !!i) : []);

		return (!fromFileColumMap ? null :
			<>
				<KeyValueList
					styleName="compact"
					className={`file-column-map ${styles['fileColumnMap']}`}
					showToolbar={false}
					canAdd={false}
					canRemove={false}
					selectable={false}
					showBottomToolbar={false}
					keyLabel={this.t('file_column')}
					keyDisplayOptions={{readOnly: true, className: styles['fileColumnMapKey']}}
					valueLabel={this.t('map_column')}
					valueDisplayType={KEY_VALUE_LIST_DISPLAY_TYPE.SELECT}
					valueDisplayOptions={{
						isClearable: true,
						options: uniqBy(trimArray([
							...CUSTOMER_FIELDS.map(i => ({
								label: this.t(i, 'constants.customerFields'),
								value: i,
								isDisabled: usedFields.includes(i)
							})),
							...getArray(customerCustomFields).map(i => ({
								label: i.name,
								value: i.name,
								isDisabled: usedFields.includes(i.name)
							}))
						]), 'value'),
					}}
					data={fromFileColumMap}
					onChange={list => this.setState({
						fromFileColumMap: keyValueArrayToObject(list)
					})}
				/>

				{showUniqueIdColumn ?
					<FormField
						className={styles['uniqueIdColumn']}
						labelClassName={styles['uniqueIdColumnLabel']}
						label={`${this.t('uniqueIdColumnLabel')}:`}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						inputClassName={styles['uniqueIdColumnInput']}
					>
						<SelectInput
							options={
								uniqBy(trimArray([
									...CUSTOMER_FIELDS.map(i => ({label: this.t(i, 'constants.customerFields'), value: i})),
									...getArray(customerCustomFields).map(i => ({label: i.name, value: i.name}))
								]), 'value')
							}
							value={uniqueIdColumn}
							onChange={v => this.setState({uniqueIdColumn: v})}
						/>
					</FormField>
					: null
				}

				<div className={styles['fileActions']}>
					<div className={styles['overrideCustomer']}>
						{
							importType === IMPORT_CUSTOMERS_FROM_FILE_TYPE.DEFAULT ||
							importType === IMPORT_CUSTOMERS_FROM_FILE_TYPE.CUSTOMER_MESSAGES ?
								<ToggleInput
									label={(
										<span>
											<Label
												content={this.t('import_file_override_customer')}
												help={this.t('import_file_override_customer_help')}
											/>
										</span>
									)}
									checked={fromFileOverrideCustomerData}
									onChange={e => this.setState({fromFileOverrideCustomerData: getBool(e, 'target.checked')})}
								/> 
							: importType === IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_REMOVE ?
								<ToggleInput
									label={(
										<span>
											<Label
												content={this.t('remove_blacklist_override_customer')}
												help={this.t('remove_blacklist_override_customer_help')}
											/>
										</span>
									)}
									checked={fromFileOverrideCustomerData}
									onChange={e => this.setState({fromFileOverrideCustomerData: getBool(e, 'target.checked')})}
								/>
							: null
						}
					</div>

					<Button
						icon={icon_font_create_symbol}
						label={buttonLabel ? buttonLabel : this.t('import_file')}
						displayStyle={BUTTON_STYLE.HIGHLIGHT}
						{...omit(buttonProps, ['buttonLabel'])}
						onClick={this.handleImportFromFileButtonClick}
						disabled={showUniqueIdColumn && !uniqueIdColumn}
					/>
				</div>

				{this.renderImportFromFileErrors()}
			</>
		);
	}

	/**
	 * Render validation errors for recipients import from file
	 */
	renderImportFromFileErrors() {
		const {fromFileErrors} = this.state;

		return (
			!Array.isArray(fromFileErrors) || fromFileErrors.length === 0 ? null :
				<div className={styles['fileErrors']}>
					{fromFileErrors.map((e, idx) =>
						<div key={idx} className={`${styles['fileError']} notice error`}>
							<Label
								content={this.tt('row', 'import_file_error', '', {row: e.row})}
								supportHtml={true}
							/>,&nbsp;
							<Label content={this.tt(e.errorType, 'import_file_error.type', 'l')} />
							{!getString(e, 'errorValue') ? null : <strong> '{getString(e, 'errorValue')}'</strong>}
							{!getString(e, 'property') ? null :
								<>
									{` ${this.tt('forProperty', 'import_file_error')} `}
									<strong>{getString(e, 'property')}</strong>
								</>
							}.
						</div>
					)}
				</div>
		);
	}

	render() {
		return (
			<FormWrapper id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<FormField labelPosition={FORM_FIELD_LABEL_POSITION.NONE} className={styles['innerWrapper']}>
					<FileInput
						showClearButton={true}
						acceptExtensions={['.csv', '.xlsx']}
						fieldName="customers"
						onChange={this.handleImportFromFileInputChange}
						showPreview={true}
						getPreview={this.renderImportFilePreview}
						ref={node => { this.fromFileInputRef = node; }}
					/>
				</FormField>
			</FormWrapper>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ImportCustomersFromFile.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	
	importType: PropTypes.oneOf(IMPORT_CUSTOMERS_FROM_FILE_TYPES).isRequired,
	loadingSelector: PropTypes.string,
	buttonLabel: PropTypes.string,
	buttonProps: PropTypes.object,
	successMessage: PropTypes.string,
	messageDefinitionId: PropTypes.string,
	showUniqueIdColumn: PropTypes.bool,
	blacklistId: PropTypes.string,
	defaultOverride: PropTypes.bool,
	
	onSuccess: PropTypes.func,
	onError: PropTypes.func, // Arguments: {ImportCustomersFileError}
	onClear: PropTypes.func,
};

/**
 * Define component default values for own props
 */
ImportCustomersFromFile.defaultProps = {
	id: '',
	className: '',
	buttonProps: {},
	defaultOverride: false,
};

export default connect(null, getGlobalActions(actions, {fetchCustomerCustomFieldsAction}))(ImportCustomersFromFile);