import "./index.css";
import "./default.style.css";
import "./action.style.css";
import "./highlight.style.css";
import "./success.style.css";
import "./header.style.css";

import React, {useRef} from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import {getBoolean, getString} from "Core/helpers/data";
import {TOGGLE_INPUT_LABEL_POSITION, TOGGLE_INPUT_LABEL_POSITIONS} from "Core/components/input/ToggleInput/const";
import {executeComponentCallback} from "Core/components/BaseComponent";
import Label from "Core/components/display/Label";

/**
 * Toggle input component
 * @description Toggle button input component.
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 *
 * This component uses 'react-toggle' component.
 */
function ToggleInput({
	key, styleName = 'action', className = '', readOnly = false, disabled = false, checked = false, value = '', 
	disableAnimation = false, label = null, labelPosition = TOGGLE_INPUT_LABEL_POSITION.RIGHT, ...reactToggle
}) {
	const toggleRef = useRef(null);
	
	/**
	 * Render the actual toggle
	 * @return {JSX.Element}
	 */
	function renderToggle() {
		return (
			<Toggle
				key={key}
				{...reactToggle}
				value={getString(value)}
				checked={getBoolean(checked)}
				className={
					`toggle-input-component ${readOnly ? 'readonly' : ''} ${disableAnimation ? 'no-animation' : ''} ` +
					`${className} ${styleName}-style`
				}
				disabled={readOnly ? true : disabled}
				ref={toggleRef}
			/>
		);
	}

	/**
	 * Render label if specified
	 * 
	 * @return {React.DetailedReactHTMLElement<{onClick: onClick}, HTMLElement>|JSX.Element}
	 */
	function renderLabel() {
		if (!label) {
			return null;
		} else if (typeof label === 'string' || typeof label === 'number') {
			return (
				<Label 
					content={getString(label)} 
					element="span" 
					elementProps={{
						className: `toggle-input-component-label ${labelPosition}`,
						onClick: () => toggleRef.current?.input?.click(),
					}}
				/>
			);
		} else {
			return React.cloneElement(label, {
				className: `toggle-input-component-label ${labelPosition} ${getString(label, 'className')}`,
				onClick: e => {
					toggleRef.current?.input.click();
					executeComponentCallback(label.onClick, e);
				}
			});
		}
	}
	
	return (
		label ?
			<>
				{labelPosition === TOGGLE_INPUT_LABEL_POSITION.LEFT ? renderLabel() : null}
				{renderToggle()}
				{labelPosition === TOGGLE_INPUT_LABEL_POSITION.RIGHT ? renderLabel() : null}
			</>
			:
			renderToggle()
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 * @note Based on 'react-toggle' component (https://github.com/aaronshaf/react-toggle).
 */
ToggleInput.propTypes = {
	// React key prop
	key: PropTypes.string,
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	defaultChecked: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	id: PropTypes.string,
	'aria-labelledby': PropTypes.string,
	'aria-label': PropTypes.string,
	icons: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
		checked: PropTypes.node,
		unchecked: PropTypes.node
	})]),
	disableAnimation: PropTypes.bool,
	
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
	labelPosition: PropTypes.oneOf(TOGGLE_INPUT_LABEL_POSITIONS),
};

export * from "./const";
export default ToggleInput;