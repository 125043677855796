import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {omit} from 'lodash';
import {connect} from 'react-redux';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from "../../actions";
import {selectors} from 'Core/store/reducers';
import {reducerStoreKey} from "../../reducer";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions(actions);

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @param {Object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	isGroupOpened: selectors[reducerStoreKey].isGroupOpened(state, ownProps.menuItemId),
});

class MenuItemGroupToggleButton extends BaseComponent {
	constructor(props) {
		super(props);
		
		// Action methods
		this.toggle = this.toggle.bind(this);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Toggle menu group opened status
	 * @param {MouseEvent} clickEvent - Mouse click event from the toggle button.
	 */
	toggle(clickEvent) {
		const {menuItemId, toggleMainSidebarGroupAction} = this.props;
		clickEvent.stopPropagation();

		toggleMainSidebarGroupAction(menuItemId);
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, isGroupOpened, closedIcon, closedIconProps, openedIcon, openedIconProps, ...otherProps
		} = this.props;
		
		return (
			<Button
				className={`menu-item-group-toggle ${className}`}
				{...omit(otherProps, ['menuItemId', 'className', ...Object.keys(allActions)])}
				icon={isGroupOpened ? openedIcon : closedIcon}
				iconProps={isGroupOpened ? openedIconProps : closedIconProps}
				onClick={this.toggle}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MenuItemGroupToggleButton.propTypes = {
	...Button.propTypes,
	
	closedIcon: PropTypes.string,
	closedIconProps: PropTypes.object,
	openedIcon: PropTypes.string,
	openedIconProps: PropTypes.object,
	
	menuItemId: PropTypes.string.isRequired,
};

/**
 * Define component default values for own props
 */
MenuItemGroupToggleButton.defaultProps = {
	className: '',
	displayType: BUTTON_DISPLAY_TYPE.NONE,
	displayStyle: BUTTON_STYLE.SUBTLE,
	
	closedIcon: 'angle-down',
	closedIconProps: {},
	openedIcon: 'angle-up',
	openedIconProps: {},
};

export default connect(mapStateToProps, allActions)(MenuItemGroupToggleButton);