import CoreAuth from "../core/auth";
import {deleteStorageKey, STORAGE_TYPE} from "Core/storage";
import {io_pause_requests_storage_field_name} from "Config/io";
import {
	access_token_storage_field_name,
	access_token_storage_type, auth_broadcast_channel, auth_broadcast_message_logout,
	current_user_storage_field_name,
	decoded_access_token_storage_field_name,
	refresh_token_storage_field_name, refresh_token_storage_type,
	refreshing_tokens_storage_field_name,
	temporary_access_token_storage_field_name
} from "Config/auth";
import {acl_storage_type, acl_storage_var} from "Config/acl";
import {user_activity_storage_path} from "Config/app";

/**
 * App auth class
 * @note This class must extend CoreAuth class, but it does not have to implement any changes if no custom functionality
 * is needed.
 */
class Auth extends CoreAuth {
	/**
	 * Logout the user
	 * @description This method will just remove tokens from storage.
	 * @return {Promise<void>}
	 * @override Deleted project ID session storage value.
	 */
	static logout = () => {
		deleteStorageKey(io_pause_requests_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY);
		deleteStorageKey(access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(refresh_token_storage_field_name, refresh_token_storage_type);
		deleteStorageKey(refreshing_tokens_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(decoded_access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(acl_storage_var, acl_storage_type);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.REDUX);
		deleteStorageKey(user_activity_storage_path, STORAGE_TYPE.LOCAL);
		
		// @override Delete project ID session storage value
		deleteStorageKey('projectId', STORAGE_TYPE.SESSION);

		// Broadcast logout so that all other opened tabs that need to will redirect to login page
		const bChannel = new BroadcastChannel(auth_broadcast_channel);
		bChannel.postMessage(auth_broadcast_message_logout);
		bChannel.close();

		return Promise.resolve();
	}
}

export default Auth;