export class CustomerMessageListItemDataObject {
	/**
	 * @param {string} id - DB ID of the customer message.
	 * @param {string} messageDefinitionId - DB ID of the message definition used ot create this customer message.
	 * @param {Date} creationDate - Creation date of the customer message.
	 * @param {Date} deliveryDate - Delivery date of the customer message.
	 * @param {CustomerMessageStatus} status - Status of the customer message.
	 * @param {boolean} immediate - Flag that specifies if customer message should be sent immediately.
	 * @param {Date} scheduledDate - Customer message scheduled date.
	 * @param {CustomerMessageCustomerDataObject} customer - Customer associated to this customer message.
	 */
	constructor(id, messageDefinitionId, creationDate, deliveryDate, status, immediate, scheduledDate, customer) {
		this.id = id;
		this.messageDefinitionId = messageDefinitionId;
		this.creationDate = creationDate;
		this.deliveryDate = deliveryDate;
		this.status = status;
		this.immediate = immediate;
		this.scheduledDate = scheduledDate;
		this.customer = customer;
	}
}

export class CustomerMessageCustomerDataObject {
	/**
	 * @param {string} id - DB ID of the customer.
	 * @param {string} firstName - Customer's first name.
	 * @param {string} lastName - Customer's last name.
	 * @param {string} email - Customer's email address.
	 * @param {string} mobilePhoneNumber - Customer's phone number.
	 * @param {string} externalCrmId - CRM ID of the customer in the external system.
	 */
	constructor(id, firstName, lastName, email, mobilePhoneNumber, externalCrmId) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.mobilePhoneNumber = mobilePhoneNumber;
		this.externalCrmId = externalCrmId;
	}
}