import {icon_font_symbol_class_prefix} from "Config/app";
import {AclCheckDataObject} from "Core/acl";

/**
 * External link data object used in the main sidebar
 */
export class MainSidebarExternalLinkDataObject {
	/**
	 * @param {string} [link=''] - Link URL.
	 * @param {Object} [linkProps={}] - Link props.
	 * @param {string} [labelTranslationPath=''] - Translation path of the link label.
	 * @param {string} [icon=''] - Link icon symbol.
	 * @param {string} [iconSymbolPrefix] - Font icon symbol prefix.
	 * @param {string} [iconClassName=''] - Icon component class name.
	 * @param {AclCheckDataObject} [access] - ACL data rules for showing the link.
	 */
	constructor(
		link = '', linkProps = {}, labelTranslationPath = '', icon = '', iconSymbolPrefix = icon_font_symbol_class_prefix, 
		iconClassName = '', access = new AclCheckDataObject(),
	) {
		this.link = link;
		this.linkProps = linkProps;
		this.labelTranslationPath = labelTranslationPath;
		this.icon = icon;
		this.iconSymbolPrefix = iconSymbolPrefix;
		this.iconClassName = iconClassName;
		this.access = access;
	}
}

export class MainSidebarCustomComponentLinkDataObject {
	/**
	 * @param {Component} [component=null] - Component to use for rendering the main menu link.
	 * @param {Object} [props={}] - Props that will be used when rendering the component.
	 * @param {AclCheckDataObject} [access] - ACL data rules for showing the link.
	 */
	constructor(component = null, props = {}, access = new AclCheckDataObject()) {
		this.component = component;
		this.props = props;
		this.access = access;
	}
}

export class MainSidebarGroupLinkDataObject {
	/**
	 * @param {
	 *    Object|
	 * 	MainSidebarExternalLinkDataObject|
	 * 	MainSidebarCustomComponentLinkDataObject|
	 * 	MainSidebarGroupLinkDataObject
	 * } rootItem - Root item to use as folders parent item.
	 * @param {
	 *    Object[]|
	 * 	MainSidebarExternalLinkDataObject[]|
	 * 	MainSidebarCustomComponentLinkDataObject[]|
	 * 	MainSidebarGroupLinkDataObject[]
	 * } children - Sub items.
	 */
	constructor(rootItem, children = []) {
		this.rootItem = rootItem;
		this.children = children;
	}
}