import {cloneDeep} from "lodash";
import {getBool, getString, isset, resetObjectValues} from "Core/helpers/data";
import {CustomerCustomFieldDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CustomerCustomFieldDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CustomerCustomFieldDataObject(
		(!isset(result?.customerCustomFieldId) || result?.customerCustomFieldId === null ? null : 
			getString(result, 'customerCustomFieldId')
		),
		getString(result, 'name'),
		getString(result, 'value'),
		(!isset(result?.identifier) || result?.identifier === null ? null : 
			getBool(result, 'identifier')
		),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {CustomerCustomFieldDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		customerCustomFieldId: result.id,
		name: result.name,
		value: result.value,
		identifier: result.identifier,
	};
	
	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}