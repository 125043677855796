/**
 * Blink background animation
 * @description Blinks two times.
 * 
 * @param {Element|HTMLElement} element - Target element.
 * @param {number} [duration=600] - Animation duration in milliseconds.
 * @param {string} [background='var(--action-color-faded)'] - CSS background to use for blinking.
 * @param {string} [originalBackground] - Original CSS background of the element.
 * @param {string} [originalTransition] - Original CSS transition of the element.
 */
export const blinkBackgroundAnimation = (
	element, duration = 500, background = 'var(--action-color-faded)', originalBackground, originalTransition
) => {
	if (element) {
		const step = Math.round(duration / 4);
		
		element.style.transition = `background-color ${step}ms ease-in`;
		element.style.background = background;
		setTimeout(() => {
			element.style.background = originalBackground ? originalBackground : '';
			element.style.transition = originalTransition ? originalTransition : '';

			setTimeout(() => {
				element.style.transition = `background-color ${step}ms ease-in`;
				element.style.background = background;

				setTimeout(() => {
					element.style.background = originalBackground ? originalBackground : '';
					element.style.transition = originalTransition ? originalTransition : '';
				}, step);
			}, step);
		}, step);
	}
};

/**
 * Slow blink background animation
 * @description Blinks once and fades out slowly.
 *
 * @param {Element|HTMLElement} element - Target element.
 * @param {number} [duration=1000] - Animation duration in milliseconds.
 * @param {string} [background='var(--action-color-faded)'] - CSS background to use for blinking.
 * @param {string} [originalBackground] - Original CSS background of the element.
 * @param {string} [originalTransition] - Original CSS transition of the element.
 */
export const blinkBackgroundSlowAnimation = (
	element, duration = 1000, background = 'var(--action-color-faded)', originalBackground, originalTransition
) => {
	if (element) {
		const appearTime = Math.round(duration * 0.2);
		const fadeTime = Math.round(duration * 0.8);
		
		element.style.transition = `background-color ${appearTime}ms ease-in`;
		element.style.background = background;
		setTimeout(() => {
			element.style.transition = `background-color ${fadeTime}ms ease-in`;
			element.style.background = originalBackground ? originalBackground : '';
			setTimeout(() => {
				element.style.transition = originalTransition ? originalTransition : '';
			}, fadeTime);
		}, appearTime);
	}
};

/**
 * Slow blink opacity animation
 * @description Blinks once and fades out slowly.
 *
 * @param {Element|HTMLElement} element - Target element.
 * @param {number} [duration=1000] - Animation duration in milliseconds.
 * @param {string} [originalOpacity] - Original CSS opacity of the element.
 * @param {string} [originalTransition] - Original CSS transition of the element.
 */
export const blinkOpacitySlowAnimation = (element, duration = 1000, originalOpacity, originalTransition) => {
	if (element) {
		const appearTime = Math.round(duration * 0.5);
		const fadeTime = Math.round(duration * 0.5);

		element.style.transition = `opacity ${appearTime}ms ease-in`;
		element.style.opacity = '0';
		setTimeout(() => {
			element.style.transition = `opacity ${fadeTime}ms ease-in`;
			element.style.opacity = originalOpacity ? originalOpacity : '';
			setTimeout(() => {
				element.style.transition = originalTransition ? originalTransition : '';
			}, fadeTime);
		}, appearTime);
	}
};