import {forOwn, set, cloneDeep} from "lodash";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import {getDateString} from "Core/helpers/datetime";
import {getString, removeEmptyObjectProps, resetObjectValues} from 'Core/helpers/data';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data.
 * @return {Object}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	forOwn(result, (value, key) => {
		// Convert ID values to string so that select inputs can select the values properly
		if (['projectId', 'deliveryChannelId'].includes(key)) {
			set(result, key, (!!value ? getString(value) : value));
		}
	});

	return result;
}

/**
 * Get output data from input data
 * @param {Object} input - Input value.
 * @return {Object}
 */
export const output = input => {
	let result = cloneDeep(input);

	forOwn(result, (value, key) => {
		// Convert Date object to appropriate date string
		if (value instanceof Date) set(result, key, getDateString(value, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S));
	});
	
	// Set proper empty values expected by the API
	result = removeEmptyObjectProps(resetObjectValues(result));

	return result;
}