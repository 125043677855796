import {actionCreators} from "./reducer";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {sidebar_menu_shrank_cookie_group} from "Config/app";
import {getBool, getBoolFromTinyInt, getTinyIntFormBool} from 'Core/helpers/data';

/**
 * Show main sidebar
 */
export const showMainSidebarAction = () => dispatch => { dispatch(actionCreators.show()); };

/**
 * Hide main sidebar
 */
export const hideMainSidebarAction = () => dispatch => { dispatch(actionCreators.hide()); };

/**
 * Toggle main sidebar
 */
export const toggleMainSidebarAction = () => dispatch => { dispatch(actionCreators.toggle()); };

/**
 * Shrink main sidebar
 */
export const shrinkMainSidebarAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData(sidebar_menu_shrank_cookie_group, 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(`sidebar_menu_shrank`, '1', STORAGE_TYPE.LOCAL);
	}

	dispatch(actionCreators.shrink());
};

/**
 * Enlarge main sidebar
 */
export const enlargeMainSidebarAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData(sidebar_menu_shrank_cookie_group, 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(`sidebar_menu_shrank`, '0', STORAGE_TYPE.LOCAL);
	}

	dispatch(actionCreators.enlarge());
};

/**
 * Toggle main sidebar size
 */
export const toggleMainSidebarSizeAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData(sidebar_menu_shrank_cookie_group, 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(
			`sidebar_menu_shrank`,
			getTinyIntFormBool(
				getBoolFromTinyInt(getStorageValue(`sidebar_menu_shrank`, STORAGE_TYPE.LOCAL)),
				'',
				'0',
				true
			),
			STORAGE_TYPE.LOCAL
		);
	}

	dispatch(actionCreators.toggleSize());
};

/**
 * Load opened statuses of menu groups from storage into Redux store
 * @return {(function(*): void)|*}
 */
export const loadMainSidebarGroupsOpenedStatusesAction = () => dispatch => {
	const menuGroupsStatusesCookie = new CookieData('necessary', 'sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(menuGroupsStatusesCookie)) {
		const statusesFromStorage = getStorageValue('sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION, true, {});
		dispatch(actionCreators.setGroupsOpenedStatuses(statusesFromStorage));
	}
};

/**
 * Open menu group
 * @description This action will set the menu group opened status in Redux and save it to the session storage.
 * 
 * @param {string} id - Menu item ID.
 * @return {(function(*): void)|*}
 */
export const openMainSidebarGroupAction = id => dispatch => {
	dispatch(actionCreators.openGroup(id));
	
	const menuGroupsStatusesCookie = new CookieData('necessary', 'sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(menuGroupsStatusesCookie)) {
		const statusesFromStorage = getStorageValue('sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION, true, {});

		setStorageValue(
			'sidebar_menu_groups_statuses',
			{...statusesFromStorage, [id]: true},
			STORAGE_TYPE.SESSION,
			undefined,
			true
		);
	}
};

/**
 * Close menu group
 * @description This action will set the menu group opened status in Redux and save it to the session storage.
 *
 * @param {string} id - Menu item ID.
 * @return {(function(*): void)|*}
 */
export const closeMainSidebarGroupAction = id => dispatch => {
	dispatch(actionCreators.closeGroup(id));
	
	const menuGroupsStatusesCookie = new CookieData('necessary', 'sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(menuGroupsStatusesCookie)) {
		const statusesFromStorage = getStorageValue('sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION, true, {});

		setStorageValue(
			'sidebar_menu_groups_statuses',
			{...statusesFromStorage, [id]: false},
			STORAGE_TYPE.SESSION,
			undefined,
			true
		);
	}
};

/**
 * Toggle menu group
 * @description This action will set the menu group opened status in Redux and save it to the session storage.
 *
 * @param {string} id - Menu item ID.
 * @return {(function(*): void)|*}
 */
export const toggleMainSidebarGroupAction = id => dispatch => {
	dispatch(actionCreators.toggleGroup(id));
	
	const menuGroupsStatusesCookie = new CookieData('necessary', 'sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(menuGroupsStatusesCookie)) {
		const statusesFromStorage = getStorageValue('sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION, true, {});

		setStorageValue(
			'sidebar_menu_groups_statuses',
			{...statusesFromStorage, [id]: !getBool(statusesFromStorage, id)},
			STORAGE_TYPE.SESSION,
			undefined,
			true
		);
	}
};