import React from 'react';
import AppComponent from "./core/App";
import {Route, Switch, withRouter} from "react-router-dom";
import {
	app_auth_page_router_path,
	app_login_page_router_path,
	app_password_reset_page_router_path,
	app_password_reset_request_page_router_path
} from "./config";

/**
 * Import required resources
 */
import cookieConsentResource, {RESOURCE_KEY as cookieConsentResourceKey} from "./core/resources/cookieConsentResource";
import i18nResource from "./core/resources/i18nResource";
import skinResource from "./core/resources/skinResource";
import currentUserResource from "./core/resources/currentUserResource";

/**
 * Import app top-level sections
 */
import {sections} from "./sections";

/**
 * Import app top-level pages
 */
import AuthPage from "./core/pages/auth";
import LoginPage from "./core/pages/login";
import PasswordResetPage from "./core/pages/passwordReset";
import PasswordResetRequestPage from "./core/pages/passwordResetRequest";

/**
 * Main app component
 */
class App extends AppComponent {
	/**
	 * List of imported resources required before app can be rendered
	 * @type {(function(): Promise<Object<string, *>>)[]}
	 */
	requiredResources = [cookieConsentResource, i18nResource, skinResource, currentUserResource];
	/**
	 * List of resource keys of the resources that will be loaded into the memory
	 * @note Resources can be big so be careful when enabling this. Resources are defined in 'requiredResources'.
	 * @type {string[]} Use '*' to load all resources.
	 */
	loadResourcesIntoMemory = [cookieConsentResourceKey];
	
	render() {
		return this.renderApp((
			<Switch>
				<Route path={app_auth_page_router_path} exact={true} component={AuthPage} />
			</Switch>
		), (
			<Switch>
				<Route path={app_login_page_router_path} exact={true} component={LoginPage} />
				<Route path={app_password_reset_page_router_path} exact={true} component={PasswordResetPage} />
				<Route path={app_password_reset_request_page_router_path} exact={true} component={PasswordResetRequestPage} />
				
				{this.renderSections(sections)}
			</Switch>
		));
	}
}

export default withRouter(App);