import {cloneDeep} from "lodash";
import {getNumber, getString} from 'Core/helpers/data';
import {StatisticsItemResultByStatusDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {StatisticsItemResultByStatusDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new StatisticsItemResultByStatusDataObject(
		getString(result, 'status'),
		getNumber(result, 'messageCount'),
		getNumber(result, 'actualMessageCount'),
	);

	return result;
}

// @note There is no output function since list is read only.