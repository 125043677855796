import styles from "./index.module.css";
import "./index.css";

import React from "react";
import ProjectPageDataComponent from "../../../ProjectPageDataComponent";
import * as appConfig from "../../../../config";
import * as campaignPageConfig from "../../config";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {resetRecipientsAction} from "./popups/MessageDefinitionPopup/tabs/RecipientsTab/actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import Breadcrumbs from "Core/components/display/Breadcrumbs";
import {getArray, getBool, getNumber, getString, getStringForDisplay, trimArray} from 'Core/helpers/data';
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {
	icon_font_close_symbol,
	icon_font_create_symbol,
	icon_font_delete_symbol,
	icon_font_save_symbol
} from 'Config/app';
import Label, {LABEL_ICON_POSITION} from "Core/components/display/Label";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import TextareaInput from "Core/components/input/TextareaInput";
import DateInput from "Core/components/input/DateInput";
import {CAMPAIGN_STATUS, MESSAGE_DEFINITION_STATUS} from "../../const";
import {reducerStoreKey} from "../../reducer";
import DataValueValidation, {VALIDATION_FIELD_TYPE, ValidationConstraintObject} from "Core/validation";
import {getCampaignIdFromUrl, getCampaignItemTo} from "../../helper";
import {nl2br} from "Core/helpers/string";
import DateLabel from "Core/components/display/DateLabel";
import Separator from "Core/components/display/Separator";
import {find, sortBy} from "lodash";
import Icon from "Core/components/display/Icon";
import AdvancedDropdown from "Core/components/display/AdvancedDropdown";
import {MESSAGE_DEFINITION_FILTER, MESSAGE_DEFINITION_FILTERS} from "./const";
import MessageDefinition from "./components/MessageDefinition";
import MessageDefinitionPopup from "./popups/MessageDefinitionPopup";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {isSuccessful} from "Core/helpers/io";
import {getCurrentProjectFromUrl, getCurrentProjectIdFromSession} from 'Helpers/project';
import {startOfDay} from "date-fns";
import {hideLoading, showPageLoading} from "Core/helpers/loading";
import CappingPlanSelectInput from 'Components/input/CappingPlanSelectInput';
import {MESSAGE_DEFINITION_POPUP_MODE} from './popups/MessageDefinitionPopup/const';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @param {Object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	projectList: selectors.projectSelector.getProjectList(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	campaignItemIdFromUrl: getCampaignIdFromUrl(ownProps.location),
	campaignItem: selectors[reducerStoreKey].getCampaignItem(state),
	messageDefinitions: selectors[reducerStoreKey].getMessageDefinitions(state),
	isGenerating: selectors[reducerStoreKey].areCustomerMessagesBeingGenerated(state),
});

class ItemPage extends ProjectPageDataComponent {
	constructor(props) {
		super(props, {
			/** @type {CampaignItemDataObject} */
			data: null,
			messageDefinitionFilter: MESSAGE_DEFINITION_FILTER.ALL,
			messageDefinitionErrors: null,
		}, {
			domPrefix: 'item-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			enableLoadOnDataPropChange: true,
			dataPropAlias: 'campaignItem',
		}, 'page_title');
		
		// Data methods
		this.getMessageDefinitionError = this.getMessageDefinitionError.bind(this);
		
		// Popup methods
		this.openMessageDefinitionPopup = this.openMessageDefinitionPopup.bind(this);

		// Validation methods
		this.validatePublish = this.validatePublish.bind(this);

		// Action methods
		this.save = this.save.bind(this);
		this.publish = this.publish.bind(this);
		this.delete = this.delete.bind(this);
		this.createMessageDefinition = this.createMessageDefinition.bind(this);
		this.removeMessageDefinition = this.removeMessageDefinition.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			.then(async () => {
				if (prevProps.campaignItemIdFromUrl !== this.props.campaignItemIdFromUrl) {
					const {clearCampaignItemAction, clearMessageDefinitionsListAction, closeAllPopupsAction} = this.props;
					clearCampaignItemAction();
					clearMessageDefinitionsListAction();
					closeAllPopupsAction();
					
					await this.loadPageData();
				}
				return Promise.resolve(this.state);
			});
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		
		const {clearCampaignItemAction, clearMessageDefinitionsListAction, closeAllPopupsAction} = this.props;
		clearCampaignItemAction();
		clearMessageDefinitionsListAction();
		closeAllPopupsAction();
	}
	

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {any|void}
	 * @throws {AsyncMountError} Throw this error or reject a promise with it in order to try running another mount
	 * method if multiple mount calls were made for this page.
	 */
	loadPageData() {
		const {campaignItemIdFromUrl, loadCampaignItemAction, loadMessageDefinitionsListAction} = this.props;
		
		// Load general campaign data
		return this.executeAbortableActionMount(loadCampaignItemAction, campaignItemIdFromUrl)
			// Load campaign message definitions list
			.then(() => this.executeAbortableActionMount(loadMessageDefinitionsListAction, campaignItemIdFromUrl));
	}

	/**
	 * Get errors for a single message definition
	 * 
	 * @param {{
	 * 	messageDefinitionId: number,
	 * 	errorDataList: {errorType: PublishMessageDefinitionError, errorValue: string}[]
	 * }} errors - Message definition errors from IO.
	 * @param {string} messageDefinitionId - Message definition DB ID.
	 * @return {{errorType: PublishMessageDefinitionError, errorValue: string}[]}
	 */
	getMessageDefinitionError(errors, messageDefinitionId) {
		return getArray(find(errors, {messageDefinitionId: getNumber(messageDefinitionId)}), 'errorDataList');
	}
	

	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();
		const startDate = dataToValidate.startDate ? startOfDay(dataToValidate.startDate) : null;

		dataValidation.addRule('name', 'required');
		dataValidation.addRule('startDate', 'datetime');
		dataValidation.addRule('endDate', 'datetime');
		dataValidation.addConstraint('endDate', new ValidationConstraintObject(
			'min', startDate, VALIDATION_FIELD_TYPE.DATE, {ignoreEmpty: true}
		));

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Validate data for publishing
	 * @return {boolean}
	 */
	validatePublish() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();
		// const startOfTomorrow = addDays(new Date(), 1);
		const startOfToday = startOfDay(new Date());
		const startDate = dataToValidate.startDate ? startOfDay(dataToValidate.startDate) : null;

		dataValidation.addRule('name', 'required');
		dataValidation.addRule('startDate', 'required', 'datetime');
		dataValidation.addRule('endDate', 'datetime');
		dataValidation.addConstraint('startDate', new ValidationConstraintObject(
			'min', startOfToday, VALIDATION_FIELD_TYPE.DATE,
		));
		dataValidation.addConstraint('endDate', new ValidationConstraintObject(
			'min', startDate, VALIDATION_FIELD_TYPE.DATE, {ignoreEmpty: true}
		));

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	openMessageDefinitionPopup(messageDefinition) {
		/**
		 * Open message definition popup
		 *
		 * @param {MessageDefinitionDataObject} messageDefinition - Message definition to open.
		 * @return {string} Popup GUI ID.
		 */
		const {campaignItem, resetRecipientsAction, openPopupAction} = this.props;
		return openPopupAction(MessageDefinitionPopup, {
			campaign: campaignItem,
			data: messageDefinition,
			redirectTo: this.redirectTo,
			deleteItemAction: this.removeMessageDefinition,
			onClose: () => {
				const mode = window.location.hash;
				const isWizardMode = (mode === MESSAGE_DEFINITION_POPUP_MODE.WIZARD);
				
				// Once the wizard popup is closed, URL should be replaced to base URL of the page to remove the wizard URL 
				// hashes since once the popup of the mode is closed, default mode should be used to avoid issues when
				// using back button in the browser. Wizard should be stopped once the popup has been closed.
				if (isWizardMode) this.redirectToBase(true);
				
				// Reset all Redux values related to the popup
				resetRecipientsAction();
			},
		});
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save item
	 * @return {Promise<*>}
	 */
	async save() {
		const {campaignItem, updateCampaignItemAction, addSuccessMessageAction} = this.props;
		const status = getString(campaignItem, 'status');
		
		if ([CAMPAIGN_STATUS.IN_PREPARATION, CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.STOPPED].includes(status)) {
			await this.clearValidationErrors();
			if (this.validate()) {
				/** @type {CampaignItemDataObject} */
				const item = this.getDataToReturn();
				const itemId = getString(item, 'id', '', true);

				const originalCappingPlanId = getString(campaignItem, 'cappingPlan.value');
				const currentCappingPlanId = getString(item, 'cappingPlan.value');
				const cappingPlanChanged = (currentCappingPlanId !== originalCappingPlanId);

				return this.executeAbortableAction(
					updateCampaignItemAction, itemId, item, cappingPlanChanged, currentCappingPlanId
				)
					.then(response => {
						if (response) addSuccessMessageAction(this.t('update_success_msg'));
						return response;
					});
			}
		}
		return Promise.resolve();
	}

	/**
	 * Publish item
	 * @return {Promise<*>}
	 */
	async publish() {
		const {
			campaignItem, campaignItemIdFromUrl, publishCampaignAction, updateCampaignItemAction, 
			loadMessageDefinitionsListAction, addSuccessMessageAction,
		} = this.props;
		
		await this.setState({messageDefinitionErrors: null});
		await this.clearValidationErrors();
		if (this.validatePublish()) {
			const loading = showPageLoading();
			
			/** @type {CampaignItemDataObject} */
			const item = this.getDataToReturn();
			const itemId = getString(item, 'id', '', true);

			const originalCappingPlanId = getString(campaignItem, 'cappingPlan.value');
			const currentCappingPlanId = getString(item, 'cappingPlan.value');
			const cappingPlanChanged = (currentCappingPlanId !== originalCappingPlanId);

			// Save the campaign before publishing it
			const saveResponse = await this.executeAbortableAction(
				updateCampaignItemAction, itemId, item, cappingPlanChanged, currentCappingPlanId
			);
			// If saving campaign was successful
			if (saveResponse) {
				// Try to publish the campaign
				const publishResponse = await this.executeAbortableAction(publishCampaignAction, itemId);
				// If publishing the campaign was successful
				if (isSuccessful(publishResponse)) {
					addSuccessMessageAction(this.t('publish_success_msg'));
					
					// Reload the message definition list
					await this.executeAbortableAction(loadMessageDefinitionsListAction, campaignItemIdFromUrl, null);
					
					hideLoading(loading);
					return publishResponse;
				}
				// If there were message definition errors while trying to publish the campaign
				else if (publishResponse?.success === false) {
					await this.setState({messageDefinitionErrors: getArray(publishResponse, 'data')});
					
					hideLoading(loading);
					return undefined;
				}
			}

			hideLoading(loading);
			return undefined;
		}
		return Promise.resolve();
	}
	
	/**
	 * Delete item
	 * @param {CampaignItemDataObject} item - Item to delete.
	 * @return {Promise<void>}
	 */
	delete(item) {
		const {
			location, projectList, deleteCampaignItemAction, openDialogAction, closeDialogAction, addSuccessMessageAction
		} = this.props;
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromUrl(projectList, location);
		
		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete', '', '', {name: item.name}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteCampaignItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.t('delete_success_msg'));
								closeDialogAction(dialogGUIID);
								this.redirectTo(getCampaignItemTo(currentProject));
							} else {
								closeDialogAction(dialogGUIID);
							}
							return Promise.resolve(response);
						})
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'item-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 400
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}

	/**
	 * Create a new message definition
	 * 
	 * @return {Promise<Object>} Promise that resolves with the newly created message definition or undefined if creation
	 * failed.
	 */
	async createMessageDefinition() {
		const {
			campaignItem, campaignItemIdFromUrl, createMessageDefinitionItemAction, loadMessageDefinitionsListAction
		} = this.props;
		const campaignId = getString(campaignItem, 'id');
		
		if (campaignId) {
			const newMessageDefinition = await this.executeAbortableAction(createMessageDefinitionItemAction, campaignId);
			if (newMessageDefinition) {
				// Load campaign message definitions list
				// @note No need to wait since it can be loaded in the background
				this.executeAbortableActionMount(loadMessageDefinitionsListAction, campaignItemIdFromUrl).then();
				// Open edit popup for the newly created messaged definition
				this.openMessageDefinitionPopup(newMessageDefinition);
			}
			return newMessageDefinition;
		}
		return undefined;
	}

	/**
	 * Remove a message definition item
	 * 
	 * @param {MessageDefinitionDataObject} item - Message definition item to remove.
	 * @return {Promise<void>}
	 */
	removeMessageDefinition(item) {
		const {
			campaignItem, deleteMessageDefinitionItemAction, loadMessageDefinitionsListAction, openDialogAction, 
			closeDialogAction, addSuccessMessageAction
		} = this.props;
		const campaignId = getString(campaignItem, 'id');

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.tt('confirm_delete', 'messageDefinition'),
				onYes: () => {
					this.executeAbortableAction(deleteMessageDefinitionItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.tt('delete_success_msg', 'messageDefinition'));
								this.executeAbortableAction(loadMessageDefinitionsListAction, campaignId).then();
							}
							closeDialogAction(dialogGUIID);
							return Promise.resolve(response);
						})
						.finally(() => resolve(true));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve(false);
				}
			}, {
				id: 'message-definition-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 400
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {location, projectList, campaignItem, isGenerating, pauseCampaignAction, resumeCampaignAction} = this.props;
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromUrl(projectList, location);
		/** @type {CampaignItemDataObject} */
		const data = this.getData();
		const isReadOnly = (
			[CAMPAIGN_STATUS.COMPLETED, CAMPAIGN_STATUS.EXPIRED].includes(getString(campaignItem, 'status'))
		);
		const hasMessageDefinitions = (getArray(this.props, 'messageDefinitions').length > 0);

		return (
			<h1 className="page-title with-actions">
				{
					this.projectExists() ?
						<>
							<div className="content">
								<Breadcrumbs
									className={styles['breadcrumbs']}
									includeHome={false}
									items={[
										{
											to: this.getProjectTo(),
											label: getString(currentProject, 'name')
										},
										{
											to: getCampaignItemTo(currentProject),
											label: this.t('page_link_label', campaignPageConfig.translationPath),
										},
										{
											label: (
												<>
													<Label 
														content={
															`[${getString(campaignItem, 'id')}] ${getString(campaignItem, 'name')}`
														} 
													/>
													<Label
														content={this.t(
															getString(campaignItem, 'status'),
															`${campaignPageConfig.translationPath}.status`
														)}
														element="small"
														elementProps={{
															className: `tag ${styles['status']} ` +
																`campaign-status-${getString(campaignItem, 'status')}`,
														}}
													/>
												</>
											),
										},
									]}
								/>
							</div>
							<div className="actions">
								{
									data === null || isReadOnly ? null :
										<>
											{data?.status !== CAMPAIGN_STATUS.IN_PREPARATION ? null :
												<div className="action-button">
													<Tooltip
														tag="div"
														title={this.t('delete_tooltip')}
														size="small"
														position="top-center"
														arrow={true}
														interactive={false}
													>
														<Button
															big={true}
															icon={icon_font_delete_symbol}
															displayType={BUTTON_DISPLAY_TYPE.SOLID}
															displayStyle={BUTTON_STYLE.DEFAULT}
															onClick={() => this.delete(data)}
														/>
													</Tooltip>
												</div>
											}
											<div className="action-button main-button">
												<Tooltip
													tag="div"
													title={this.t('save_tooltip')}
													size="small"
													position="top-center"
													arrow={true}
													interactive={false}
												>
													<Button
														big={true}
														icon={icon_font_save_symbol}
														label={this.t('Save', 'general')}
														displayType={BUTTON_DISPLAY_TYPE.SOLID}
														displayStyle={BUTTON_STYLE.ACTION}
														onClick={() => this.save()}
													/>
												</Tooltip>
											</div>

											{data?.status === CAMPAIGN_STATUS.ACTIVE ?
												<div className="action-button main-button">
													<Tooltip
														tag="div"
														size="small"
														title={this.t('stop_tooltip')}
														position="top-center"
														arrow={true}
														interactive={false}
													>
														<Button
															big={true}
															icon="hand-paper-o"
															label={this.t('stop')}
															displayType={BUTTON_DISPLAY_TYPE.SOLID}
															displayStyle={BUTTON_STYLE.ACTION}
															onClick={() => this.executeAbortableAction(pauseCampaignAction, data.id)}
															disabled={isGenerating}
														/>
													</Tooltip>
												</div>
												: null
											}
											{data?.status === CAMPAIGN_STATUS.STOPPED ?
												<div className="action-button main-button">
													<Tooltip
														tag="div"
														size="small"
														title={this.t('resume_tooltip')}
														position="top-center"
														arrow={true}
														interactive={false}
													>
														<Button
															big={true}
															icon="repeat"
															label={this.t('resume')}
															displayType={BUTTON_DISPLAY_TYPE.SOLID}
															displayStyle={BUTTON_STYLE.ACTION}
															onClick={() => this.executeAbortableAction(resumeCampaignAction, data.id)}
															disabled={isGenerating}
														/>
													</Tooltip>
												</div>
												: null
											}

											{data?.status === CAMPAIGN_STATUS.IN_PREPARATION ?
												<div className="action-button main-button">
													<Tooltip
														tag="div"
														title={
															isGenerating ? this.t('publish_customer_messages_generating_tooltip') :
															!hasMessageDefinitions ? this.t('publish_missing_definitions_tooltip') : 
															this.t('publish_tooltip')
														}
														size="small"
														position="top-center"
														arrow={true}
														interactive={false}
													>
														<Button
															big={true}
															icon="bullhorn"
															label={this.t('publish')}
															displayType={BUTTON_DISPLAY_TYPE.SOLID}
															displayStyle={BUTTON_STYLE.ACTION}
															onClick={() => this.publish()}
															disabled={!hasMessageDefinitions || isGenerating}
														/>
													</Tooltip>
												</div>
												: null
											}
										</>
								}
								<div className="action-button close-button">
									<Tooltip
										tag="div"
										title={this.t('Close', 'general')}
										size="small"
										position="top-center"
										arrow={true}
										interactive={false}
									>
										<Button
											big={true}
											icon={icon_font_close_symbol}
											displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
											displayStyle={BUTTON_STYLE.SUBTLE}
											onClick={() => this.redirectTo(getCampaignItemTo(currentProject))}
										/>
									</Tooltip>
								</div>
							</div>
						</>
						: null
				}
			</h1>
		);
	}

	render() {
		const projectId = getCurrentProjectIdFromSession();
		const {campaignItem, mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		/** @type {CampaignItemDataObject} */
		const data = this.getData();
		const {messageDefinitionFilter, messageDefinitionErrors} = this.state;
		/** @type {MessageDefinitionDataObject[]} */
		const messageDefinitions = sortBy(
			getArray(this.props, 'messageDefinitions')
				.filter(md => (
					messageDefinitionFilter === MESSAGE_DEFINITION_FILTER.ALL || md.status === messageDefinitionFilter)
				), 
			['startDate']
		);
		const isReadOnly = (
			[CAMPAIGN_STATUS.COMPLETED, CAMPAIGN_STATUS.EXPIRED].includes(getString(campaignItem, 'status'))
		);
		if (getString(campaignItem, 'name')) this.setBrowserTitle(getString(campaignItem, 'name'));

		/**
		 * Flag that specifies if capping plan is defined and active
		 * @type {boolean}
		 */
		const cappingPlanActive = !!data?.cappingPlan && getBool(data, 'cappingPlan.active');
		const originalCappingPlanId = getString(campaignItem, 'cappingPlan.value');
		const currentCappingPlanId = getString(data, 'cappingPlan.value');
		const cappingPlanChanged = (currentCappingPlanId !== originalCappingPlanId);
	
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				{!this.projectExists() ? this.renderMissingProject() :
					data === null ? null :
					<div className={`${styles['inner']}`}>
						<div id="campaign-general" className={`${styles['general']}`}>
							<div 
								className={
									`title-separator ${styles['separator']} ${styles['withToolbar']} ${styles['editTitle']}`
								}
							>
								<Separator
									className={`${styles['title']}`}
									icon="bullhorn"
									content={this.t('general_section')}
									noBorder={true}
									noMargin={true}
								/>
								{/*
									isReadOnly ? null :
										<Label
											element="div"
											elementProps={{
												className: `button solid action ${styles['button']}`,
												onClick: () => this.save(),
											}}
											icon={icon_font_save_symbol}
											content={this.t('Save', 'general')}
										/>
								*/}
							</div>
							{
								!isReadOnly ?
									<FormWrapper>
										<FormField
											required={true}
											label={this.t('nameField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
											errorMessages={this.getValidationErrors('name')}
										>
											<TextInput
												name="name"
												value={data.name}
												onChange={this.handleInputChange}
												disabled={data.status !== CAMPAIGN_STATUS.IN_PREPARATION}
											/>
										</FormField>
										<FormField
											label={this.t('descriptionField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
											className="multiline-form-field"
											errorMessages={this.getValidationErrors('description')}
										>
											<TextareaInput
												name="description"
												value={data.description}
												onChange={this.handleInputChange}
												rows={3}
												disabled={data.status !== CAMPAIGN_STATUS.IN_PREPARATION}
											/>
										</FormField>
										<FormField
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
											label={<><Label content={this.t('startDateField')} /><sup>1</sup></>}
											labelProps={{
												element: 'span',
												elementProps: {className: 'cursor-help'},
												tooltip: this.t('required_for_publish'),
											}}
											errorMessages={this.getValidationErrors('startDate')}
										>
											<DateInput
												openToDate={data.startDate ? data.startDate : new Date()}
												value={data.startDate}
												onChange={d => this.handleValueChange('startDate', d)}
												disabled={data.status !== CAMPAIGN_STATUS.IN_PREPARATION}
											/>
										</FormField>
										<FormField
											label={this.t('endDateField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
											errorMessages={this.getValidationErrors('endDate')}
										>
											<DateInput
												openToDate={data.endDate ? data.endDate : new Date()}
												value={data.endDate}
												onChange={d => this.handleValueChange('endDate', d)}
											/>
										</FormField>
										<FormField
											label={this.t('cappingPlanField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
											warningMessages={
												!!data?.cappingPlan && !getBool(data, 'cappingPlan.active') ? 
													[this.t('cappingPlanInactiveTooltip')] : 
													[]
											}
										>
											<CappingPlanSelectInput
												projectId={projectId}
												isClearable={true}
												simpleValue={false}
												value={data.cappingPlan}
												onChange={v => this.handleValueChange('cappingPlan', v)}
												placeholder={this.t('selectCappingPlan')}
												disabled={
													data.status === CAMPAIGN_STATUS.EXPIRED || 
													data.status === CAMPAIGN_STATUS.COMPLETED
												}
											/>
										</FormField>
										{cappingPlanChanged ?
											<div className="notice default">
												{!!currentCappingPlanId ?
													<Label element="small" content={this.t('capping_plan_changed_msg')} /> :
													<Label element="small" content={this.t('capping_plan_removed_msg')} />
												}
											</div>
											: null
										}
										
										<div className="legend-wrapper">
											<small className="legend-label">
												<sup><strong>1</strong></sup>&nbsp;
												<Label content={this.t('required_for_publish_legend')} />
											</small>
										</div>
									</FormWrapper>
									:
									<FormWrapper>
										<FormField
											label={this.t('nameField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED
											}>
											<Label content={getStringForDisplay(data.name)} />
										</FormField>
										<FormField
											label={this.t('descriptionField')}
											className="multiline-form-field"
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
										>
											<Label 
												content={nl2br(getStringForDisplay(data.description))} 
												supportHtml={true} 
											/>
										</FormField>
										<FormField
											label={this.t('startDateField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
										>
											{data.startDate ? <DateLabel inputDate={data.startDate} /> : '—'}
										</FormField>
										<FormField
											label={this.t('endDateField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
										>
											{
												data.endDate ?
													<DateLabel inputDate={data.endDate} />
													:
													this.t('noEndDateField')
											}
										</FormField>
										<FormField
											label={this.t('cappingPlanField')}
											labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
										>
											{!!data?.cappingPlan ?
												<Label
													tooltip={
														!cappingPlanActive ?
															this.t('cappingPlanInactiveTooltip') :
															this.t('cappingPlanActiveTooltip')
													}
													element="span"
													elementProps={{className: !cappingPlanActive ? 'faded-color' : ''}}
													icon="circle"
													iconClassName={!cappingPlanActive ? 'faded-color' : 'success-color'}
													content={getStringForDisplay(
														getString(data, 'cappingPlan.label'), undefined, true, true
													)}
												/>
												:
												'—'
											}
										</FormField>
									</FormWrapper>
							}
						</div>

						<div id="message-definitions" className={`${styles['pings']}`}>
							<div 
								className={
									`title-separator ${styles['separator']} ${styles['withToolbar']} ${styles['pingsTitle']}`
								}
							>
								<Separator
									className={styles['title']}
									icon="bell-o"
									content={this.t('pings_section')}
									noBorder={true}
									noMargin={true}
								/>
								<AdvancedDropdown
									parent="#item-page"
									className={styles['dropdown']}
									label={
										<span className={`button transparent subtle ${styles['dropdownButton']}`}>
											<Icon symbol="filter" />&nbsp;&nbsp;
											<Label
												content={(
													<span className={styles['dropdownButtonValue']}>
														{this.tt(messageDefinitionFilter, 'messageDefinition.filter')}
													</span>
												)}
												icon="angle-down" 
												iconPosition={LABEL_ICON_POSITION.RIGHT}
											/>
										</span>
									}
								>
									{MESSAGE_DEFINITION_FILTERS.map(f =>
										<div 
											key={f}
											className={`dropdown-item ${messageDefinitionFilter === f ? 'active' : ''}`}
											onClick={() => this.setState({messageDefinitionFilter: f})}
										>
											<Label content={this.tt(f, 'messageDefinition.filter')} />
										</div>
									)}
								</AdvancedDropdown>
								{
									isReadOnly ? null :
										<Label
											element="div"
											elementProps={{
												className: `button solid action ${styles['button']}`,
												onClick: () => this.createMessageDefinition(),
											}}
											icon={icon_font_create_symbol}
											content={this.tt('newBtn', 'messageDefinition')}
										/>
								}
							</div>

							<div>
								{messageDefinitions.map(md => 
									<MessageDefinition 
										key={md.id}
										id={`md-${md.id}`}
										data={md}
										readOnly={isReadOnly || md.status !== MESSAGE_DEFINITION_STATUS.IN_PREPARATION}
										errors={this.getMessageDefinitionError(messageDefinitionErrors, md.id)}
										onEdit={md => this.openMessageDefinitionPopup(md)}
										onDelete={md => this.removeMessageDefinition(md)}
									/>
								)}
							</div>

							{isReadOnly ? null :
								<Button
									className={`${styles['newButtonBottom']}`}
									icon={icon_font_create_symbol}
									label={this.tt('newBtn', 'messageDefinition')}
									displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
									displayStyle={BUTTON_STYLE.SUBTLE}
									onClick={() => this.createMessageDefinition()}
								/>
							}
						</div>
					</div>
				}
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default withRouter(connect(mapStateToProps, getPageActions(actions, {resetRecipientsAction}))(ItemPage));