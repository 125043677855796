import {ioJsonAction, ioJsonFetchAction} from 'Core/store/actions/io';
import {getArray, getBool, getString} from 'Core/helpers/data';
import * as campaignDataMap from 'Pages/apps/default/projectPages/campaign/dataMap/listItem';
import * as messageDefinitionDataMap from 'Pages/apps/default/projectPages/campaign/dataMap/messageDefinition';
import * as channelDataMap from 'Pages/apps/default/projectPages/campaign/dataMap/channelList';

/**
 * @typedef {Object} CodebookItem
 * @property {string} id - DB ID of the codebook item.
 * @property {string} name - Codebook item name.
 */

/**
 * Low-level function to fetch any standard codebook
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} endpoint - API endpoint of the codebook.
 * @param {Object} [data] - Any IO request data.
 * @param {string} [api='defaultAuthorizedApi'] - API to use (key of the 'io_base_urls' io config object). 
 * @return {function(*): Promise<Object[]>}
 * @private
 */
// eslint-disable-next-line no-unused-vars
const _fetchCodebookAction = (abortCallback, endpoint, data, api = 'defaultAuthorizedApi') => dispatch => {
	return ioJsonAction(abortCallback, api, endpoint, data)(dispatch)
		// Get the list of codebook items from the response
		.then (res => getArray(res, 'data'));
};

/**
 * Fetch the campaigns codebook
 * @note For now it uses a standard page search API endpoint with high per-page number and no filter to get all 
 * campaigns. Change this function if and when a specific codebook API endpoint becomes available.
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} projectId - DB ID of the project to get campaigns for.
 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
 * @return {function(*): Promise<CampaignListItemDataObject[]|undefined>}
 */
export const fetchCampaignCodebookAction = (abortCallback, projectId, clientId = null) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/search',
		undefined,
		undefined,
		undefined,
		undefined,
		9999,
		undefined,
		undefined,
		{
			projectId, 
			clientId
		}
	)(dispatch)
		// Get the list of campaigns from the response
		.then(res => getArray(res, 'data').map(i => campaignDataMap.input(i)));
};

/**
 * Fetch the message definition codebook
 * @note For now it uses a standard page search API endpoint with high per-page number and no filter to get all
 * message definitions. Change this function if and when a specific codebook API endpoint becomes available.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} campaignId - DB ID of the campaign to get message definitions for.
 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
 * @return {function(*): Promise<MessageDefinitionDataObject[]|undefined>}
 */
export const fetchMessageDefinitionCodebookAction = (abortCallback, campaignId, clientId = null) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-message-definitions',
		{id: campaignId, clientId},
	)(dispatch)
		// Get the list of campaigns from the response
		.then(res => getArray(res, 'data').map(i => messageDefinitionDataMap.input(i)));
};

/**
 * Fetch the available channels codebook
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {?string} [projectId=null] - DB ID of the project to get available channels for.
 * @param {?CampaignMessageContentType} [messageType=null] - Type of the message to get available channels for.
 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
 * @return {function(*): Promise<MessageDefinitionChannelDataObject[]|undefined>}
 */
export const fetchChannelsCodebookAction = (
	abortCallback, projectId = null, messageType = null, clientId = null
) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'channel/fetch-all',
		{
			projectId: !!projectId ? projectId : null,
			messageType: !!messageType ? messageType : null,
			clientId: !!clientId ? clientId : null,
		},
	)(dispatch)
		// Get the list of channels from the response
		.then(res => getArray(res, 'data').map(c => channelDataMap.input(c)));
};

/**
 * Fetch all capping plans for a specified project and client
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {?string} [projectId=null] - DB ID of the project to get capping plans for.
 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
 * @return {function(*): Promise<{active: boolean, label: string, value: string}[]>}
 */
export const fetchCappingPlansCodebookAction = (abortCallback, projectId = null, clientId = null) => dispatch => {
	return _fetchCodebookAction(abortCallback, 'frequency-capping-plan/fetch-all', {projectId, clientId})(dispatch)
		.then(list => list.map(i => ({
			label: getString(i, 'name'),
			value: getString(i, 'id'),
			active: getBool(i, 'active')
		})))
}