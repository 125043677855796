import {cloneDeep, get} from 'lodash';
import {getString} from "Core/helpers/data";
import {CustomersListItemDataObject} from "../dataObjects";
import {ltrimString} from "Core/helpers/string";
import {getDate, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CustomersListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	const mobilePhoneNumber = getString(result, 'mobilePhoneNumber');

	result = new CustomersListItemDataObject(
		getString(result, 'id'),
		getString(result, 'externalCrmId', null, true),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		(mobilePhoneNumber ? `+${ltrimString(mobilePhoneNumber, '+')}` : ''),
		getString(result, 'email'),
		getDate(get(result, 'dateOfBirth', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
	);

	return result;
}

// @note There is no output function since list is read only.