/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = `/unsubscribe`;

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = `UnsubscribePage`;