import {cloneDeep} from "lodash";
import {getArray, getBool, getString, resetObjectValues} from 'Core/helpers/data';
import {CappingItemDataObject} from "../dataObjects";
import * as ruleDataMap from "./rule";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CappingItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CappingItemDataObject(
		getString(result, 'id'),
		getString(result, 'projectId'),
		getString(result, 'name'),
		getBool(result, 'active'),
		getArray(result, 'frequencyCappingRuleDataList').map(i => ruleDataMap.input(i)),
		getString(result, 'clientId')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {CappingItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		id: inputData.id,
		projectId: inputData.projectId,
		name: inputData.name,
		active: inputData.active,
		frequencyCappingRuleDataList: getArray(inputData.rules).map(i => ruleDataMap.output(i)),
		clientId: inputData.clientId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}