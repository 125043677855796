import styles from "../index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import PropTypes from "prop-types";
import SelectInput from 'Core/components/input/SelectInput';
import Label from 'Core/components/display/Label';
import * as pageConfig from 'Pages/apps/default/projectPages/campaign/pages/ItemPage/config';
import {FormField} from 'Core/components/advanced/FormWrapper';
import {CAMPAIGN_MESSAGE_CONTENT_TYPE} from 'Pages/apps/default/projectPages/campaign/const';
import TextInput, {TEXT_INPUT_TOOLBAR_POSITION} from 'Core/components/input/TextInput';
import {getArray, getString, getStringForDisplay, trimArray} from 'Core/helpers/data';
import {FORM_FIELD_LABEL_POSITION} from 'Core/components/advanced/FormWrapper/FormField';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {cloneDeep, find, get} from 'lodash';
import {icon_font_delete_symbol, icon_font_error_symbol} from 'Config/app';
import Icon from 'Core/components/display/Icon';
import {Tooltip} from 'react-tippy';
import NotificationPreviewDialog from 'Components/dialogs/NotificationPreviewDialog';
import {MessagesListItemDeliveryDataObject} from 'Pages/apps/default/messages/dataObjects';
import {openDialog} from 'Core/helpers/dialog';
import {NOTIFICATION_PREVIEW_MODE} from 'Components/dialogs/NotificationPreviewDialog/const';

class Channel extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: `Channels.Channel`,
			domPrefix: 'channel-component',
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['usedChannels', 'usedChannelOptions', 'channelOptions', 'onChange', 'onDelete'],
		});

		// Initiate component's state
		this.state = {
			/**
			 * Name of the mobile app for push notification channel
			 */
			mobileAppName: '',
			/**
			 * List of channels that are not used for other channels
			 * @type {ChannelSelectOptionDataObject[]}
			 */
			availableChannels: getArray(props, 'channelOptions'),
			/**
			 * Flag that specifies if channel settings form will be shown
			 * @type {boolean}
			 */
			showSettingsForm: false,
		};

		// Data methods
		this.updateData = this.updateData.bind(this);
		this.updateSender = this.updateSender.bind(this);
		this.selectSingleSender = this.selectSingleSender.bind(this);

		// Change handle methods
		this.handleChannelSelectAndUpdate = this.handleChannelSelectAndUpdate.bind(this);

		// Validation methods
		this.getValidationErrors = this.getValidationErrors.bind(this);

		// Dialog methods
		this.openContentPreviewDialog = this.openContentPreviewDialog.bind(this);

		// Render methods
		this.renderChannel = this.renderChannel.bind(this);
		this.renderMainChannel = this.renderMainChannel.bind(this);
		this.renderFallbackChannel = this.renderFallbackChannel.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Calls 'onChange' event and passes the updated value
	 * @param {Object} updater - Value updater.
	 */
	updateData(updater) {
		executeComponentCallback(this.props.onChange, {...this.getProp('value'), ...updater});
	}

	/**
	 * Handles updating channel sender
	 * @param {Object} updater - Value updater.
	 * @param {CampaignMessageContentType} messageType - Type of the message.
	 */
	updateSender(updater, messageType) {
		if (messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL) {
			const {value} = this.props;
			/** @type {ChannelSelectOptionDataObject[]} */
			const availableChannels = this.state.availableChannels;
			/** @type {ChannelSelectOptionDataObject} */
			const selectedChannel = find(availableChannels, {value: get(value, 'channelId')});
			const senderItem = find(getArray(selectedChannel, 'senders'), updater);

			this.updateData({...updater, senderName: getString(senderItem, 'senderName')});
		} else {
			this.updateData(updater);
		}
	}

	/**
	 * Select the sender if it is the only one
	 */
	selectSingleSender() {
		const {value} = this.props;
		const messageType = get(value, 'messageType');
		/** @type {ChannelSelectOptionDataObject[]} */
		const availableChannels = this.state.availableChannels;
		const selectedChannel = find(availableChannels, {value: get(value, 'channelId')});
		const senderOptions = getArray(selectedChannel, 'senders').map(s => ({label: s.sender, value: s.sender}));
		const singleSender = (senderOptions.length === 1);
		
		if (singleSender) {
			this.setState({showSettingsForm: true})
				.then(() => this.updateSender({sender: senderOptions[0].value}, messageType))
				.then(() => this.setState({showSettingsForm: false}))
		}
	}


	// Change handle methods --------------------------------------------------------------------------------------------
	/**
	 * Handle selecting a channel and update component
	 * @param {ChannelSelectOptionDataObject} selectOption - Selected channel option.
	 */
	handleChannelSelectAndUpdate(selectOption) {
		const {value, usedChannels} = this.props;

		if (!!selectOption) {
			// Do nothing if selected channel did not change
			if (selectOption.value === get(value, 'channelId')) return;

			// Get the mobile app name from the selected options and save it to local state, so it can be rendered since it
			// is not present in the 'value' prop (not a part of the MessageDefinitionChannelDataObject)
			// @note This is used only for push notifications
			this.setState({mobileAppName: selectOption.mobileAppName}).then();

			let sender;
			let senderName = '';
			switch (selectOption.messageType) {
				case CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS:
				case CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER:
					/**
					 * Get the last used SMS or Viber channel option with the same provider as the selection option that has
					 * a sender specified in the related message definition channel
					 * @type {ChannelSelectOptionDataObject}
					 */
					const lastUsedOption = cloneDeep(getArray(this.props, 'usedChannelOptions'))
						.filter(o => {
							const channelSender = getString(
								find(
									getArray(usedChannels)
										.filter(c => [
											CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS,
											CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER
										].includes(c.messageType)),
									{channelId: o.value}
								),
								'sender'
							);
							const optionSender = getString(find(getArray(o, 'senders'), {sender: channelSender}), 'sender');
							return o.providerId === selectOption.providerId && !!optionSender;
						})
						.pop();
					if (!!lastUsedOption) {
						const lastUsedSender = getString(find(usedChannels, {channelId: lastUsedOption.value}), 'sender');
						// Check if selected option has the last used sender in its list of senders
						const hasSender = find(getArray(selectOption, 'senders'), {sender: lastUsedSender});

						// Set the sender to the last used sender for the SMS or Viber channel of the same provider
						if (hasSender) sender = getString(find(usedChannels, {channelId: lastUsedOption.value}), 'sender');
					}
					break;
				default:
					sender = null;
			}

			// Select sender by default if it is not selected already, and it is the only one in the list
			if (!sender && getArray(selectOption, 'senders').length === 1) {
				sender = selectOption.senders[0].sender;
				senderName = selectOption.senders[0].senderName;
			}

			this.updateData({
				channelId: selectOption.value,
				channelName: selectOption.label,
				messageType: selectOption.messageType,
				sender,
				senderName,
			});
		} else {
			this.setState({mobileAppName: '', showSettingsForm: false}).then();

			this.updateData({
				id: null,
				channelId: null,
				channelName: '',
				messageType: '',
				sender: null,
				senderName: '',
			});
		}
	}


	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * Get validation errors array
	 * @note Use this if component data is an object.
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to get the errors array for. If not
	 * specified, errors for all fields will be returned.
	 * @return {string[]|Object<string, string[]>|undefined} Validation errors array for a specified field or undefined
	 * if there are no errors for the field. If 'path' is not specified, errors for all fields will be returned.
	 */
	getValidationErrors(path = '') {
		if (path) return get(this.props, ['validationErrors', ...(Array.isArray(path) ? path : [path])]);
		else return get(this.props, 'validationErrors');
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open the notification content preview dialog
	 * @param {MessageContentDataObject} channelContent
	 */
	openContentPreviewDialog(channelContent) {
		this.contentDialogGUIID = openDialog('', NotificationPreviewDialog, {
			mode: NOTIFICATION_PREVIEW_MODE.SOURCE,
			data: new MessagesListItemDeliveryDataObject(
				'', '', '', 
				channelContent.messageType, '', channelContent, channelContent.subject, channelContent.body,
				0, false, null, null, null
			),
		}, {
			id: 'notification-preview-dialog',
			className: `notification-type-${channelContent.messageType} bordered-title`,
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 'max-content',
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), this.contentDialogGUIID], 'left')
		);
	}



	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render single channel form
	 * @return {JSX.Element}
	 */
	renderChannel() {
		const {value, isMainChannel, readOnly, messageContents, messageContentsLabel} = this.props;
		const messageType = get(value, 'messageType');
		const {showSettingsForm} = this.state;
		/** @type {ChannelSelectOptionDataObject[]} */
		const availableChannels = this.state.availableChannels;
		const selectedChannel = find(availableChannels, {value: get(value, 'channelId')});
		/** @type {MessageContentDataObject} */
		const selectedChannelContent = find(messageContents, {messageType: getString(selectedChannel, 'messageType')});
		const mobileAppName = getString(selectedChannel, 'mobileAppName');
		const senderOptions = getArray(selectedChannel, 'senders').map(s => ({label: s.sender, value: s.sender}));
		// Check if there are any validation errors for channel settings (sender, senderName, ...)
		const settingsHasValidationErrors = (
			messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS || messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER ?
				getArray(this.getValidationErrors('sender')).length > 0
				: messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL ?
					(
						getArray(this.getValidationErrors('sender')).length > 0 ||
						getArray(this.getValidationErrors('senderName')).length > 0
					)
					: false
		);

		return (
			readOnly ?
				<div 
					className={
						`${styles['channel']} ` +
						`${!isMainChannel ? `fallback-channel ${styles['fallback']} ` : `main-channel ${styles['main']}`} ` +
						`${!!selectedChannelContent ? styles['withContentPreviewBtn'] : ''}`
					}
				>
					<div>
						<Label element="strong" content={getStringForDisplay(get(value, 'channelName'), undefined, true, true)}/>
						{
							messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS ||
							messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER ?
								<small className="d-block">
									{this.t('senderField')}: {
									getStringForDisplay(get(value, 'sender'), undefined, true, true)
								}
								</small>
							: messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL ?
								<small className="d-block">
									{this.t('senderField')}: {
									getStringForDisplay(get(value, 'sender'), undefined, true, true)
								}{get(value, 'senderName') ? ` (${get(value, 'senderName')})` : ''}
								</small>
							: messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.PUSH_NOTIFICATION ?
								<small className="d-block">
									{this.t('mobileAppNameLabel')}: {getStringForDisplay(mobileAppName, undefined, true, true)}
								</small>
							: null
						}
					</div>
					{!!selectedChannelContent ?
						<Tooltip
							className={styles['contentPreviewBtnTooltip']}
							tag="div"
							title={this.t('contentTooltip')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
							touchHold={true}
							delay={250}
						>
							<Button
								className="action-btn no-border-radius"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon="file-text-o"
								label={<span>{messageContentsLabel}</span>}
								onClick={() => this.openContentPreviewDialog(selectedChannelContent)}
							/>
						</Tooltip>
						: null
					}
				</div>
				:
				<div 
					className={
						`${!isMainChannel ? `fallback-channel ${styles['fallback']}` : `main-channel ${styles['main']}`}`
					}
				>
					<SelectInput
						className="channel-select"
						simpleValue={false}
						isClearable={true}
						value={get(value, 'channelId')}
						options={availableChannels}
						onChange={this.handleChannelSelectAndUpdate}
						onFocus={() => this.setState({availableChannels: getArray(this.props, 'channelOptions')})}
						toolbarButtons={[
							{
								key: 'open-settings',
								className: (
									`${styles['channelSettingsBtn']} ${showSettingsForm ? styles['opened'] : ''} ` +
									`${settingsHasValidationErrors ? styles['error'] : ''}`
								),
								icon: settingsHasValidationErrors ? icon_font_error_symbol : 'cog',
								tooltip: (
									messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS ||
									messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER ||
									messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL ?
										this.t('channelOptionsBtn') :
										this.t('noChannelOptionsBtn')
								),
								onClick: () => this.setState({showSettingsForm: !showSettingsForm}),
								position: TEXT_INPUT_TOOLBAR_POSITION.RIGHT,
								displayType: settingsHasValidationErrors ? BUTTON_DISPLAY_TYPE.SOLID : BUTTON_DISPLAY_TYPE.NONE,
								displayStyle: settingsHasValidationErrors ? BUTTON_STYLE.ERROR : BUTTON_STYLE.SUBTLE,
								disabled: (
									messageType !== CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS &&
									messageType !== CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER &&
									messageType !== CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL
								)
							},
							{
								key: 'delete',
								className: `${styles['channelSettingsBtn']}`,
								icon: icon_font_delete_symbol,
								tooltip: this.t('Delete', 'general'),
								onClick: () => executeComponentCallback(this.props.onDelete, get(value, 'GUIID')),
								position: TEXT_INPUT_TOOLBAR_POSITION.RIGHT,
								displayType: BUTTON_DISPLAY_TYPE.NONE,
								displayStyle: BUTTON_STYLE.SUBTLE,
								hide: isMainChannel,
							},
						]}
					/>
					{
						showSettingsForm ?
							messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS ||
							messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER ?
								<div
									className={
										`channel-settings ${styles['channelSettings']} ` +
										`${!!this.getValidationErrors('mainChannelId') ? styles['error'] : ''}`
									}
								>
									<FormField
										label={this.t('senderField')}
										labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
										errorMessages={this.getValidationErrors('sender')}
									>
										<SelectInput
											isClearable={true}
											value={get(value, 'sender')}
											options={senderOptions}
											onChange={sender => this.updateSender({sender}, messageType)}
										/>
									</FormField>
									<Button
										className="close-channel-settings-button"
										icon="times"
										label={this.t('Close', 'general')}
										displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
										displayStyle={BUTTON_STYLE.SUBTLE}
										onClick={() => this.setState({showSettingsForm: false})}
									/>
								</div>
							: messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL ?
								<div
									className={
										`channel-settings ${styles['channelSettings']} ` +
										`${!!this.getValidationErrors('mainChannelId') ? styles['error'] : ''}`
									}
								>
									<FormField
										label={this.t('senderField')}
										labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
										errorMessages={this.getValidationErrors('sender')}
									>
										<SelectInput
											isClearable={true}
											value={get(value, 'sender')}
											options={senderOptions}
											onChange={selectOption => this.updateSender({sender: selectOption}, messageType)}
										/>
									</FormField>
									<FormField
										label={this.t('senderNameField')}
										labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
										errorMessages={this.getValidationErrors('senderName')}
									>
										<TextInput
											value={get(value, 'senderName')}
											onChange={e => this.updateData({senderName: getString(e, 'target.value')})}
										/>
									</FormField>
									<Button
										className="close-channel-settings-button"
										icon="times"
										label={this.t('Close', 'general')}
										displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
										displayStyle={BUTTON_STYLE.SUBTLE}
										onClick={() => this.setState({showSettingsForm: false})}
									/>
								</div>
							: messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.PUSH_NOTIFICATION ?
								<small className={`default-color d-block ${styles['channelSettingsInfo']}`}>
									<span>{this.t('mobileAppNameLabel')}: {
										getStringForDisplay(mobileAppName, undefined, true, true)}</span>
								</small>
							: null
						:
							messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.SMS ||
							messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.VIBER ?
								<small
									className={`default-color cursor-pointer d-block ${styles['channelSettingsInfo']}`}
									onClick={() => this.setState({showSettingsForm: !showSettingsForm})}
								>
									<span>{this.t('senderField')}: {
										getStringForDisplay(get(value, 'sender'), undefined, true, true)}</span>
									<Icon symbol="angle-down" />
								</small>
							: messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.EMAIL ?
								<small
									className={`default-color cursor-pointer d-block ${styles['channelSettingsInfo']}`}
									onClick={() => this.setState({showSettingsForm: !showSettingsForm})}
								>
								<span>{this.t('senderField')}: {
									getStringForDisplay(get(value, 'sender'), undefined, true, true)
								}{get(value, 'senderName') ? ` (${get(value, 'senderName')})` : ''}</span>
									<Icon symbol="angle-down" />
								</small>
							: messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.PUSH_NOTIFICATION ?
								<small className={`default-color d-block ${styles['channelSettingsInfo']}`}>
									<span>{this.t('mobileAppNameLabel')}: {
										getStringForDisplay(mobileAppName, undefined, true, true)}</span>
								</small>
							: null
					}
				</div>
		);
	}

	/**
	 * Render single channel form if this is a main channel
	 * @return {JSX.Element}
	 */
	renderMainChannel() {
		const {readOnly, supportPublish} = this.props;

		return (
			readOnly ?
				<FormField
					className={`multiline-form-field channel-row ${styles['channelRow']} ${styles['mainRow']}`}
					label={this.t('mainChannelField')}
				>
					{this.renderChannel()}
				</FormField>
				:
				<FormField
					className={`multiline-form-field channel-row channel-row ${styles['channelRow']} ${styles['mainRow']}`}
					label={
						supportPublish ?
							<><Label content={this.t('mainChannelField')} /><sup>1</sup></>
							:
							this.t('mainChannelField')
					}
					labelProps={
						supportPublish ? 
							{
								element: 'span',
								elementProps: {className: 'cursor-help'},
								tooltip: this.t('required_for_publish', `${pageConfig.translationPath}.MessageDefinitionPopup`),
							}
						: 
							{}
					}
					errorMessages={this.getValidationErrors('mainChannelId')}
				>
					{this.renderChannel()}
				</FormField>
		);
	}

	/**
	 * Render single channel form if this is a fallback channel
	 * @return {JSX.Element}
	 */
	renderFallbackChannel() {
		const {index} = this.props;

		return (
			<FormField
				className={
					`multiline-form-field channel-row ${styles['fallbackRow']} ${styles['channelRow']} ` +
					`${index === 1 ? styles['fallbackFirst'] : (index > 1 ? styles['fallbackRest'] : '')}`
				}
				label={index === 1 ? this.t('channelFallbacksField') : ''}
			>
				{this.renderChannel()}
			</FormField>
		);
	}

	/** @inheritDoc */
	canRender() {
		return !!this.getProp('value');
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;

		const {className, isMainChannel} = this.props;

		return (
			<div id={this.getId()} className={`${this.getOption('domPrefix')} ${styles['item']} ${className}`}>
				{isMainChannel ? this.renderMainChannel() : this.renderFallbackChannel()}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Channel.propTypes = {
	...BaseComponent.propTypes,

	// Wrapper element ID attribute
	id: PropTypes.string,
	// Wrapper element class attribute
	className: PropTypes.string,
	// Index of the channel item
	index: PropTypes.number.isRequired,
	// Flag that specifies if this is a main channel
	isMainChannel: PropTypes.bool,
	// @type {MessageDefinitionChannelDataObject}
	value: PropTypes.object,
	// @type {MessageDefinitionChannelDataObject[]}
	usedChannels: PropTypes.arrayOf(PropTypes.object),
	// @type {ChannelSelectOptionDataObject[]}
	usedChannelOptions: PropTypes.arrayOf(PropTypes.object),
	// @type {ChannelSelectOptionDataObject[]}
	channelOptions: PropTypes.arrayOf(PropTypes.object),
	// Content of all the message types
	// @note Used only in read-only mode to render a content preview button that will open a preview dialog.
	// @type {MessageContentDataObject[]}
	messageContents: PropTypes.arrayOf(PropTypes.object),
	// Message contents button label
	messageContentsLabel: PropTypes.string,
	// Flag that specifies if this component should be rendered in the read-only mode where nothing can be changed
	readOnly: PropTypes.bool,
	// Flag that specifies if publishing is supported
	// @description This only determines if publish tooltip will be shown on the main channel label.
	supportPublish: PropTypes.bool,

	/** @type {Function<MessageDefinitionChannelDataObject>} */
	onChange: PropTypes.func,
	/** @type {Function<string>} - Function with GUIID argument */
	onDelete: PropTypes.func,

	// Validation errors for the channel
	validationErrors: PropTypes.object,
};

/**
 * Define component default values for own props
 */
Channel.defaultProps = {
	id: '',
	className: '',
};

export default Channel;