import * as applicationConfig from "../config";
import Icon from "../../../../core/components/display/Icon";
import {AclCheckDataObject} from "Core/acl";
import {getArray} from "Core/helpers/data";
import {rtrimChar} from "Core/helpers/string";

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = <Icon symbol="home" />

/**
 * Access control settings
 * @type {AclCheckDataObject}
 */
export const access = new AclCheckDataObject(
	[...getArray(applicationConfig, 'access.permissions'), ],
	[...getArray(applicationConfig, 'access.userTypes'), ],
	undefined
);

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = `${rtrimChar(applicationConfig.routerPath, '/')}`;
/**
 * Page router options used for router matching and main sidebar to highlight the current page
 * @type {{[exact]: boolean, [strict]: boolean, [sensitive]: boolean}}
 */
export const routerOptions = {exact: true};

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = `${applicationConfig.translationPath}.HomePage`;