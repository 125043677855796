import {cloneDeep, get} from "lodash";
import {getArray} from 'Core/helpers/data';

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'projectSelector';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@projectSelector/reset',
	SET_PROJECT_LIST: '@projectSelector/set_project_list',
	CLEAR_PROJECT_LIST: '@projectSelector/clear_project_list',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {ProjectListItemDataObject[]} list
	 * @return {{type: string, list: ProjectListItemDataObject[]}}
	 */
	setProjectList: list => ({type: REDUCER_TYPES.SET_PROJECT_LIST, list}),
	clearProjectList: () => ({type: REDUCER_TYPES.CLEAR_PROJECT_LIST}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/**
	 * List of all projects used in main navigation
	 * @note null means that the list is not loaded yet.
	 * @type {ProjectListItemDataObject[]}
	 */
	projectList: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return {...initialState};
		
		case REDUCER_TYPES.SET_PROJECT_LIST: return {...state, projectList: action.list};
		case REDUCER_TYPES.CLEAR_PROJECT_LIST: return {...state, projectList: cloneDeep(initialState.projectList)};
		
		default: return state;
	}
};

// Selectors
export const selectors = {
	/** 
	 * @return {ProjectListItemDataObject[]|null|undefined} Where null means that project list is not loaded and 
	 * undefined meas that there was an error setting or getting the project list.
	 */
	getProjectList: state => get(state, [reducerStoreKey, 'projectList']),
	/**
	 * @return {ProjectListItemDataObject|undefined} Returns a project list item if it is the only project in the list.
	 * Otherwise, it returns undefined.
	 */
	getSingleProject: state => (
		getArray(state, [reducerStoreKey, 'projectList']).length === 1 ?
			get(state, [reducerStoreKey, 'projectList', 0])
			: undefined
	)};

export default reducer;