import {getArray} from 'Core/helpers/data';

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'statistics';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@statistics/reset',
	SET: '@statistics/set',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {StatisticsItemDataObject[]} statistics
	 * @return {{type: string, statistics: StatisticsItemDataObject[]}}
	 */
	setStatistics: statistics => ({type: REDUCER_TYPES.SET, statistics}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {StatisticsItemDataObject[]} */
	data: [],
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET: return {...state, data: action.statistics};

		default: return state;
	}
};

// Selectors
export const selectors = {
	/** @type {StatisticsItemDataObject[]} */
	getStatistics: state => getArray(state, [reducerStoreKey, 'data']),
};

export default reducer;