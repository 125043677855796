/** @typedef {string} NotificationPreviewMode */
/**
 * Preview of that was actually sent (personalized). This will require recipient data and delivery date.
 * @type {string}
 */
export const NOTIFICATION_PREVIEW_MODE_REAL = 'REAL';
/**
 * Preview of notification source (not personalized).
 * @type {string}
 */
export const NOTIFICATION_PREVIEW_MODE_SOURCE = 'SOURCE';
/** @enum {NotificationPreviewMode} */
export const NOTIFICATION_PREVIEW_MODE = {
	REAL: NOTIFICATION_PREVIEW_MODE_REAL,
	SOURCE: NOTIFICATION_PREVIEW_MODE_SOURCE,
};
/** @type {NotificationPreviewMode[]} */
export const NOTIFICATION_PREVIEW_MODES = [
	NOTIFICATION_PREVIEW_MODE_REAL,
	NOTIFICATION_PREVIEW_MODE_SOURCE,
];