/**
 * Submit a client request for a demo
 *  
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} email - Email address of the client that requested a demo.
 * @return {(function(*))|*}
 */
export const requestDemoAction = (abortCallback, email) => dispatch => {
	// TODO:
	return Promise.resolve({success: true});
}