import React from "react";
import {translate} from "Core/i18n";
import {app_id, locale_cookie_group, sidebar_menu_shrank_cookie_group, skin_mode_cookie_group} from "Config/app";

/**
 * Cookie policy for sr-Latn-RS locale
 * @note This will automatically be used in CookiePolicy component (index.js) if sr-Latn-RS is the current locale. 
 * There is no need to use this component directly.
 * 
 * @param {any} props - Any prop that will be added to the DIV element.
 * @return {JSX.Element}
 * @constructor
 */
function CookiePolicySrLatnRS(props) {
	return (
		<div {...props}>
			<h2>O kolačićima</h2>
			<p>...</p>

			<h2>Specifikacija kolačića</h2>
			<div className="data-table-component standard">
				<table>
					<thead>
						<tr>
							<th className="column"><div className="content">KATEGORIJA</div></th>
							<th className="column" width="1"><div className="content">NAZIV</div></th>
							<th className="column"><div className="content">OPIS</div></th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_pauseRequests</div></td>
							<td><div className="content">Kolačić koji se koristi za pauziranje sistemskih procesa</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authToken</div></td>
							<td><div className="content">Kolačić koji se koristi za autorizaciju ulogovanog korisnika</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authTokenData</div></td>
							<td><div className="content">Kolačić koji sadrži autorizacioni ključ trenutno ulogovanog korisnika</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshToken</div></td>
							<td><div className="content">Kolačić koji se koristi za autorizaciju ulogovanog korisnika</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshingToken</div></td>
							<td><div className="content">Kolačić koji se koristi za autorizaciju ulogovanog korisnika</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_acl</div></td>
							<td><div className="content">Kolačić koji sadrži listu privilegija trenutno ulogovanog korisnika</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_currentUser</div></td>
							<td><div className="content">Kolačić koji sadrži sistemske podatke trenutno ulogovanog korisnika (korisničko ime, tip, itd.)</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_userActivity</div></td>
							<td><div className="content">Kolačić koji sadrži sistemske podatke o aktivnosti trenutno ulogovanog korisnika</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">projectId</div></td>
							<td><div className="content">Kolačić koji sadrži identifikator trenutno izabranog projekta</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_cookie_consent_settings</div></td>
							<td><div className="content">Kolačić koji sadrži informaciju o tome koje kolačiće je korisnik prihvatio</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${locale_cookie_group}`)}</div></td>
							<td><div className="content">{app_id}_locale</div></td>
							<td><div className="content">Kolačić koji sadrži sistemski kod regiona na osnovu kojeg se određuje jezik koji će biti korišćen</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${sidebar_menu_shrank_cookie_group}`)}</div></td>
							<td><div className="content">sidebar_menu_shrank</div></td>
							<td><div className="content">Kolačić koji određuje veličinu glavnog navigacionog menija</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:necessary`)}</div></td>
							<td><div className="content">sidebar_menu_groups_statuses</div></td>
							<td><div className="content">Kolačić koji određuje koji elementi glavnog navigacionog menija će biti otvoreni</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${skin_mode_cookie_group}`)}</div></td>
							<td><div className="content">skin_mode</div></td>
							<td><div className="content">Kolačić koji određuje temu (boje) aplikacije</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${skin_mode_cookie_group}`)}</div></td>
							<td><div className="content">use_system_skin_mode</div></td>
							<td><div className="content">Kolačić koji određuje temu (boje) aplikacije</div></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CookiePolicySrLatnRS;