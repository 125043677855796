import {hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {ioUploadAction} from "Core/store/actions/io";
import {getArray, getObject} from "Core/helpers/data";

/**
 * Validate columns of a customer list file
 * @note This is usually done before importing customer messages.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {{[clientId]: string, customers: FormData}} data - Data to submit.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const validateCustomerListFileColumnsAction = (abortCallback, data, loadingOverlaySelector) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioUploadAction(
		abortCallback,
		'defaultAuthorizedApi',
		'customer/validate-file-columns',
		data,
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		// Get file columns object where keys are column names from the file and values are constant values or 
		// customerCustomField names to which column names from file are mapped to.
		.then(res => (res ? getObject(res, 'data') : undefined));
};

/**
 * Validate customer list file
 * @note This is usually done before importing customer messages.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {{[clientId]: string, header: Object<string, string>, customers: FormData}} data - Data to submit.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @return {function(*): Promise<{row: number, property: string, errorType: string, errorValue: string}[]>} Promise that
 * will resolve with the list of errors per file row or undefined if request fails.
 */
export const validateCustomerListFileAction = (abortCallback, data, loadingOverlaySelector) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioUploadAction(
		abortCallback,
		'defaultAuthorizedApi',
		'customer/validate-file-content',
		data,
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		// Get errors
		.then(res => (res ? getArray(res, 'data') : undefined));
}

/**
 * Add customers from the import file
 * @description Creates new customers and update existing ones if 'override' flag is set to true.
 * @note This action should be called after both file columns and file content validation passes.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {{
 * 	[clientId]: string, 
 * 	[uniqueIdColumn]: string, 
 * 	override: boolean, 
 * 	header: Object<string, string>, 
 * 	customers: FormData
 * }} data - Data to submit.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @return {function(*): *}
 */
export const addCustomersFromFileAction = (abortCallback, data, loadingOverlaySelector) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioUploadAction(
		abortCallback,
		'defaultAuthorizedApi',
		'customer/add-customers-from-file',
		data,
		hideLoadingFunction(loading),
	)(dispatch);
};

/**
 * Add customer messages based on recipients import file
 * @note This action should be called after both file columns and file content validation passes. This action will also
 * create new customers and update existing ones if 'override' flag is set to true.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {{
 * 	[clientId]: string, 
 * 	messageDefinitionId: string, 
 * 	override: boolean, 
 * 	header: Object<string, string>, 
 * 	customers: FormData
 * }} data - Data to submit.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @return {function(*): *}
 */
export const addCustomerMessagesFromFileAction = (abortCallback, data, loadingOverlaySelector) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioUploadAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/add-customer-messages-from-file',
		data,
		hideLoadingFunction(loading),
	)(dispatch);
};

/**
 * Add customers to a blacklist from the import file
 * @note This action should be called after both file columns and file content validation passes.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {{}} data - Data to submit.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @return {function(*): *}
 */
export const addToBlacklistFromFileAction = (abortCallback, data, loadingOverlaySelector) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioUploadAction(
		abortCallback,
		'defaultAuthorizedApi',
		'blacklist/add-customers-from-file-to-blacklist',
		data,
		hideLoadingFunction(loading),
	)(dispatch);
};

/**
 * Remove customers from a blacklist using the import file
 * @note This action should be called after both file columns and file content validation passes.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {{}} data - Data to submit.
 * @param {string} [loadingOverlaySelector] - Selector for the loading overlay.
 * @return {function(*): *}
 */
export const removeFromBlacklistFromFileAction = (abortCallback, data, loadingOverlaySelector) => dispatch => {
	const loading = (loadingOverlaySelector ? showLoading(loadingOverlaySelector) : undefined);
	return ioUploadAction(
		abortCallback,
		'defaultAuthorizedApi',
		'blacklist/remove-customers-from-file-from-blacklist',
		data,
		hideLoadingFunction(loading),
	)(dispatch);
};