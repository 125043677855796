import {cloneDeep, get} from "lodash";
import {getBool, getString, resetObjectValues} from 'Core/helpers/data';
import {CampaignItemDataObject} from "../dataObjects";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {endOfDay, startOfDay} from 'date-fns';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CampaignItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CampaignItemDataObject(
		getString(result, 'id'),
		getString(result, 'projectId'),
		getString(result, 'name'),
		getString(result, 'description'),
		getDate(get(result, 'startDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'endDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'campaignStatus'),
		get(result, 'cappingPlan') ? {
			label: getString(result, 'cappingPlan.name'), 
			value: getString(result, 'cappingPlan.id'),
			active: getBool(result, 'cappingPlan.active')
		} : null,
	);

	return result;
}

/**
 * Get output data from input data
 * @param {CampaignItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		projectId: result.projectId,
		clientId: result.clientId,
		name: result.name,
		description: result.description,
		startDate: getDateString(startOfDay(result.startDate), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		endDate: getDateString(endOfDay(result.endDate), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		campaignStatus: result.status
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}