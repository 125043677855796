import {cloneDeep, pickBy} from 'lodash';
import {getObject, getString, resetObjectValues} from "Core/helpers/data";
import {MessageContentDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {MessageContentDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	let bodySource;
	try { bodySource = JSON.parse(getString(result, 'bodySource')); } 
	catch (e) { bodySource = undefined; }

	result = new MessageContentDataObject(
		getString(result, 'id'),
		getString(result, 'messageType'),
		getString(result, 'subject'),
		getString(result, 'body'),
		bodySource,
		getString(result, 'image'),
		getString(result, 'link'),
		getString(result, 'linkText'),
		getObject(result, 'customFields')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {MessageContentDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);
	
	let bodySource;
	try { bodySource = JSON.stringify(inputData.bodySource); } 
	catch (e) { bodySource = undefined; }

	result = {
		id: result.id,
		messageType: result.messageType,
		subject: result.subject,
		body: result.body,
		bodySource,
		image: result.image,
		link: result.link,
		linkText: result.linkText,
		// Remove undefined object keys
		customFields: pickBy(result.customFields, (v, k) => k !== 'undefined')
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result, {
		customFields: {},
	});

	return result;
}