export class MessagePreviewOptionsSMS {
	/**
	 * @param {string} [body=''] - Message body.
	 * @param {?number} [bodyLimit=null] - Limit the number of characters when previewing the body.
	 */
	constructor(body = '', bodyLimit = null) {
		this.body = body;
		
		this.bodyLimit = bodyLimit;
	}
}

export class MessagePreviewOptionsPUSH_NOTIFICATION {
	/**
	 * @param {string} [subject=''] - Push notification subject.
	 * @param {string} [body=''] - Push notification body.
	 * @param {string} [image=''] - URL of the image that will be added below the message body.
	 * @param {string} [link=''] - Link used for the button that will be added to the bottom of the message.
	 * @param {string} [linkText=''] - Label of the button that will be added to the bottom of the message.
	 * @param {Object} [customFields={}] - Custom fields that can be used inside the message.
	 * @param {?number} [subjectLimit=null] - Limit the number of characters when previewing the subject.
	 * @param {?number} [bodyLimit=null] - Limit the number of characters when previewing the body.
	 */
	constructor(
		subject = '', body = '', image = '', link = '', linkText = '', customFields = {}, subjectLimit = null, 
		bodyLimit = null
	) {
		this.subject = subject;
		this.body = body;
		this.image = image;
		this.link = link;
		this.linkText = linkText;
		
		this.customFields = customFields;
		this.bodyLimit = bodyLimit;
	}
}

export class MessagePreviewOptionsEMAIL {
	/**
	 * @param {string} [subject=''] - Subject of the message.
	 * @param {string} [body=''] - Message body text.
	 * @param {any} [bodySource] - Message body source.
	 * @param {?number} [subjectLimit=null] - Limit the number of characters when previewing the subject.
	 * @param {?number} [bodyLimit=null] - Limit the number of characters when previewing the body.
	 */
	constructor(subject = '', body = '', bodySource, subjectLimit = null, bodyLimit = null) {
		this.subject = subject;
		this.body = body;
		this.bodySource = bodySource;

		this.subjectLimit = subjectLimit;
		this.bodyLimit = bodyLimit;
	}
}

export class MessagePreviewOptionsVIBER {
	/**
	 * @param {string} [body=''] - Message body text.
	 * @param {string} [image=''] - URL of the image that will be added below the message body.
	 * @param {string} [link=''] - Link used for the button that will be added to the bottom of the message.
	 * @param {string} [linkText=''] - Label of the button that will be added to the bottom of the message.
	 * @param {?number} [subjectLimit=null] - Limit the number of characters when previewing the subject.
	 * @param {?number} [bodyLimit=null] - Limit the number of characters when previewing the body.
	 */
	constructor(body = '', image = '', link = '', linkText = '', subjectLimit = null, bodyLimit = null) {
		this.body = body;
		this.image = image;
		this.link = link;
		this.linkText = linkText;

		this.subjectLimit = subjectLimit;
		this.bodyLimit = bodyLimit;
	}
}