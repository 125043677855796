import styles from "./index.module.css";

import React from "react";
import DialogDataComponent from "Core/components/DialogDataComponent";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {CampaignItemDataObject} from "../../dataObjects";
import * as pageConfig from "../../config";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {
	icon_font_close_symbol,
	icon_font_create_symbol,
	icon_font_delete_symbol,
	icon_font_restore_symbol, icon_font_save_symbol
} from "Config/app";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {getBool, isset} from "Core/helpers/data";
import DataValueValidation from "Core/validation";
import {getCurrentProjectFromUrl, getCurrentProjectIdFromUrl} from "../../../../../../../helpers/project";
import {selectors} from "Core/store/reducers";
import Spinner from "Core/components/display/Spinner";
import {isMacintosh} from "Core/helpers/system";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	projectList: selectors.projectSelector.getProjectList(state),
});

class CreateItemDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: new CampaignItemDataObject(undefined, getCurrentProjectIdFromUrl(props.location)),
		}, {
			translationPath: `${pageConfig.translationPath}.CreateItemDialog`,
			domPrefix: 'create-item-dialog',
			disableLoad: true,
		});
	}
	
	componentDidMount() {
		super.componentDidMount();

		const {location, projectList} = this.props;
		const projectFromUrl = getCurrentProjectFromUrl(projectList, location);

		// Close dialog if project list failed to load or loaded project list does not include the project form URL
		if (!isset(projectList) || (Array.isArray(projectList) && !isset(projectFromUrl))) this.close();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {location, projectList} = this.props;
		const projectFromUrl = getCurrentProjectFromUrl(projectList, location);
		
		// Close dialog if project list failed to load or loaded project list does not include the project form URL
		if (!isset(projectList) || (Array.isArray(projectList) && !isset(projectFromUrl))) {
			this.close();
			return Promise.resolve(this.state);
		}
		
		return super.componentDidUpdate(prevProps, prevState, snapshot);
	}

	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('name', 'required');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render dialog action buttons
	 * @note Low-level method to render any dialog action buttons.
	 *
	 * @param {DialogButton[]} buttons - Array of buttons to render.
	 * @param {string} [className=''] - Buttons wrapper additional CSS class.
	 * @return {*} Action buttons JSX to use in the main render method.
	 * 
	 * @override Added 'className' to the Button component.
	 */
	renderActionButtons(buttons = [], className = '') {
		return (
			buttons.length > 0 ?
				<div className={`buttons ${className}`}>
					{buttons.map((button, index) =>
						<Button
							key={index}
							className={styles['actionButton']}
							icon={button.icon}
							displayType={button.type ? button.type : BUTTON_DISPLAY_TYPE.SOLID}
							displayStyle={button.style ? button.style : BUTTON_STYLE.DEFAULT}
							onClick={button.onClick}
							disabled={getBool(button, 'disabled')}
						>
							{button.label ? button.label : null}
						</Button>
					)}
				</div>
				:
				null
		);
	}

	/**
	 * Render dialog action buttons used by data dialogs
	 * @note Every dialog that is used to manipulate some data should use these action buttons on the bottom of the main
	 * render method. Standard buttons include create, save, delete, restore and close and they will be rendered
	 * depending on dialog type and available events (isNew, isRestore, onDelete, ...).
	 *
	 * @param {string|null} [createLabel] - Label used for create button. Default value will be loaded from translation file.
	 * @param {string|null} [createIcon] - Optional icon used for create button.
	 * @param {string|null} [deleteLabel] - Label used for delete button. Default value will be loaded from translation file.
	 * @param {string|null} [deleteIcon] - Optional icon used for delete button.
	 * @param {string|null} [saveLabel] - Label used for save button. Default value will be loaded from translation file.
	 * @param {string|null} [saveIcon] - Optional icon used for save button.
	 * @param {string|null} [closeLabel] - Label used for close button. Default value will be loaded from translation file.
	 * @param {string|null} [closeIcon] - Optional icon used for close button.
	 * @param {string|null} [restoreLabel] - Label used for restore button. Default value will be loaded from translation
	 * file.
	 * @param {string|null} [restoreIcon] - Optional icon used for restore button.
	 * @param {boolean} [hideClose=false] - If true, close button will not be rendered. This is useful for confirm
	 * dialogs.
	 * @return {*} Action buttons JSX to use in the main render method.
	 * 
	 * @override Action buttons disabled if projectList is null (project list is loading).
	 */
	renderDataActionButtons({
		createLabel = undefined, createIcon = undefined, deleteLabel = undefined, deleteIcon = undefined,
		saveLabel = undefined, saveIcon = undefined, closeLabel = undefined, closeIcon = undefined,
		restoreLabel = undefined, restoreIcon = undefined
	} = {}, hideClose = false) {
		const {isNew, isRestore, onDelete, projectList} = this.props;

		let buttons = [];

		// Close/cancel button
		if (isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});
		
		// Delete button
		if (onDelete && !isNew) buttons.push({
			style: BUTTON_STYLE.ERROR,
			label: (deleteLabel ? deleteLabel : this.t('Delete', 'general')),
			icon: (isset(deleteIcon) && deleteIcon !== null ? deleteIcon : icon_font_delete_symbol),
			onClick: this.delete,
			disabled: (projectList === null),
		});

		// Save buttons (create, restore and save)
		if (isNew) buttons.push({
			style: BUTTON_STYLE.ACTION,
			label: (createLabel ? createLabel : this.t('Create', 'general')),
			icon: (typeof createIcon === 'undefined' ? icon_font_create_symbol : createIcon),
			onClick: this.save,
			disabled: (projectList === null),
		});
		else if (isRestore) buttons.push({
			style: BUTTON_STYLE.ACTION,
			label: (restoreLabel ? restoreLabel : this.t('Restore', 'general')),
			icon: (isset(restoreIcon) && restoreIcon !== null ? restoreIcon : icon_font_restore_symbol),
			onClick: this.save,
			disabled: (projectList === null),
		});
		else buttons.push({
				style: BUTTON_STYLE.ACTION,
				label: (saveLabel ? saveLabel : this.t('Save', 'general')),
				icon: (isset(saveIcon) && saveIcon !== null ? saveIcon : icon_font_save_symbol),
				onClick: this.save,
				disabled: (projectList === null),
			});

		// Close/cancel button
		if (!isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		return this.renderActionButtons(buttons);
	}
	
	render() {
		const {projectList} = this.props;
		
		/** @type {CampaignItemDataObject} */
		const data = this.getData();
		
		return this.renderDialog(
			this.renderTitle(this.t('new_title')),
			(<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				{
					projectList === null ?
						<div className={styles['loadingWrapper']}>
							<Spinner element="div" className={styles['loading']} />
						</div>
						:
						<FormWrapper>
							<FormField
								required={true}
								label={this.t('nameField')}
								labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
								errorMessages={this.getValidationErrors('name')}
							>
								<TextInput
									name="name"
									value={data.name}
									onChange={this.handleInputChange}
									onEnterKey={this.save}
									inputProps={{autoFocus: true}}
								/>
							</FormField>
						</FormWrapper>
				}
			</div>),
			{
				createLabel: this.t('create_action'), 
				createIcon: icon_font_create_symbol,
				closeLabel: this.t('Cancel', 'general'),
			}
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CreateItemDialog.propTypes = {
	
};

/**
 * Define component default values for own props
 */
CreateItemDialog.defaultProps = {
	// @important Do not change or set this prop!
	isNew: true,
};

export default withRouter(connect(mapStateToProps, null)(CreateItemDialog));