import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";

import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {getSkin} from "Core/helpers/skin";
import {SKIN_MODE} from "Core/const/global";

/**
 * 
 * @see https://ace.c9.io/build/kitchen-sink.html
 */
class CodeEditorInput extends BaseComponent {
	constructor(props) {
		super(props, {
			updateOnSkinChange: true,
		});

		// Initiate component's state
		this.state = {
			mode: null,
			lightTheme: null,
			darkTheme: null,
		};
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount() {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount();

		const {mode, lightTheme, darkTheme} = this.props;
		
		// Dynamically import mode
		await import(`ace-builds/src-noconflict/mode-${mode}`)
			// Set layout if it hasn't been set yet
			.then(({default: importedMode}) => this.setState({mode: importedMode}));

		// Dynamically import themes
		await import(`ace-builds/src-noconflict/theme-${lightTheme}`)
			// Set layout if it hasn't been set yet
			.then(({default: importedLightTheme}) => this.setState({lightTheme: importedLightTheme}));
		await import(`ace-builds/src-noconflict/theme-${darkTheme}`)
			// Set layout if it hasn't been set yet
			.then(({default: importedDarkTheme}) => this.setState({darkTheme: importedDarkTheme}));
		
		return Promise.resolve();
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const {mode, lightTheme, darkTheme} = this.state;
		
		// Do not allow component render if mode and themes are not loaded 
		return !!mode && !!lightTheme && !!darkTheme;
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		const {lightTheme, darkTheme, ...allProps} = this.props;
		const themeName = (getSkin()  === SKIN_MODE.DARK ? darkTheme : lightTheme);
		
		return (
			<AceEditor 
				{...allProps} 
				theme={themeName}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CodeEditorInput.propTypes = {
	...AceEditor.propTypes,
	
	// Language for parsing and code highlighting
	mode: PropTypes.oneOf([
		'abap', 'abc', 'actionscript', 'ada', 'alda', 'apache_conf', 'apex', 'aql', 'asciidoc', 'asl', 'assembly_x86', 
		'autohotkey', 'batchfile', 'bibtex', 'c_cpp', 'c9search', 'cirru', 'clojure', 'cobol', 'coffee', 'coldfusion', 
		'crystal', 'csharp', 'csound_document', 'csound_orchestra', 'csound_score', 'css', 'curly', 'd', 'dart', 'diff', 
		'dockerfile', 'dot', 'drools', 'edifact', 'eiffel', 'ejs', 'elixir', 'elm', 'erlang', 'forth', 'fortran', 
		'fsharp', 'fsl', 'ftl', 'gcode', 'gherkin', 'gitignore', 'glsl', 'gobstones', 'golang', 'graphqlschema', 'groovy',
		'haml', 'handlebars', 'haskell', 'haskell_cabal', 'haxe', 'hjson', 'html', 'html_elixir', 'html_ruby', 'ini', 
		'io', 'ion', 'jack', 'jade', 'java', 'javascript', 'jexl', 'json', 'json5', 'jsoniq', 'jsp', 'jssm', 'jsx', 
		'julia', 'kotlin', 'latex', 'latte', 'less', 'liquid', 'lisp', 'livescript', 'log', 'logiql', 'logtalk', 'lsl', 
		'lua', 'luapage', 'lucene', 'makefile', 'markdown', 'mask', 'matlab', 'maze', 'mediawiki', 'mel', 'mips', 'mixal',
		'mushcode', 'mysql', 'nginx', 'nim', 'nix', 'nsis', 'nunjucks', 'objectivec', 'ocaml', 'partiql', 'pascal', 
		'perl', 'pgsql', 'php', 'php_laravel_blade', 'pig', 'plsql', 'powershell', 'praat', 'prisma', 'prolog', 
		'properties', 'protobuf', 'puppet', 'python', 'qml', 'r', 'raku', 'razor', 'rdoc', 'red', 'rhtml', 'robot', 'rst',
		'ruby', 'rust', 'sac', 'sass', 'scad', 'scala', 'scheme', 'scrypt', 'scss', 'sh', 'sjs', 'slim', 'smarty', 
		'smithy', 'snippets', 'soy_template', 'space', 'sparql', 'sql', 'sqlserver', 'stylus', 'svg', 'swift', 'tcl', 
		'terraform', 'tex', 'text', 'textile', 'toml', 'tsx', 'turtle', 'twig', 'typescript', 'vala', 'vbscript', 
		'velocity', 'verilog', 'vhdl', 'visualforce', 'wollok', 'xml', 'xquery', 'yaml', 'zeek', 'django'
	]),
	// Theme to use when the app uses the light skin
	lightTheme: PropTypes.oneOf([
		'chrome', 'clouds', 'crimson_editor', 'dawn', 'dreamweaver', 'eclipse', 'github', 'iplastic', 'katzenmilch', 
		'kuroir', 'solarized_light', 'sqlserver', 'textmate', 'tomorrow', 'xcode'
	]),
	// Theme to use when the app uses the dark skin
	darkTheme: PropTypes.oneOf([
		'ambiance', 'chaos', 'clouds_midnight', 'cobalt', 'dracula', 'gob', 'gruvbox', 'idle_fingers', 'kr_theme', 
		'merbivore', 'merbivore_soft', 'mono_industrial', 'monokai', 'pastel_on_dark', 'solarized_dark', 'terminal', 
		'tomorrow_night', 'tomorrow_night_blue', 'tomorrow_night_bright', 'tomorrow_night_eighties', 'twilight', 
		'vibrant_ink',
	]),
};

/**
 * Define component default values for own props
 */
CodeEditorInput.defaultProps = {
	...AceEditor.defaultProps,
	mode: 'html',
	lightTheme: 'chrome',
	darkTheme: 'tomorrow_night_bright',
};

export default CodeEditorInput;