import "./index.css";

// Import app pages
import * as homePage from "./home";
// import * as projectsPage from "./projects";
import * as statisticsPage from "./statistics";
import * as customersPage from "./customers";
import * as blacklistsPage from "./blacklists";
import * as customerCustomFieldsPage from "./customerCustomFields";
import * as projectDashboardPage from "./projectPages/dashboard";
import * as projectCampaignsPage from "./projectPages/campaign";
import * as projectCampaignPage from "./projectPages/campaign/pages/ItemPage";
import * as projectCappingPage from "./projectPages/capping";
import * as messagesPage from "./messages";
import * as messageDeliveriesPage from "./messageDeliveries";

import AppSectionComponent from "Core/components/AppSectionComponent";
import * as appConfig from "./config";
import {appHasLogin} from "Core/helpers/login";

class DefaultApp extends AppSectionComponent {
	constructor(props) {
		super(props, appConfig, {
			checkLogin: true,
			autoLogout: appHasLogin(),
			appPages: [
				homePage,
				// projectsPage,
				customersPage,
				statisticsPage,
				blacklistsPage,
				customerCustomFieldsPage,
				projectCampaignsPage,
				projectCampaignPage,
				projectCappingPage,
				projectDashboardPage,
				messagesPage,
				messageDeliveriesPage
			],
		}, 'default-app');
	}

	render() {
		return this.renderApp();
	}
}

export * from "./config";
export default DefaultApp;