import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {Object<string, string|CampaignMessageContentType>}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = {
		id: getString(result, 'id'),
		name: getString(result, 'name'),
		code: getString(result, 'code'),
		description: getString(result, 'description'),
		messageType: getString(result, 'messageType'),
	};

	return result;
}