import {cloneDeep} from "lodash";
import {getArray, getBool, getString} from 'Core/helpers/data';
import {CappingListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CappingListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CappingListItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getBool(result, 'active'),
		getArray(result, 'campaignIds').length,
	);

	return result;
}

// @note There is no output function since list is read only.