import {cloneDeep} from 'lodash';
import {getString, resetObjectValues} from "Core/helpers/data";
import {BlacklistsItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {BlacklistsItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new BlacklistsItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getString(result, 'projectId'),
		getString(result, 'messageType'),
		getString(result, 'channelId'),
		getString(result, 'clientId'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {BlacklistsItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		id: inputData.id,
		name: inputData.name,
		projectId: inputData.projectId,
		messageType: inputData.messageType,
		channelId: inputData.channelId,
		clientId: inputData.clientId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}