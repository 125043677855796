/**
 * Abstract main app component
 */
import React from "react";
import PropTypes from "prop-types";
import DataComponent from "./components/DataComponent";
import {Redirect, Route} from "react-router-dom";
import {cloneDeep, findIndex, get, map, pick, sortBy, throttle} from "lodash";
import {addErrorMessageAction} from "./components/global/Message";
import {setBreakpointAction} from "./store/actions/breakpoint";
import {
	resources_storage_path,
	responsive_breakpoints,
	responsive_default_breakpoints_name,
	responsive_mobile_breakpoint_name, 
	user_activity_trigger_events
} from "../config";
import {calculateCurrentBreakpointName} from "./helpers/dom";
import Messages from "./components/global/Messages";
import Overlay from "./components/global/Overlay";
import {LoadingOverlayObject} from "./objects";
import ACL from "../acl";
import AclErrorPage from "./pages/error/acl";
import {getArray} from "./helpers/data";
import {setStorageValue, STORAGE_TYPE} from "Core/storage";
import {AclCheckDataObject} from "Core/acl";
import {updateLastUserActivityTime} from "Core/helpers/userActivity";
import {AsyncMountError} from "Core/errors";

/**
 * Main app component
 */
class AppComponent extends DataComponent {
	/**
	 * List of imported resources required before app can be rendered
	 * @type {(function(): Promise<Object<string, *>>)[]}
	 */
	requiredResources = [];

	/**
	 * List of resource keys of the resources that will be loaded into the memory
	 * @note Resources can be big so be careful when enabling this. Resources are defined in 'requiredResources'.
	 * @type {string[]} Use '*' to load all resources.
	 */
	loadResourcesIntoMemory = [];
	
	constructor(props, initialState = {}, options = {}) {
		const _options = {
			translationPath: 'App',
			disableLoad: true,
			domManipulationIntervalTimeout: 10,
			
			...(options ? cloneDeep(options) : {})
		};

		const _initialState = {
			data: {
				resourcesLoading: true,
				resourcesLoaded: false,
				resources: {},
				resourceRedirect: undefined,
			},

			...(initialState ? cloneDeep(initialState) : {})
		};

		super(props, _initialState, _options);

		// DOM methods
		this.updateResponsiveBreakpoints = this.updateResponsiveBreakpoints.bind(this);
		
		// Render methods
		this.renderSections = this.renderSections.bind(this);
		this.renderApp = this.renderApp.bind(this);

		// Register event listeners
		this.registerEventListener('resize', this.updateResponsiveBreakpoints);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);
		
		// Load all required resources
		// This will load all the required global resources that need to be loaded before the app is rendered. For 
		// example i18n translation files need to be loaded before app is rendered.
		await Promise.allSettled(
			this.requiredResources.map(resourceFunction => resourceFunction(this.props.location))
		)
			// Return a promise that resolves to an array of loaded resources or rejects on a first failed resource.
			// @note Resources will be checked in the order they are declared in 'this.requiredResources'.
			.then(results => {
				return new Promise((resolve, reject) => {
					let loadedRes = [];
					results.forEach(result => {
						if (result.status === 'fulfilled') loadedRes.push(result.value);
						else if (result.status === 'rejected') reject(result.reason);
					});
					resolve(loadedRes);
				});
			})
			// Load resources into the memory
			.then(loadedRes => {
				const loadedResReduced = loadedRes.reduce((prev, curr) => ({...prev, ...curr}), {});
				const loadResourcesIntoMemory = getArray(this.loadResourcesIntoMemory);
				// Load all resources into the memory
				if (loadResourcesIntoMemory.includes('*')) {
					setStorageValue(resources_storage_path, loadedRes, STORAGE_TYPE.MEMORY);
				}
				// Load specified resources into the memory (if any)
				else if (loadResourcesIntoMemory.length > 0) {
					setStorageValue(
						resources_storage_path, pick(loadedResReduced, loadResourcesIntoMemory), STORAGE_TYPE.MEMORY
					);
				}
				return loadedRes;
			})
			.then(() => this.setValue('resourcesLoaded', true))
			.catch(error => {
				console.error(this.t('Resource loading error'), error.message);
				return new Promise((resolve, reject) => {
					if (error.hasOwnProperty('redirect')) {
						this.setValue('resourcesLoaded', false)
							.then(() => this.setValue('resourceRedirect', error.redirect))
							.then(() => resolve());
					} else {
						this.props.store.dispatch(addErrorMessageAction(this.t('Could not load app resource!')));
						this.setValue('resourcesLoaded', false)
							.then(() => this.setValue('resourcesLoading', false))
							.then(() => { reject(new AsyncMountError('Failed to load app resources!')); });
					}
				});
			})
			.then(() => this.setValue('resourcesLoading', false));
		
		return Promise.resolve();
	}
	
	componentDidMount() {
		super.componentDidMount();

		// Update user activity
		// @note Activity updates will be throttled, so they will only trigger once every second, not quicker.
		if (Array.isArray(user_activity_trigger_events) && user_activity_trigger_events.length > 0) {
			// Add event listeners for all events representing user activity
			window.addEventListener('load', updateLastUserActivityTime, true);
			user_activity_trigger_events.forEach(name => {
				document.addEventListener(
					name,
					throttle(updateLastUserActivityTime, 1000, {leading: true, trailing: false}),
					true
				);
			});
		}

		// Update responsive breakpoints based on window width
		this.updateResponsiveBreakpoints();
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		
		// Remove event listeners for all events representing user activity
		if (Array.isArray(user_activity_trigger_events) && user_activity_trigger_events.length > 0) {
			window.removeEventListener('load', updateLastUserActivityTime, true);
			user_activity_trigger_events.forEach(name => {
				document.removeEventListener(
					name,
					throttle(updateLastUserActivityTime, 1000, {leading: true, trailing: false}),
					true
				);
			});
		}
	}

	
	// DOM methods ------------------------------------------------------------------------------------------------------
	/**
	 * Method called on each DOM manipulation interval
	 *
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		// Handle overlay parent elements
		document.querySelectorAll('.overlay-component').forEach(overlay => {
			const overlayParent = overlay.parentElement;
			if (overlayParent) {
				overlayParent.classList.add('has-overlay-component');
				if (!overlayParent.style.position || overlayParent.style.position === 'static') {
					overlayParent.classList.add('has-overlay-component-position');
				}
				overlayParent.classList.add('has-overlay-component-overlay');
				if (overlay.classList.contains('overlay-component-blur')) {
					overlayParent.classList.add('has-overlay-component-blur');
				}
			}
		});
		document.querySelectorAll('.has-overlay-component-blur').forEach(overlayParent => {
			if (!overlayParent.querySelector('.overlay-component-blur')) {
				overlayParent.classList.remove('has-overlay-component-blur');
			}
		});
		document.querySelectorAll('.has-overlay-component').forEach(overlayParent => {
			if (!overlayParent.querySelector('.overlay-component')) {
				overlayParent.classList.remove(
					'has-overlay-component', 'has-overlay-component-position', 'has-overlay-component-overlay',
					'has-overlay-component-blur'
				)
			}
		});
	}

	/**
	 * Update responsive breakpoints based on window width
	 */
	updateResponsiveBreakpoints() {
		// Calculate current breakpoint base on window width
		const currentBreakpointName = calculateCurrentBreakpointName();

		// Set calculated current breakpoint in Redux store so that other components can use it
		this.props.store.dispatch(setBreakpointAction(currentBreakpointName));

		// Clear all breakpoint CSS classes from body tag 
		document.body.classList.remove(
			...map(responsive_breakpoints, 'name'), responsive_default_breakpoints_name
		);

		// Set current breakpoint CSS class to body tag
		document.body.classList.add(currentBreakpointName);

		// Set mobile breakpoint CSS class to body tag
		const sortedResponsiveBreakpoints = sortBy(responsive_breakpoints, ['maxWidth']);
		const currentBpIndex = findIndex(sortedResponsiveBreakpoints, {name: currentBreakpointName});
		const mobileBpIndex = findIndex(sortedResponsiveBreakpoints, {name: responsive_mobile_breakpoint_name});
		if (currentBpIndex <= mobileBpIndex) document.body.classList.add('mobile');
		else document.body.classList.remove('mobile');
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render app sections
	 * 
	 * @param {Object[]} [sections=[]] - App sections to render.
	 * @return {*}
	 */
	renderSections(sections = []) {
		// @note AppPageRoute is used as a functions because when used inside a 'Switch' component, 'Route' must be the 
		// direct child of it. If we used AppPageRoute as a component this will not be the case and 'Switch' would not 
		// work as intended.
		return getArray(sections).map((section, idx) => AppPageRoute({
			page: section, key: idx
		}));
	}

	/**
	 * Method used to render the main app
	 * 
	 * @param {JSX.Element} [routs=null] - Routes that will be available even if resources are not loaded or some 
	 * resource fails to load. All resources before the failed one will still be available to those routes (pages).
	 * @param {JSX.Element} [resourceRoutes=null] - Routes that will be available if all resources are loaded.
	 * @return {JSX.Element}
	 */
	renderApp(routs = null, resourceRoutes = null) {
		return (
			<>
				<Messages />
				{
					this.getValue('resourcesLoading') ?
						<Overlay 
							data={new LoadingOverlayObject('#root', false, false, '3rem', 3, '', true)}
							element={document.getElementById('root')}
						/> 
						:
						routs
				}
				{this.getValue('resourcesLoaded') ? resourceRoutes : null}
				{
					!this.getValue('resourcesLoaded') && typeof this.getValue('resourceRedirect') !== 'undefined'?
						<Redirect to={this.getValue('resourceRedirect')} /> 
						:
						null
				}
			</>
		);
	}
}

/**
 * Standard page route component
 * @description Use this component as a helper to render standard app pages in main 'App.js' file. It handles ACL and 
 * can handle other core functionality in the future, so you don't have to.
 * 
 * @param {string} key - React key prop.
 * @param {Object} page - Page import object containing all exports from the page file.
 * @param {boolean} [ignorePermissions=false] - If true, permissions check will be skipped and page will be rendered 
 * regardless of ACL settings.   
 * @param {PageComponent|PageDataComponent} [errorPage=null] - Custom ACL error page component. If null or not specified
 * default AclErrorPage component will be used.
 * @param {boolean} [exact=false] - When true, will only match if the path matches the location.pathname exactly. This 
 * is a prop from react-router-dom Route component.
 * @param {boolean} [strict=false] - When true, a path that has a trailing slash will only match a location.pathname 
 * with a trailing slash. This has no effect when there are additional URL segments in the location.pathname. This is a 
 * prop from react-router-dom Route component.
 * @param {boolean} [sensitive=false] - When true, will match if the path is case-sensitive. This is a prop from 
 * react-router-dom Route component.
 * @param {any} [routeProps] - Any other react-router-dom Route component prop.
 * @return {JSX.Element}
 * @constructor
 */
export function AppPageRoute({
	key,
	page, 
	ignorePermissions = false, 
	errorPage = null, 
	exact = false, 
	strict = false, 
	sensitive = false, 
	...routeProps
}) {
	const ErrorPage = (errorPage ? errorPage : AclErrorPage);
	return (
		<Route
			key={key}
			path={page.routerPath}
			component={(
				ignorePermissions ? 
					page.default 
					:
					(
						ACL.check(ACL, get(page, 'access', new AclCheckDataObject())) ? 
							page.default 
							: 
							ErrorPage
					)
			)}
			exact={exact}
			strict={strict}
			sensitive={sensitive}
			{...routeProps}
		/>
	);
}
/**
 * Define component's own props that can be passed to it by parent components
 */
AppPageRoute.propTypes = {
	page: PropTypes.object.isRequired,
	ignorePermissions: PropTypes.bool,
	errorPage: PropTypes.elementType,
	exact: PropTypes.bool,
	strict: PropTypes.bool,
	sensitive: PropTypes.bool
};

/**
 * Define component's own props that can be passed to it by parent components
 */
AppComponent.propTypes = {
	// Redux store
	store: PropTypes.object.isRequired
};

export default AppComponent;