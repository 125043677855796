import "./index.css";

import React from "react";
import PageComponent from "../../../core/components/PageComponent";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as pageConfig from "./config";
import {Link} from "react-router-dom";
import Label, {LABEL_ICON_POSITION} from "Core/components/display/Label";
import {routerPath as defaultAppHomeRouterPath} from "../../apps/default/home/config";
import {appHasLogin} from "Core/helpers/login";
import {selectors} from "Core/store/reducers";
import {getRouterPathUrl} from "Core/helpers/url";
import {getObject} from "Core/helpers/data";
import {app_login_page_router_path} from 'Config/app';
// import * as requestDemoPage from "../requestDemo";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	currentUser: selectors.getCurrentUser(state),
});

class SiteHomePage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'site_default',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath
		}, null);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return 'site-home-page'; }


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {currentUser} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()}>
				<div className="hero">
					<Label element="h1" elementProps={{className: 'hero-title'}} content={this.tt('title', 'hero')} />
					<Label 
						element="p" 
						elementProps={{className: 'hero-description'}} 
						content={this.tt('description', 'hero')} 
						supportHtml={true} 
					/>
					<Link 
						to={
							!appHasLogin() || currentUser ? 
								getRouterPathUrl(defaultAppHomeRouterPath, getObject(this.props, 'match')) :
								getRouterPathUrl(app_login_page_router_path, getObject(this.props, 'match'))
							// getRouterPathUrl(requestDemoPage.routerPath, getObject(this.props, 'match'))
						}
						className="hero-call-to-action button action solid"
					>
						<Label
							iconPosition={LABEL_ICON_POSITION.RIGHT}
							icon="arrow-right"
							content={this.tt(
								currentUser ? 'call_to_action_authorized' : 'call_to_action_login', 'hero'
							)} 
						/>
					</Link>
				</div>
			</div>
		));
	}
}

export * from "./config";
export default withRouter(connect(mapStateToProps, null)(SiteHomePage));