import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import SelectInput from 'Core/components/input/SelectInput';
import {omit} from 'lodash';
import PropTypes from 'prop-types';
import {getArray, getBoolean} from 'Core/helpers/data';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions();

class CampaignSelectInput extends BaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			/** @type {CampaignListItemDataObject[]|{label: string, value: string}|null|undefined} */
			campaignList: null,
			/** @type {boolean} */
			campaignListLoading: false,
		};
		
		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		// Load select options
		await this.loadOptions();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {projectIdRequired} = this.props;
		
		// Re-load select options when project ID or client ID changes
		if (this.props.projectId !== prevProps.projectId || this.props.clientId !== prevProps.clientId) {
			if (projectIdRequired) {
				if (!this.props.projectId) {
					this.setState({campaignList: []}).then(() => executeComponentCallback(this.props.onChange, null));
				} else {
					this.loadOptions().then();
				}
			} else {
				this.loadOptions().then();
			}
		}
	}
	
	/**
	 * Load select options
	 * @param {boolean} [onMount=false] - Specifies if this method is called as part of the component mount cycle.
	 * @return {Promise<void>}
	 */
	async loadOptions(onMount = false) {
		const {
			projectIdRequired, projectId, clientId, includeNonCampaignOption, nonCampaignOption, useDefaultOptionFormat,
			fetchCampaignCodebookAction
		} = this.props;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		
		if (!projectIdRequired || !!projectId) {
			await this.setState({campaignListLoading: true})
				.then(() => executeAbortableAction(fetchCampaignCodebookAction, projectId, clientId))
				.then(res => {
					let campaignList = getArray(res);
					if (includeNonCampaignOption) campaignList.unshift(nonCampaignOption);
					return this.setState({
						campaignList: (
							useDefaultOptionFormat ? 
								campaignList.map(i => ({id: i.id, label: i.name, value: i.id})) : 
								campaignList
						)
					});
				})
				.finally(() => this.setState({campaignListLoading: false}));
		}
	}

	render() {
		const {projectIdRequired, projectId, useDefaultOptionFormat} = this.props;
		const {campaignList, campaignListLoading} = this.state;
		
		return (
			<SelectInput
				{...omit(this.props, [
					'isDisabled', 'projectIdRequired', 'projectId', 'clientId', ...Object.keys(allActions)
				])}
				primaryKey={useDefaultOptionFormat ? 'value' : 'id'}
				options={!!campaignList ? campaignList : []}
				isLoading={campaignListLoading}
				getOptionValue={o => useDefaultOptionFormat ? o.value : o.id}
				getOptionLabel={o => 
					(!!o.id && o.id !== '-1' ? `[${o.id}] ` : '') + 
					(useDefaultOptionFormat ? o.label : o.name)
				}
				isDisabled={projectIdRequired ? !projectId : getBoolean(this.props, 'isDisabled')}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CampaignSelectInput.propTypes = {
	...SelectInput.propTypes,

	projectIdRequired: PropTypes.bool,
	projectId: PropTypes.string,
	clientId: PropTypes.string,
	includeNonCampaignOption: PropTypes.bool,
	nonCampaignOption: PropTypes.object,
	useDefaultOptionFormat: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
CampaignSelectInput.defaultProps = {
	...SelectInput.defaultProps,
	
	includeNonCampaignOption: false,
	nonCampaignOption: {name: '---', id: ''},
	useDefaultOptionFormat: false,
};

export default connect(null, allActions, null, {forwardRef: true})(CampaignSelectInput);