import React from "react";
import {components} from "react-select";
import {get, set, forOwn, omit} from "lodash";
import Icon from "../../../../display/Icon";
import {SelectDynamicValueFunction} from "../../index";
import {getString} from 'Core/helpers/data';

/**
 * Select option replacement component using Icon component
 * @description Use this as an Option component replacement for the SelectInput (react-select) component. You can pass
 * Label component props through 'labelProps' prop of the SelectInput component. These prop can be an instance of
 * SelectDynamicValueFunction class if prop should depend on option data.
 *
 * @param {Object} props - Option props sent by the react-select component to all the options.
 * @return {JSX.Element}
 * @constructor
 */
const IconOptionComponent = props => {
	const labelProps = get(props, 'selectProps.labelProps', {});

	// If any prop is an instance of SelectDynamicValueFunction class, call the inner func and pass the option data to it 
	// to get the actual value
	let iconComponentProps = {};
	forOwn(labelProps, (value, key) => {
		if (value instanceof SelectDynamicValueFunction) {
			try { set(iconComponentProps, key, value.func(props.data)); }
			catch (e) { omit(iconComponentProps, [key]); }
		}
		else set(iconComponentProps, key, value);
	});

	return (
		<components.Option {...props}>
			{iconComponentProps ? 
				<Icon 
					className={`select-input-label-icon ${getString(iconComponentProps, 'className')}`} 
					{...omit(iconComponentProps, ['className'])} 
				/> 
				: null
			}{props.label}
		</components.Option>
	)
};

export default IconOptionComponent;