/** @typedef {string} MessageDefinitionFilter */
export const MESSAGE_DEFINITION_FILTER_ALL = 'ALL';
export const MESSAGE_DEFINITION_FILTER_IN_PREPARATION = 'IN_PREPARATION';
export const MESSAGE_DEFINITION_FILTER_PUBLISHED = 'PUBLISHED';
/** @enum {MessageDefinitionFilter} */
export const MESSAGE_DEFINITION_FILTER = {
	ALL: MESSAGE_DEFINITION_FILTER_ALL,
	IN_PREPARATION: MESSAGE_DEFINITION_FILTER_IN_PREPARATION,
	PUBLISHED: MESSAGE_DEFINITION_FILTER_PUBLISHED,
};
/** @type {MessageDefinitionFilter[]} */
export const MESSAGE_DEFINITION_FILTERS = [
	MESSAGE_DEFINITION_FILTER_ALL,
	MESSAGE_DEFINITION_FILTER_IN_PREPARATION,
	MESSAGE_DEFINITION_FILTER_PUBLISHED,
];