import "./index.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE} from "Core/components/DialogComponent";
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import MessagePreview, {
	MESSAGE_PREVIEW_TYPE, 
	MessagePreviewOptionsEMAIL,
	MessagePreviewOptionsPUSH_NOTIFICATION,
	MessagePreviewOptionsSMS,
	MessagePreviewOptionsVIBER
} from 'Core/components/display/MessagePreview';
import {getArray, getNumber, getStringForDisplay} from 'Core/helpers/data';
import {isBreakpointBigger} from 'Core/helpers/dom';
import {selectors} from 'Core/store/reducers';
import {get} from 'lodash';
import {DATETIME_LOCALE_INPUT_FORMAT} from 'Core/components/input/DatetimeInput/const';
import Label from 'Core/components/display/Label';
import DateLabel from 'Core/components/display/DateLabel';
import {
	NOTIFICATION_PREVIEW_MODE,
	NOTIFICATION_PREVIEW_MODES
} from 'Components/dialogs/NotificationPreviewDialog/const';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	currentBreakpointName: selectors.breakpoint.getCurrentBreakpointName(state),
});

class NotificationPreviewDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'notification-preview-dialog',
			translationPath: 'NotificationPreviewDialog',
			alignContent: 'left',
			dialogType: DIALOG_TYPE.NONE,
		});
	}

	render() {
		const {currentBreakpointName, mode, showMessageTypeHeader} = this.props;
		/** @type {MessagesListItemDeliveryDataObject} */
		const data = this.getProp('data');
		const {messageType, messageSubject, messageText} = data;
		/** @type {MessageContentDataObject} */
		const sourceData = get(data, 'messageSourceData');

		/**
		 * @type {
		 * 	MessagePreviewOptionsSMS|
		 * 	MessagePreviewOptionsVIBER|
		 * 	MessagePreviewOptionsPUSH_NOTIFICATION|
		 * 	MessagePreviewOptionsEMAIL
		 * }
		 */
		let options = null;
		switch (messageType) {
			case MESSAGE_PREVIEW_TYPE.SMS: 
				options = new MessagePreviewOptionsSMS(messageText); 
				break;
			case MESSAGE_PREVIEW_TYPE.VIBER: 
				options = new MessagePreviewOptionsVIBER(
					messageText, sourceData.image, sourceData.link, sourceData.linkText
				); 
				break;
			case MESSAGE_PREVIEW_TYPE.PUSH:
				options = new MessagePreviewOptionsPUSH_NOTIFICATION(
					messageSubject, messageText, sourceData.image, sourceData.link, sourceData.linkText, 
					JSON.stringify(getArray(sourceData.customFields))
				);
				break;
			case MESSAGE_PREVIEW_TYPE.EMAIL:
				options = new MessagePreviewOptionsEMAIL(messageSubject, messageText, sourceData.bodySource);
				break;
			// no default
		}
		
		return this.renderDialog(
			messageType === MESSAGE_PREVIEW_TYPE.EMAIL ? 
				this.renderTitle(
					<>
						<Label icon="user-circle-o" content={getStringForDisplay(options?.subject)}/>
						{mode === NOTIFICATION_PREVIEW_MODE.REAL ?
							<div className="email-preview-header">
								<div className="email-recipient"><Label content={data.recipient}/></div>
								<div className="email-time">
									<DateLabel 
										inputDate={data.deliveryDate}
										outputFormat={DATETIME_LOCALE_INPUT_FORMAT.STANDARD}
									/>
								</div>
							</div>
							: null
						}
					</>
				) : null,
			messageType === MESSAGE_PREVIEW_TYPE.EMAIL ?
				<div className="email-preview">
					<iframe
						title="e-mail"
						srcDoc={options?.body}
						width={getNumber(options, 'bodySource.body.values.contentWidth', 320)}
					/>
				</div>
				:
				<MessagePreview
					showMessageTypeHeader={
						showMessageTypeHeader ? 
							[MESSAGE_PREVIEW_TYPE.VIBER, MESSAGE_PREVIEW_TYPE.SMS].includes(messageType) : 
							false
					}
					messageTypeHeaderRecipient={mode === NOTIFICATION_PREVIEW_MODE.REAL ? data.recipient : undefined}
					messageType={messageType}
					messageOptions={options}
					messageTime={mode === NOTIFICATION_PREVIEW_MODE.REAL ? data.deliveryDate : undefined}
					messageTimeFormat={
						mode === NOTIFICATION_PREVIEW_MODE.REAL ? DATETIME_LOCALE_INPUT_FORMAT.STANDARD : undefined
					}
					deviceWidth={isBreakpointBigger(currentBreakpointName, 'bp-xs') ? 320 : 320}
					deviceAspectRatio="9/16"
				/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
NotificationPreviewDialog.propTypes = {
	// Preview mode
	// @type {NotificationPreviewMode}
	mode: PropTypes.oneOf(NOTIFICATION_PREVIEW_MODES),
	// Flag that specifies if message type header will be rendered at the top of the device screen
	showMessageTypeHeader: PropTypes.bool, 
	// @type {MessagesListItemDeliveryDataObject}
	data: PropTypes.object,
};

/**
 * Define component default values for own props
 */
NotificationPreviewDialog.defaultProps = {
	mode: NOTIFICATION_PREVIEW_MODE.REAL,
	showMessageTypeHeader: true,
};

export default connect(mapStateToProps, null)(NotificationPreviewDialog);