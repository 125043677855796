import {v4} from 'uuid';

export class ChannelDataObject {
	/**
	 * @param {string|null} [id=null] - Channel's DB ID (ID form the relation table).
	 * @param {string|null} [channelId=null] - Channel's DB ID.
	 * @param {string} [channelName=''] - Name of the channel.
	 * @param {CampaignMessageContentType|''} [messageType=''] - Message type used by the channel.
	 * @param {?string} [sender=null] - For SMS and Viber messages this field is set to short code. For email messages
	 * this field is set to the email address that email is sent from. For push notifications this field can be empty.
	 * @param {string} [senderName=''] - This field is populated only for email messages, and it is set to the name of
	 * the sender ("from name") set on the email message when sending an email.
	 * @param {string} [GUIID] - GUI ID of the channel used exclusively by GUI to manage the list of channels.
	 */
	constructor(
		id = null, channelId = null, channelName = '', messageType = '', sender = null, senderName = '', GUIID = v4(),
	) {
		this.id = id;
		this.channelId = channelId;
		this.channelName = channelName;
		this.messageType = messageType;
		this.sender = sender;
		this.senderName = senderName;
		this.GUIID = GUIID;
	}
}

export class ChannelSelectOptionDataObject {
	/**
	 * @param {string} value - DB ID of the channel.
	 * @param {string} label - Name of the channel.
	 * @param {CampaignMessageContentType} messageType - Message type used by this channel.
	 * @param {string} mobileAppName - Name of the mobile application if channel is for push notification.
	 * @param {ChannelSelectOptionSenderDataObject[]} senders - List of possible senders for the channel.
	 * @param {string} providerId - DB ID of the channel provider.
	 */
	constructor(value, label, messageType, mobileAppName, senders, providerId) {
		this.value = value;
		this.label = label;
		this.messageType = messageType;
		this.mobileAppName = mobileAppName;
		this.senders = senders;
		this.providerId = providerId;
	}
}
export class ChannelSelectOptionSenderDataObject {
	/**
	 * @param {string} sender - Predefined channel sender defined for the channel provider.
	 * @param {string} senderName - Default channel sender name for the sender used for email channels. Can be overridden
	 * by user.
	 */
	constructor(sender, senderName) {
		this.sender = sender;
		this.senderName = senderName;
	}
}