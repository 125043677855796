import {ioJsonAction} from 'Core/store/actions/io';
import {isSuccessful} from 'Core/helpers/io';
import {getArray} from 'Core/helpers/data';
import * as channelSelectOptionsDataMap from './dataMap/selectOption';

/**
 * Fetch all available channel select options
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {?string} [projectId=null] - DB ID of the project to get available channel options for.
 * @return {function(*): Promise<ChannelSelectOptionDataObject[]|undefined>}
 */
export const fetchAvailableChannelOptionsAction = (abortCallback, projectId = null) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'channel/fetch-all',
		{projectId},
	)(dispatch)
		// Get mapped data from response data
		.then(r => {
			if (isSuccessful(r)) return getArray(r, 'data').map(c => channelSelectOptionsDataMap.input(c));
			else return undefined;
		});
};