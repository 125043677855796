import {v4} from 'uuid';

/**
 * Data object representing the capping list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class CappingListItemDataObject {
	/**
	 * @param {string} id - DB ID of the capping plan.
	 * @param {string} name - Name of the capping plan.
	 * @param {boolean} active - Flag that specifies if capping plan is active.
	 * @param {number} campaignCount - Number of campaigns using this capping plan.
	 */
	constructor(id, name, active, campaignCount) {
		this.id = id;
		this.name = name;
		this.active = active;
		this.campaignCount = campaignCount;
	}
}

/**
 * Data object representing the capping item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class CappingItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {?string} [projectId=null] - DB ID of the project.
	 * @param {string} [name=''] - Item's name.
	 * @param {boolean} [active=true] - Flag that specifies if capping plan is active.
	 * @param {CappingItemRuleDataObject[]} [rules=[]] - List of capping rules.
	 * @param {?string} [clientId=null]
	 */
	constructor(id = null, projectId = null, name = '', active = true, rules = [], clientId) {
		this.id = id;
		this.projectId = projectId;
		this.name = name;
		this.active = active;
		this.rules = rules;
		this.clientId = clientId;
	}
}

export class CappingItemRuleDataObject {
	/**
	 * @param {?string} [id=null] - DB ID of the capping plan rule.
	 * @param {CampaignMessageContentType|''} [messageType=''] - Message type for witch this capping plan rule is used.
	 * @param {?number} [messageLimit=null] - Message count cap.
	 * @param {CappingPlanTimeUnit|''} [timeUnit=''] - Cap time unit.
	 * @param {?string} [clientId=null]
	 * @param {string} [GUIID] - Rule unique GUI ID. If not specified, unique GUI ID will be generated and used. 
	 */
	constructor(id = null, messageType = '', messageLimit = null, timeUnit = '', clientId = null, GUIID = v4()) {
		this.id = id;
		this.messageType = messageType;
		this.messageLimit = messageLimit;
		this.timeUnit = timeUnit;
		this.clientId = clientId;
		this.GUIID = GUIID;
	}
}