/** @typedef {string} PhoneInputRegion */
export const PHONE_INPUT_REGION_AMERICA = 'america';
export const PHONE_INPUT_REGION_EUROPE = 'europe';
export const PHONE_INPUT_REGION_ASIA = 'asia';
export const PHONE_INPUT_REGION_OCEANIA = 'oceania';
export const PHONE_INPUT_REGION_AFRICA = 'africa';
/** @enum {PhoneInputRegion} */
export const PHONE_INPUT_REGION = {
	AMERICA: PHONE_INPUT_REGION_AMERICA,
	EUROPE: PHONE_INPUT_REGION_EUROPE,
	ASIA: PHONE_INPUT_REGION_ASIA,
	OCEANIA: PHONE_INPUT_REGION_OCEANIA,
	AFRICA: PHONE_INPUT_REGION_AFRICA,
};
/** @type {PhoneInputRegion[]} */
export const PHONE_INPUT_REGIONS = [
	PHONE_INPUT_REGION_AMERICA,
	PHONE_INPUT_REGION_EUROPE,
	PHONE_INPUT_REGION_ASIA,
	PHONE_INPUT_REGION_OCEANIA,
	PHONE_INPUT_REGION_AFRICA,
];


/** @typedef {string} PhoneInputSubRegion */
export const PHONE_INPUT_SUBREGION_NORTH_AMERICA = 'north-america';
export const PHONE_INPUT_SUBREGION_SOUTH_AMERICA = 'south-america';
export const PHONE_INPUT_SUBREGION_CENTRAL_AMERICA = 'central-america';
export const PHONE_INPUT_SUBREGION_CARIBBEAN = 'carribean';
export const PHONE_INPUT_SUBREGION_EU_UNION = 'eu-union';
export const PHONE_INPUT_SUBREGION_EX_USSR = 'ex-ussr';
export const PHONE_INPUT_SUBREGION_EX_YU = 'ex-yugos';
export const PHONE_INPUT_SUBREGION_BALTIC = 'baltic';
export const PHONE_INPUT_SUBREGION_MIDDLE_EAST = 'middle-east';
export const PHONE_INPUT_SUBREGION_NORTH_AFRICA = 'north-africa';
/** @enum {PhoneInputSubRegion} */
export const PHONE_INPUT_SUBREGION = {
	NORTH_AMERICA: PHONE_INPUT_SUBREGION_NORTH_AMERICA,
	SOUTH_AMERICA: PHONE_INPUT_SUBREGION_SOUTH_AMERICA,
	CENTRAL_AMERICA: PHONE_INPUT_SUBREGION_CENTRAL_AMERICA,
	CARIBBEAN: PHONE_INPUT_SUBREGION_CARIBBEAN,
	EU_UNION: PHONE_INPUT_SUBREGION_EU_UNION,
	EX_USSR: PHONE_INPUT_SUBREGION_EX_USSR,
	EX_YU: PHONE_INPUT_SUBREGION_EX_YU,
	BALTIC: PHONE_INPUT_SUBREGION_BALTIC,
	MIDDLE_EAST: PHONE_INPUT_SUBREGION_MIDDLE_EAST,
	NORTH_AFRICA: PHONE_INPUT_SUBREGION_NORTH_AFRICA,
};
/** @type {PhoneInputSubRegion[]} */
export const PHONE_INPUT_SUBREGIONS = [
	PHONE_INPUT_SUBREGION_NORTH_AMERICA,
	PHONE_INPUT_SUBREGION_SOUTH_AMERICA,
	PHONE_INPUT_SUBREGION_CENTRAL_AMERICA,
	PHONE_INPUT_SUBREGION_CARIBBEAN,
	PHONE_INPUT_SUBREGION_EU_UNION,
	PHONE_INPUT_SUBREGION_EX_USSR,
	PHONE_INPUT_SUBREGION_EX_YU,
	PHONE_INPUT_SUBREGION_BALTIC,
	PHONE_INPUT_SUBREGION_MIDDLE_EAST,
	PHONE_INPUT_SUBREGION_NORTH_AFRICA,
];