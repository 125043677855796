import {ioJsonAction} from 'Core/store/actions/io';
import {hideLoadingFunction} from 'Core/helpers/loading';
import {sortBy} from 'lodash';
import {getArray} from 'Core/helpers/data';
import * as ioDataMap from 'DataMaps/project/listItem';

/**
 * Fetch the list of all projects
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [loadingGUIID] - GUI ID of the loading overlay to hide when done.
 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
 * @return {function(*): Promise<ProjectListItemDataObject[]|undefined>}
 */
export const fetchProjectListAction = (abortCallback, loadingGUIID, clientId = null) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'project/fetch-all-for-user',
		{clientId},
		loadingGUIID ? hideLoadingFunction(loadingGUIID) : undefined,
	)(dispatch)
		// Map and sort response data
		.then(response => {
			if (response) return sortBy(getArray(response, 'data').map(i => ioDataMap.input(i)), ['ordinal']);
			return undefined;
		});
}