import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {ImportCustomersListItemDataObject} from "../dataObjects";
import {ltrimString} from "Core/helpers/string";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ImportCustomersListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	const mobilePhoneNumber = getString(result, 'mobilePhoneNumber');

	result = new ImportCustomersListItemDataObject(
		getString(result, 'id'),
		getString(result, 'externalCrmId'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		(mobilePhoneNumber ? `+${ltrimString(mobilePhoneNumber, '+')}` : ''),
		getString(result, 'email')
	);

	return result;
}

// @note There is no output function since list is read only.