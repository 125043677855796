import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import {fetchProjectListAction} from 'Actions/project';
import {getGlobalActions} from 'Core/helpers/redux';
import SelectInput from 'Core/components/input/SelectInput';
import {omit} from 'lodash';
import PropTypes from 'prop-types';
import {selectors} from 'Core/store/reducers';
import {getArray, isset} from 'Core/helpers/data';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({fetchProjectListAction});

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	projectList: selectors['projectSelector'].getProjectList(state),
});

class ProjectSelectInput extends BaseComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			/** @type {ProjectListItemDataObject[]|null|undefined} */
			projectList: null,
			/** @type {boolean} */
			projectListLoading: false,
		};
		
		// Refs
		this.selectRef = null;
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		const {projectList, useGlobalProjectList, fetchProjectListAction} = this.props;
		
		executeComponentCallback(this.props.onLoadingStart);
		await this.setState({projectListLoading: true})
			.then(() => {
				// If 'useGlobalProjectList' flag is true, try to get the list of project from Redux store (set by the
				// project selector component in the app's header) instead of fetching it from IO
				if (useGlobalProjectList) {
					// Use project list if it is already loaded into Redux store
					if (isset(projectList) && projectList !== null) {
						return Promise.resolve(projectList);
					}
					// Fetch the product list from IO if it is not yet loaded into Redux store
					else {
						return this.executeAbortableActionMount(fetchProjectListAction);
					}
				}
				// If 'useGlobalProjectList' flag is false, fetch the product list from IO
				else {
					return this.executeAbortableActionMount(fetchProjectListAction);
				}
			})
			.then(projectList => {
				// Automatically select the project if there is only one project in the list and 'autoSelectSingleProject'
				// is set to true
				if (!!this.getProp('autoSelectSingleProject') && getArray(projectList).length === 1) {
					if (this.selectRef) this.selectRef.onChange(projectList[0]);
				}
				return this.setState({projectList});
			})
			.finally(() => {
				const {projectList} = this.state;
				
				return this.setState({projectListLoading: false})
					.then(state => {
						const projects = getArray(projectList);
						const singleProject = (getArray(projectList).length === 1);
						executeComponentCallback(this.props.onLoadingEnd, (singleProject ? projects[0] : null));
						return state;
					});
			});
	}
	
	render() {
		const {projectList, projectListLoading} = this.state;
		
		return (
			<SelectInput
				{...omit(
					this.props, 
					[
						'useGlobalProjectList', 'projectList', 'autoSelectSingleProject', 'onLoadingStart', 'onLoadingEnd',
						...Object.keys(allActions)
					]
				)}
				primaryKey="id"
				options={!!projectList ? projectList : []}
				isLoading={projectListLoading}
				getOptionValue={o => o.id}
				getOptionLabel={o => o.name}
				ref={node => { this.selectRef = node; }}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ProjectSelectInput.propTypes = {
	...SelectInput.propTypes,
	
	// Flag that specifies if this component will try to get the list of project from Redux store (set by the project 
	// selector component in the app's header) instead of fetching it from IO
	useGlobalProjectList: PropTypes.bool,
	// Automatically select the project if there is only one project in the list
	autoSelectSingleProject: PropTypes.bool,


	// Event triggered when list of project is started loading
	// No arguments
	onLoadingStart: PropTypes.func,
	// Event triggered when list of project is done loading
	// Arguments: {?ProjectListItemDataObject} singleProject
	onLoadingEnd: PropTypes.func,
};

/**
 * Define component default values for own props
 */
ProjectSelectInput.defaultProps = {
	...SelectInput.defaultProps,

	useGlobalProjectList: false,
	autoSelectSingleProject: false,
};

export default connect(mapStateToProps, allActions, null, {forwardRef: true})(ProjectSelectInput);