import "./index.css";

import PropTypes from "prop-types";
import PopupComponent, {
	POPUP_ACTION_BUTTON_LOCATION,
	PopupActionDataObject,
	PopupTabDataObject
} from 'Core/components/PopupComponent';
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {cloneDeep, orderBy, get, isEqual} from 'lodash';
import {
	icon_font_close_symbol, 
	icon_font_create_symbol, 
	icon_font_save_symbol, 
	icon_font_delete_symbol
} from "Config/app";
import {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {getBool, getString, isset, trimArray} from 'Core/helpers/data';
import ImportCustomersFromFileDialog from 'Components/dialogs/ImportCustomersFromFileDialog';
import {IMPORT_CUSTOMERS_FROM_FILE_TYPE} from 'Components/advanced/ImportCustomersFromFile/const';
import Label from 'Core/components/display/Label';
import {selectors} from 'Core/store/reducers';
import {reducerStoreKey} from 'Pages/apps/default/blacklists/reducer';
import DownloadBlacklistCustomersButton
	from 'Pages/apps/default/blacklists/components/DownloadBlacklistCustomersButton';
import {ADVANCED_DROPDOWN_POSITION} from 'Core/components/display/AdvancedDropdown';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	localeCode: selectors.i18n.getLocaleCode(state),
	blacklistsItemId: getString(selectors[reducerStoreKey].getBlacklistsItem(state), 'id'),
	blacklistsItemName: getString(selectors[reducerStoreKey].getBlacklistsItem(state), 'name'),
});

class ItemPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.ItemPopup`,
			domPrefix: 'item-popup',
			hideSingleTab: true,
			
			// Custom component options
			/**
			 * Current interval to check if importing customers from file is in progress
			 * @note The value entered here is an initial value that will be used when component mounts, but it will be
			 * changed based on the state of the 'importFromFileInProgress' flag when
			 * 'checkImportFromFileIntervalSlow' or 'checkImportFromFileIntervalFast' will be used. This should
			 * probably be the same value as 'checkImportFromFileIntervalSlow' option.
			 * @type {number|null|undefined} If falsy, no interval well be started.
			 */
			checkImportFromFileInterval: 60000,
			/**
			 * Interval to check if importing customers from file is in progress if it is not in progress
			 * @type {number|null|undefined} If falsy, no interval well be started.
			 */
			checkImportFromFileIntervalSlow: 60000,
			/**
			 * Interval to check if importing customers from file is in progress while it is in progress
			 * @type {number|null|undefined} If falsy, no interval well be started.
			 */
			checkImportFromFileIntervalFast: 5000,
			/**
			 * Interval identifier for interval for checking if importing customers from file is in progress
			 */
			importFromFileInterval: undefined,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',

			/**
			 * Flag that shows if importing customers form file is in progress
			 * @type {boolean}
			 */
			importFromFileInProgress: false,
			/**
			 * Type of the import currently in progress
			 * @note Relevant only if 'importFromFileInProgress' is true.
			 * @type {ImportCustomersFromFileType|''}
			 */
			importFromFileType: '',
		};

		this.state = cloneDeep(this.initialState);

		// Action methods
		this.save = this.save.bind(this);
		this.saveAndClose = this.saveAndClose.bind(this);
		this.delete = this.delete.bind(this);

		// Import/export methods
		this.addToListFromFile = this.addToListFromFile.bind(this);
		this.removeFromListFromFile = this.removeFromListFromFile.bind(this);
		this.startImportFromFileInterval = this.startImportFromFileInterval.bind(this);
		this.updateImportFromFileProgress = this.updateImportFromFileProgress.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();

		// Set the interval to periodically checking and updating import status
		this.startImportFromFileInterval();
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		const {localeCode, isNew, blacklistsItemId} = this.props;
		
		// Update dynamic action buttons when locale code changes to update action button labels
		if (localeCode !== prevProps.localeCode) {
			setTimeout(() => this.dynamicActionButtons({isNew}));
		}
		
		if (blacklistsItemId !== prevProps.blacklistsItemId) {
			// Restart the interval to periodically checking and updating import status
			this.startImportFromFileInterval();
			// Update import progress
			this.updateImportFromFileProgress().then();
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		// Clear interval that checks import status
		clearInterval(this.getOption('importFromFileInterval'));
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @return {Promise<void>}
	 */
	async dynamicActionButtons({isNew}) {
		const {blacklistsItemId, blacklistsItemName} = this.props;
		const {importFromFileInProgress} = this.state;
		
		const actionDefinitions = {
			'delete': new PopupActionDataObject({
				id: 'delete',
				action: this.delete,
				buttonProps: {
					label: 'general.Delete',
					icon: icon_font_delete_symbol,
					displayStyle: BUTTON_STYLE.DEFAULT
				},
				disabled: !blacklistsItemId,
				ordinal: 1
			}),
			'create': new PopupActionDataObject({
				id: 'create',
				action: this.save,
				buttonProps: {
					label: this.getTranslationPath('create_action'),
					icon: icon_font_create_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				ordinal: 3
			}),
			'update': new PopupActionDataObject({
				id: 'update',
				action: this.save,
				buttonProps: {
					label: this.getTranslationPath('update_action'),
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				disabled: !blacklistsItemId,
				ordinal: 3
			}),
			'update_and_close': new PopupActionDataObject({
				id: 'update_and_close',
				action: this.saveAndClose,
				buttonProps: {
					label: this.getTranslationPath('update_and_close_action'),
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				disabled: !blacklistsItemId,
				ordinal: 2,
			}),
			'import': new PopupActionDataObject({
				id: 'import',
				location: POPUP_ACTION_BUTTON_LOCATION.TAB,
				action: this.addToListFromFile,
				buttonProps: {
					label: (<Label element="span" content={this.t('import')}/>),
					tooltip: this.t('import_tooltip'),
					icon: 'level-down',
					displayStyle: BUTTON_STYLE.ACTION,
					displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
				},
				disabled: importFromFileInProgress || !blacklistsItemId,
				ordinal: 1,
			}),
			'import_remove': new PopupActionDataObject({
				id: 'import_remove',
				location: POPUP_ACTION_BUTTON_LOCATION.TAB,
				action: this.removeFromListFromFile,
				buttonProps: {
					label: (<Label element="span" content={this.t('import_remove')}/>),
					tooltip: this.t('import_remove_tooltip'),
					icon: 'level-up',
					displayStyle: BUTTON_STYLE.ACTION,
					displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
				},
				disabled: importFromFileInProgress || !blacklistsItemId,
				ordinal: 2,
			}),
			'download': new PopupActionDataObject({
				id: 'download',
				location: POPUP_ACTION_BUTTON_LOCATION.TAB,
				action: () => {}, // Nothing, because custom component is used.
				customButtonComponent: DownloadBlacklistCustomersButton,
				customButtonComponentProps: {
					blacklistsId: blacklistsItemId,
					blacklistsName: blacklistsItemName,
					defaultPosition: ADVANCED_DROPDOWN_POSITION.LEFT,
					boundRect: document.getElementById('item-popup').getBoundingClientRect(),
				},
				disabled: importFromFileInProgress || !blacklistsItemId,
				ordinal: 3,
			})
		};
		
		let actionsToRemove;
		let actionsToUpdate;
		if (isNew) {
			actionsToRemove = ['delete', 'update', 'update_and_close', 'import', 'import_remove', 'download'];
			actionsToUpdate = ['create'];
		} else {
			actionsToRemove = ['create'];
			actionsToUpdate = ['delete', 'update', 'update_and_close', 'import', 'import_remove', 'download'];
		}
		await Promise.allSettled([
			this.removeActions(actionsToRemove),
			...actionsToUpdate.map(id => {
				const prevAction = this.getAction(id);
				const action = actionDefinitions[id];
				if (!isEqual(prevAction, action)) {
					if (this.actionExists(id)) return this.updateAction(id, action);
					else return this.addAction(action);
				}
				return Promise.resolve();
			})
		]);
	}

	/**
	 * Update dynamic tabs that depend on current state and props
	 *
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @return {Promise<unknown>}
	 */
	async dynamicTabs({isNew}) {
		const {importFromFileInProgress, importFromFileType} = this.state;
		// Add customers tab on update dialog
		if (!isNew) {
			if (!this.tabExists('CustomersTab')) {
				await this.addTab(new PopupTabDataObject({
					id: 'CustomersTab',
					icon: 'users',
					label: this.tt('tab_name', 'CustomersTab'),
					loading: importFromFileInProgress,
					ordinal: 2
				}));
				await this.importTabComponents();
			}
		}
		// Remove customers tab on create dialog
		else {
			if (this.tabExists('CustomersTab')) await this.removeTab('CustomersTab');
		}
		
		return Promise.allSettled(
			this.getTabs().map(tab => this.updateTab({
				...tab,
				loading: (tab.id === 'CustomersTab' && importFromFileInProgress),
				componentProps: {...tab.componentProps, isNew, importFromFileInProgress, importFromFileType},
			}))
		);
	}

	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const isNew = getBool(this.props, 'isNew');

		// Add static actions that don't depend on current state or props
		let actions = [
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
		];
		await this.setActions(actions);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({isNew});

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
				icon: 'cog',
				label: this.tt('tab_name', 'MainTab'),
			}),
		]).then(this.importTabComponents);
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs({isNew});

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	save(allTabsData, event, actionId) {
		const {
			redirectToItem, createBlacklistsItemAction, updateBlacklistsItemAction, 
			loadBlacklistsListAction, addSuccessMessageAction
		} = this.props;

		return this.getTabRef('MainTab').validateTab()
			.then(valid => {
				if (valid) {
					/** @type {BlacklistsItemDataObject} */
					const item = get(allTabsData, 'MainTab');

					// Create item
					if (actionId === 'create') {
						return this.executeAbortableAction(createBlacklistsItemAction, item)
							.then(createdItem => {
								if (createdItem?.id) {
									// Redirect to item URL if item was created successfully and show success message
									redirectToItem(createdItem.id);
                        	addSuccessMessageAction(this.t('create_success_msg'));
								
									// Reload item list (data table) without rendering the loading overlay
									// @note This is done asynchronously on purpose because there is no reason to wait for this 
									// to finish before continuing.
									this.executeAbortableAction(loadBlacklistsListAction,
										undefined, undefined, undefined, undefined, undefined, false
									).then();
								}
								return createdItem;
							});
					}
					// Update item
					else if (actionId === 'update') {
						return this.executeAbortableAction(
							updateBlacklistsItemAction,
							getString(item, 'id', '', true),
							item
						)
							.then(response => {
								if (response) addSuccessMessageAction(this.t('update_success_msg'));
								return response;
							});
					} else {
						console.error(`Invalid blacklists item popup save method: ${actionId}`);
					}
				}
				return Promise.resolve();
			});
	}

	/**
	 * Save item and close the popup if successful
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @return {Promise<*>}
	 */
	saveAndClose(allTabsData, event) {
		return this.save(allTabsData, event, 'update')
			.then(response => {
				if (isset(response)) this.close();
				return response;
			});
	}

	/**
	 * Delete item
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	delete(allTabsData, event, actionId) {
		const {deleteItemAction, redirectToBase} = this.props;
		const item = get(allTabsData, 'MainTab');
		if (item && item.id) return deleteItemAction(item).then(event => { if (event === 'yes') redirectToBase(); });
		return Promise.resolve();
	}


	// Import/export methods --------------------------------------------------------------------------------------------
	/**
	 * Add customers to a blacklist from the import file
	 * 
	 * @param {Object} tabData - Internal data object of the tab that called the action.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 */
	addToListFromFile(tabData, event, actionId) {
		const {blacklistsItemId, openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', ImportCustomersFromFileDialog, {
			icon: 'level-down',
			title: this.t('import'),
			description: this.t('import_desc'),
			importType: IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_ADD,
			showUniqueIdColumn: true,
			blacklistId: blacklistsItemId,
			onSuccess: () => {
				this.setState({
					importFromFileInProgress: true,
					importFromFileType: IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_ADD,
				})
					// Update dynamic tabs and actions because they depend on import progress set above
					.then(() => {
						const isNew = !getString(this.getTabData('MainTab'), 'id');
						return this.updateDynamics({isNew});
					})
					// Check if import is done if small number of customers are imported
					.then(() => setTimeout(() => this.updateImportFromFileProgress(), 500));
			}
		}, {
			id: 'import-customers-from-file-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Remove customers from a blacklist using the import file
	 * 
	 * @param {Object} tabData - Internal data object of the tab that called the action.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 */
	removeFromListFromFile(tabData, event, actionId) {
		const {blacklistsItemId, openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', ImportCustomersFromFileDialog, {
			icon: 'level-up',
			title: this.t('import_remove'),
			description: this.t('import_remove_desc'),
			importType: IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_REMOVE,
			showUniqueIdColumn: true,
			blacklistId: blacklistsItemId,
			defaultOverride: false,
			loadingSelector: '#remove-customers-from-file-dialog',
			onSuccess: () => {
				this.setState({
					importFromFileInProgress: true,
					importFromFileType: IMPORT_CUSTOMERS_FROM_FILE_TYPE.BLACKLIST_REMOVE,
				})
					// Update dynamic tabs and actions because they depend on import progress set above
					.then(() => {
						const isNew = !getString(this.getTabData('MainTab'), 'id');
						return this.updateDynamics({isNew});
					})
					// Check if import is done if small number of customers are imported
					.then(() => setTimeout(() => this.updateImportFromFileProgress(), 500));
			}
		}, {
			id: 'remove-customers-from-file-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Start the interval to periodically check if customers are being imported from a file
	 * @return {void}
	 */
	startImportFromFileInterval() {
		const checkImportFromFileInterval = this.getOption('checkImportFromFileInterval');
		const importFromFileInterval = this.getOption('importFromFileInterval');

		// Clear previous interval (if any)
		if (importFromFileInterval) clearInterval(importFromFileInterval);

		// Start the interval and store its identifier in component option
		if (checkImportFromFileInterval) {
			this.setOption(
				'importFromFileInterval',
				setInterval(() => this.updateImportFromFileProgress(false, true), checkImportFromFileInterval)
			);
		}
	}

	/**
	 * Update local state flag for importing customers from file progress
	 *
	 * @param {boolean} [onMount=false] - Flag that specifies if this method is called as part of the mount cycle.
	 * @param {boolean} [ignoreErrors=false] - Flag that specifies if response errors will be ignored.
	 * @return {Promise<Object|void>}
	 */
	updateImportFromFileProgress(onMount = false, ignoreErrors = false) {
		const {blacklistsItemId, checkBlacklistImportProgressAction} = this.props;
		const {importFromFileInProgress, importFromFileType} = this.state;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		const isNew = !getString(this.getTabData('MainTab'), 'id');
		
		if (!isNew && !!blacklistsItemId) {
			// Abort previous check action
			this.abortAction('checkBlacklistImportProgressAction');
			
			// Execute check action
			return executeAbortableAction({
				id: 'checkBlacklistImportProgressAction', 
				action: checkBlacklistImportProgressAction
			}, blacklistsItemId, ignoreErrors)
				.then(newProgressType => {
					const newInProgress = !!newProgressType;
					
					// Error while checking import progress
					if (!isset(newProgressType)) {
						// Set interval time to slow
						this.setOption('checkImportFromFileInterval', this.getOption('checkImportFromFileIntervalSlow'));
					}
					// Import is in progress
					else if (newProgressType !== '') {
						// Set interval time to fast
						this.setOption('checkImportFromFileInterval', this.getOption('checkImportFromFileIntervalFast'));
					}
					// Import is not in progress
					else {
						// Set interval time to slow
						this.setOption('checkImportFromFileInterval', this.getOption('checkImportFromFileIntervalSlow'));
					}

					// Restart the interval with new time
					this.startImportFromFileInterval();
					
					return this.setState({
						importFromFileInProgress: newInProgress,
						importFromFileType: newProgressType,
					})
						// Update dynamic tabs and actions that depend on import progress
						.then(() => {
							const isNew = !getString(this.getTabData('MainTab'), 'id');
							return (importFromFileType !== newProgressType ? this.updateDynamics({isNew}) : undefined);
						})
						.then(() => {
							// Import process has ended
							if (!newInProgress && newInProgress !== importFromFileInProgress) {
								const isNew = !getString(this.getTabData('MainTab'), 'id');
								
								// Reload customers tab main list with current options but without the loading overlay because 
								// this should be a background process
								if (!isNew) {
									const customersTabRef = this.getTabRef('CustomersTab');
									if (!!customersTabRef) customersTabRef.reloadMainList(false).then();
								}
							}
						});
				});
		}
		return Promise.resolve();
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ItemPopup.propTypes = {
	// Flag that specifies if this popup is for creating a new item
	// @note This prop can be dynamically changed.
	isNew: PropTypes.bool,
	// Function that will redirect to item URL
	redirectToItem: PropTypes.func, // Arguments: {string} - Item id to redirect to.
	// Function that will redirect to the base URL of the page
	redirectToBase: PropTypes.func, // Arguments: no arguments
	
	// Actions
	// @param {ManageDocumentsItemDataObject} item - Item to delete. Full item is needed to access the name displayed in
	// delete confirmation message.
	// @return {Promise<void>}
	deleteItemAction: PropTypes.func,

	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(ItemPopup);