import "./index.css";
import "./typeViber.css";
import "./typeSms.css";
import "./typeEmail.css";
import "./typePush.css";

import appIconLight from "Images/logo_small_light.png";
import appIconDark from "Images/logo_small_dark.png";

import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {MESSAGE_PREVIEW_TYPE, MESSAGE_PREVIEW_TYPE_ICONS, MESSAGE_PREVIEW_TYPES} from './const';
import * as optionObjects from "./optionObjects";
import {get, pick} from "lodash";
import {getNumber, getString, isset} from "Core/helpers/data";
import {cssStyleStringToObject, nl2br} from "Core/helpers/string";
import Html from "Core/components/display/Html";
import DateLabel from "Core/components/display/DateLabel";
import {SKIN_MODE} from "Core/const/global";
import {getSkin} from "Core/helpers/skin";
import Label from "Core/components/display/Label";
import Icon from 'Core/components/display/Icon';

class MessagePreview extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: "MessagePreview",
			domPrefix: 'message-preview-component',
			optimizedUpdate: true,
			updateOnSkinChange: true,
		});

		// Render methods
		this.updateCssVars = this.updateCssVars.bind(this);
	}
	
	componentDidMount() {
		super.componentDidMount();
		
		this.updateCssVars();
	}


	componentDidUpdate(prevProps, prevState, snapshot) {
		this.updateCssVars();
	}

	/**
	 * Update CSS custom properties (variables) for the wrapper div
	 */
	updateCssVars() {
		const {
			deviceWidth, deviceAspectRatio, deviceBorderRadius, deviceBezel, deviceBezelColor, deviceBorder,
			deviceScreenBorder, deviceScreenBackground, fontSize, messageBackground, messageBorder, buttonBackground, 
			buttonColor
		} = this.props;
		const elem = this.getDomElement();
		
		if (elem) {
			if (isset(deviceWidth)) elem.style.setProperty('--device-width', deviceWidth);
			if (isset(deviceAspectRatio)) elem.style.setProperty('--device-aspect-ratio', deviceAspectRatio);
			if (isset(deviceBorderRadius)) elem.style.setProperty('--device-border-radius', deviceBorderRadius);
			if (isset(deviceBezel)) elem.style.setProperty('--device-bezel', deviceBezel);
			if (isset(deviceBezelColor)) elem.style.setProperty('--device-bezel-color', deviceBezelColor);
			if (isset(deviceBorder)) elem.style.setProperty('--device-border', deviceBorder);
			if (isset(deviceScreenBorder)) elem.style.setProperty('--device-screen-border', deviceScreenBorder);
			if (isset(deviceScreenBackground)) elem.style.setProperty('--device-screen-background', deviceScreenBackground);
			if (isset(fontSize)) elem.style.setProperty('--device-screen-font-size', fontSize);
			if (isset(messageBackground)) elem.style.setProperty('--device-message-background', messageBackground);
			if (isset(messageBorder)) elem.style.setProperty('--device-message-border', messageBorder);
			if (isset(buttonBackground)) elem.style.setProperty('--device-button-background', buttonBackground);
			if (isset(buttonColor)) elem.style.setProperty('--device-button-color', buttonColor);
		}
	}
	
	render() {
		const {
			className, messageType, defaultStyle, showMessageTypeHeader, messageTypeHeaderRecipient, messageTime, 
			messageTimeFormat
		} = this.props;
		const optionsClass = get(optionObjects, `MessagePreviewOptions${messageType}`);
		let messageOptions = null;
		if (optionsClass) {
			messageOptions = pick(
				get(this.props, 'messageOptions'),
				Object.getOwnPropertyNames(new optionsClass())
			);
		}
		
		let subject = get(messageOptions, 'subject', null);
		const subjectLimit = getNumber(messageOptions, 'subjectLimit', null);
		if (subjectLimit > 0 && subject !== null) subject = subject.slice(0, subjectLimit) + '...';
		
		let body = get(messageOptions, 'body', null);
		const bodyLimit = getNumber(messageOptions, 'bodyLimit', null);
		if (bodyLimit > 0 && body !== null) body = body.slice(0, bodyLimit) + '...';
		
		const image = get(messageOptions, 'image', null);
		const link = get(messageOptions, 'link', null);
		const linkText = getString(messageOptions, 'linkText');
		const appIcon = (getSkin() === SKIN_MODE.DARK ? appIconDark : appIconLight);
		
		return (
			<div 
				id={this.getDomId()} 
				className={`${this.getOption('domPrefix')} ${className} message-type-${messageType}`}
			>
				<div className="preview">
					<div className="speaker" />
					<div className="screen-wrapper">
						{showMessageTypeHeader ?
							<div className="type-header">
								{!!getString(MESSAGE_PREVIEW_TYPE_ICONS, messageType) ?
									<><Icon {...MESSAGE_PREVIEW_TYPE_ICONS[messageType]} />&nbsp;</> :
									null
								}
								<Label content={this.t(messageType, 'constants.messageType')}/>
								{!!messageTypeHeaderRecipient ?
									!!messageType ?
										<>: <Label content={messageTypeHeaderRecipient} /></>:
										<Label content={messageTypeHeaderRecipient} />
									: null
								}
							</div>
							: null
						}
						<div className="screen">
							<div className="avatar" />
							<div className="message">
								<div className="header">
									{
										messageType === MESSAGE_PREVIEW_TYPE.PUSH ?
											<div className="app-name">
												<img className="app-icon" src={appIcon} alt="" />
												<Label content={this.t('app_name')} />
												<span className="time">&middot;&nbsp;&nbsp;
													{isset(messageTime) ?
														<DateLabel inputDate={messageTime} outputFormat={messageTimeFormat} /> : 
														'2m'
													}
												</span>
											</div>
											: subject ?
												<h2>{subject}</h2>
											: null
									}
								</div>
								
								<div className="body">
									<div className="content">
										{messageType === MESSAGE_PREVIEW_TYPE.PUSH ? subject ? <h2>{subject}</h2> : null : null}
										{body ?
											messageType === MESSAGE_PREVIEW_TYPE.EMAIL ?
												<Html
													element="div"
													elementProps={{style: cssStyleStringToObject(getString(defaultStyle))}}
													content={body}
												/>
												:
												<Html
													element="p"
													elementProps={{style: cssStyleStringToObject(getString(defaultStyle))}}
													content={nl2br(body)}
												/>
											: null}
									</div>
									{image ? <div className="image"><img src={image} alt="" /></div> : null}
								</div>
								
								{link || linkText ?
									<div className="actions">
										<a
											href={link ? link : undefined}
											target="_blank"
											rel="noopener noreferrer"
											className={`button solid ${messageType} no-select`}
										>{getString(messageOptions, 'linkText')}</a>
									</div>
									: null
								}
								
								<div className="time">
									<DateLabel 
										inputDate={isset(messageTime) ? messageTime : new Date()} 
										outputFormat={messageTimeFormat} 
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MessagePreview.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	
	// @type {MessagePreviewMessageType|string}
	messageType: PropTypes.oneOfType([PropTypes.oneOf(MESSAGE_PREVIEW_TYPES), PropTypes.string]),
	// @type {
	// 	MessagePreviewOptionsSMS |
	// 	MessagePreviewOptionsEmail |
	// 	MessagePreviewOptionsViber |
	// 	MessagePreviewOptionsPUSH_NOTIFICATION
	// }
	messageOptions: PropTypes.object,
	
	// Device frame width in px
	deviceWidth: PropTypes.number,
	// Aspect ratio css value
	// @example '9/16' - Portrait phone.
	deviceAspectRatio: PropTypes.string,
	// Device frame outer border radius
	// @note Inner border radius will be automatically calculated.
	deviceBorderRadius: PropTypes.number,
	// Device frame bezel in px
	deviceBezel: PropTypes.number,
	// Device frame bezel background color
	deviceBezelColor: PropTypes.string,
	// Device frame outer border CSS value
	// @example '1px solid var(--border-color-main)'
	deviceBorder: PropTypes.string,
	// Device frame inner (screen) border CSS value
	// @example '1px solid var(--border-color-main)'
	deviceScreenBorder: PropTypes.string,
	// Device frame inner (screen) background CSS value
	deviceScreenBackground: PropTypes.string,
	// Device frame inner (screen) font size CSS value
	fontSize: PropTypes.string,
	// Message background CSS value
	messageBackground: PropTypes.string,
	// Message border CSS value
	// @example '1px solid var(--background-color-secondary)'
	messageBorder: PropTypes.string,
	// Action button background CSS value
	buttonBackground: PropTypes.string,
	// Action button label color CSS value
	buttonColor: PropTypes.string,
	// Flag that specifies if message type header will be rendered at the top of the device screen
	showMessageTypeHeader: PropTypes.bool,
	// Recipient name that will be rendered in the message header (if enabled) after the message type.
	// @note This is used only if 'showMessageTypeHeader' prop is true.
	messageTypeHeaderRecipient: PropTypes.string,
	// Date value that will be rendered in message preview as sent time
	// @note If undefined, current time will be used.
	messageTime: PropTypes.object,
	// Format used to render message time
	// @note This is used only if 'messageTime' prop is defined.
	messageTimeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	
	// Default CSS style for the preview
	// @example 'font-family: ariel, sans-serif;'
	defaultStyle: PropTypes.string,
};

/**
 * Define component default values for own props
 */
MessagePreview.defaultProps = {
	id: '',
	className: '',
	messageType: '',
	messageOptions: null,
	defaultStyle: 'font-family: ariel, sans-serif;',
	showMessageTypeHeader: false,
	messageTypeHeaderRecipient: '',
	messageTimeFormat: 'HH:mm',
};

export * from "./const";
export * from "./optionObjects";
export default MessagePreview;