import styles from "./index.module.css";
import logoLight from "../../../skin/images/logo_light.png";
import logoDark from "../../../skin/images/logo_dark.png";
import logoSmallLight from "../../../skin/images/logo_small_light.png";
import logoSmallDark from "../../../skin/images/logo_small_dark.png";

// Import pages
import * as defaultAppHomePageConfig from "Pages/apps/default/home/config";
import * as defaultAppStatisticsPageConfig from "Pages/apps/default/statistics/config";
import * as defaultAppProjectDashboardPageConfig from "Pages/apps/default/projectPages/dashboard/config";
import * as defaultAppCustomersPageConfig from "Pages/apps/default/customers/config";
import * as defaultAppBlacklistsPageConfig from "Pages/apps/default/blacklists/config";
import * as defaultAppCustomerCustomFieldsPageConfig from "Pages/apps/default/customerCustomFields/config";
import * as defaultAppProjectCampaignPageConfig from "Pages/apps/default/projectPages/campaign/config";
import * as defaultAppProjectCappingPageConfig from "Pages/apps/default/projectPages/capping/config";
import * as defaultAppMessagesPageConfig from "Pages/apps/default/messages/config";
import * as defaultAppMessageDeliveriesPageConfig from "Pages/apps/default/messageDeliveries/config";

// Import custom menu item components
// ...

import React from "react";
import SidebarComponent  from "Core/components/SidebarComponent";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {cloneDeep, get, isEqual, omit, set} from 'lodash';
import {selectors} from "Core/store/reducers";
import {getGlobalActions} from "Core/helpers/redux";
import {NavLink, Link} from "react-router-dom";
import {app_home_page_router_path, project_navigation_mode} from 'Config/app';
import Label from "Core/components/display/Label";
import ACL from "../../../acl";
import {getArray, getBool, getObject, getString, isset, trimArray} from 'Core/helpers/data';
import {getMenuSidebarShrankFromStorage} from "./helpers";
import {
	MainSidebarCustomComponentLinkDataObject,
	MainSidebarExternalLinkDataObject,
	MainSidebarGroupLinkDataObject
} from './dataObjects';
import Separator from "Core/components/display/Separator";
import Icon from "Core/components/display/Icon";
import {scrollToSelector} from "Core/helpers/dom";
import {AclCheckDataObject} from "Core/acl";
import {SKIN_MODE} from "Core/const/global";
import {getSkin} from "Core/helpers/skin";
import {getCurrentUrl, getRouterPathUrl} from "Core/helpers/url";
import {
	getCurrentProjectFromSession,
	getCurrentProjectIdFromSession,
	PROJECT_ID_CHANGE_EVENT_TYPE
} from 'Helpers/project';
import {MAIN_SIDEBAR_SECTION_ICONS} from "Layout/elements/MainSidebar/const";
import ProjectMenuSidebarItem from 'Components/sidebar/ProjectMenuSidebarItem';
import ProjectSubMenuSidebarItem from 'Components/sidebar/ProjectSubMenuSidebarItem';
import MenuItemGroupToggleButton from 'Layout/elements/MainSidebar/components/MenuItemGroupToggleButton';

/**
 * Imported page config if pages that will be rendered in main navigation as links in the order they are specified in
 * this array.
 */
const initialPages = {
	DEFAULT: {
		root: [
			defaultAppHomePageConfig,
		],
		// Projects section is used when project navigation is in 'all_projects' mode
		// @see 'project_navigation_mode' custom app config option.
		projects: [

		],
		// Project section is used when project navigation is in 'current_project' mode
		// @see 'project_navigation_mode' custom app config option.
		project: [

		],
		customers: [
			defaultAppCustomersPageConfig,
			defaultAppBlacklistsPageConfig,
			defaultAppCustomerCustomFieldsPageConfig,
		],
		statistics: [
			defaultAppStatisticsPageConfig,
		],
		logs: [
			defaultAppMessagesPageConfig,
			defaultAppMessageDeliveriesPageConfig,
		],
	},
};


/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	onTop: selectors.breakpoint.isMobileBreakpoint(state),
	visible: (!selectors.breakpoint.isMobileBreakpoint(state) || selectors.mainSidebar.visible(state)),
	shrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	groupsOpenedStatuses: selectors.mainSidebar.getGroupsOpenedStatuses(state),
	projectList: selectors.projectSelector.getProjectList(state),
	singleProject: selectors.projectSelector.getSingleProject(state),
});

class MainSidebar extends SidebarComponent {
	constructor(props) {
		super(props, {
			pages: {},
			activeChildren: {},
		}, {
			translationPath: 'MainSidebar',
			updateOnSkinChange: true,
			disableLoad: true,
		});
		
		// Change handle methods
		this.handleProjectChange = this.handleProjectChange.bind(this);

		// Menu methods
		this.updateMenu = this.updateMenu.bind(this);
		this.isGroupOpened = this.isGroupOpened.bind(this);
		this.isGroupChildActive = this.isGroupChildActive.bind(this);
		this.updateGroupsActiveStatus = this.updateGroupsActiveStatus.bind(this);

		// Render methods
		this.renderGroupToggleButton = this.renderGroupToggleButton.bind(this);
		this.renderItem = this.renderItem.bind(this);
		this.renderSectionItems = this.renderSectionItems.bind(this);
		this.renderSection = this.renderSection.bind(this);

		// Hide on mobile when 'Escape' key is pressed
		this.registerEventListener('keydown', event => {
			if (props.isMobileBreakpoint && event.key === 'Escape') this.hide();
		});
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined|AsyncMountError>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero. Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);

		const {loadMainSidebarGroupsOpenedStatusesAction} = this.props;

		// Load opened statuses of menu groups from storage into Redux store
		loadMainSidebarGroupsOpenedStatusesAction();
		
		// Scroll to active menu item
		setTimeout(() => {
			scrollToSelector(
				`#${this.getDomId()} .main-menu a.active`,
				true,
				0,
				`#${this.getDomId()} .main-menu`
			);
		});

		return Promise.resolve();
	}
	
	componentDidMount() {
		super.componentDidMount();

		const {visible, isMobileBreakpoint, showMainSidebarAction, hideMainSidebarAction} = this.props;

		window.addEventListener(PROJECT_ID_CHANGE_EVENT_TYPE, this.handleProjectChange);
		
		// Handle visibility on first page load or refresh
		if (visible === null) {
			if (isMobileBreakpoint) hideMainSidebarAction();
			else showMainSidebarAction();
		}
		
		this.updateMenu().then();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {showMainSidebarAction, hideMainSidebarAction} = this.props;
		
		return super.componentDidUpdate(prevProps, prevState, snapshot).then(state => {
			// If screen size mobile breakpoint flag changed
			if (prevProps.isMobileBreakpoint !== this.props.isMobileBreakpoint) {
				// Hide sidebar when screen size changes to mobile
				if (this.props.isMobileBreakpoint === true) hideMainSidebarAction();
				// Show sidebar when screen size changes to size bigger than mobile
				else showMainSidebarAction();
			}
			
			// Update menu when project list changes
			if (!isEqual(prevProps.projectList, this.getProp('projectList'))) this.updateMenu().then();
			
			// Make sure that promise resolves with the expected value (see 'super.componentDidUpdate' method)
			return cloneDeep(state);
		});
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		
		window.removeEventListener(PROJECT_ID_CHANGE_EVENT_TYPE, this.handleProjectChange);
	}


	// Change handle methods --------------------------------------------------------------------------------------------
	/**
	 * Handle project ID change event
	 */
	handleProjectChange() {
		this.updateMenu().then();
	}


	// Menu methods -----------------------------------------------------------------------------------------------------
	/**
	 * Update menu sections and items
	 * @return {Promise<Object>}
	 */
	updateMenu() {
		const {projectList, singleProject} = this.props;
		
		let pages = cloneDeep(initialPages);
		
		// When in 'all_projects' mode, add all projects as items and their related sub-items to the 'projects' section of
		// the menu
		if (project_navigation_mode === 'all_projects') {
			// Add project related menu items to the 'root' section if there is only one project
			if (isset(singleProject)) {
				set(
					pages,
					'DEFAULT.root',
					trimArray([...getArray(pages, 'DEFAULT.root'),
						new MainSidebarCustomComponentLinkDataObject(
							ProjectSubMenuSidebarItem,
							{id: `${singleProject.id}-campaign`, project: singleProject, item: defaultAppProjectCampaignPageConfig},
							defaultAppProjectDashboardPageConfig.access
						),
						new MainSidebarCustomComponentLinkDataObject(
							ProjectSubMenuSidebarItem,
							{id: `${singleProject.id}-capping`, project: singleProject, item: defaultAppProjectCappingPageConfig},
							defaultAppProjectDashboardPageConfig.access
						),
					])
				);
			}
			// Add project related menu items to the 'projects' section if there are more then one projects
			else {
				if (Array.isArray(projectList) && projectList.length) {
					set(
						pages,
						'DEFAULT.projects',
						trimArray([...getArray(pages, 'DEFAULT.projects'), ...projectList.map(p =>
							new MainSidebarGroupLinkDataObject(
								new MainSidebarCustomComponentLinkDataObject(
									ProjectMenuSidebarItem,
									{project: p},
									defaultAppProjectDashboardPageConfig.access
								),
								[
									new MainSidebarCustomComponentLinkDataObject(
										ProjectSubMenuSidebarItem,
										{key: `${p.id}-campaign`, project: p, item: defaultAppProjectCampaignPageConfig},
										defaultAppProjectDashboardPageConfig.access
									),
									new MainSidebarCustomComponentLinkDataObject(
										ProjectSubMenuSidebarItem,
										{key: `${p.id}-capping`, project: p, item: defaultAppProjectCappingPageConfig},
										defaultAppProjectDashboardPageConfig.access
									)
								]
							),
						)])
					);
				}
			}
		}
		// When in 'current_project' mode, add current project pages to the 'project' section of the menu
		else if (project_navigation_mode === 'current_project') {
			const currentProject = getCurrentProjectFromSession(projectList);

			// Add project related menu items to the 'root' section if there is only one project
			if (isset(singleProject)) {
				set(
					pages,
					'DEFAULT.root',
					trimArray([...getArray(pages, 'DEFAULT.root'),
						defaultAppProjectCampaignPageConfig,
						defaultAppProjectCappingPageConfig,
					])
				);
			}
			// Add project related menu items to the 'project' section if there are more then one projects
			else {
				const projectPages = [
					new MainSidebarCustomComponentLinkDataObject(
						ProjectMenuSidebarItem, {project: currentProject}, defaultAppProjectDashboardPageConfig.access,
					),
					defaultAppProjectCampaignPageConfig,
					defaultAppProjectCappingPageConfig,
				];

				// Manage visibility for default app project pages
				// @description Default app project pages should only be visible if a project is selected.
				if (currentProject) {
					set(
						pages,
						'DEFAULT.project',
						trimArray([...getArray(pages, 'DEFAULT.project'), ...projectPages])
					);
				}
			}
		}
		
		return this.setState({pages})
			.then(() => this.updateGroupsActiveStatus());
	}

	/**
	 * Check if menu group is opened
	 * 
	 * @param {string} id - Group item ID.
	 * @return {boolean}
	 */
	isGroupOpened(id) {
		const {groupsOpenedStatuses} = this.props;
		return getBool(groupsOpenedStatuses, id);
	}
	
	/**
	 * Check if menu group has an active child item
	 * 
	 * @param {string} id - Group item ID.
	 * @return {boolean}
	 */
	isGroupChildActive(id) {
		const {activeChildren} = this.state;
		return getBool(activeChildren, `${id}-group`);
	}

	/**
	 * Update which groups are active based on active children
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	updateGroupsActiveStatus() {
		try {
			const groups = document.querySelectorAll('.main-menu .group-menu-item');
			
			let activeChildren = {};
			groups.forEach(group => {
				const groupChildren = group.querySelector('.group-menu-item-children');
				set(activeChildren, group.id, !!groupChildren.querySelector('.active'));
			});
			return this.setState({activeChildren});
		} catch (e) {
			return Promise.resolve(this.state);
		}
	}

	/**
	 * Close/hide sidebar
	 */
	hide() {
		const {isMobileBreakpoint, hideMainSidebarAction} = this.props;
		// Main sidebar should only hide on mobile screen sizes.
		if (isMobileBreakpoint) hideMainSidebarAction();
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render a toggle button of a group menu item
	 * @return {JSX.Element}
	 */
	renderGroupToggleButton(id) {
		return (
			<MenuItemGroupToggleButton menuItemId={id} />
		);
	}
	
	/**
	 * Render sidebar item
	 *
	 * @param {
	 * 	Object|
	 * 	MainSidebarExternalLinkDataObject|
	 * 	MainSidebarCustomComponentLinkDataObject|
	 * 	MainSidebarGroupLinkDataObject
	 * } item - Menu item to render.
	 * @param {string|number} key - Item's unique key.
	 * @param {boolean} [groupItem=false] - Flag that specifies if group item should be rendered.
	 * @return {JSX.Element}
	 */
	renderItem(item, key, groupItem = false) {
		const currentProjectId = getCurrentProjectIdFromSession();
		const routeOptions = (
			isset(item?.menuRouterOptions) ?
				getObject(item, 'menuRouterOptions') :
				getObject(item, 'routerOptions')
		);
		
		return (
			ACL.check(ACL, get(item, 'access', new AclCheckDataObject())) ?
				(
					item instanceof MainSidebarExternalLinkDataObject ?
						<a 
							key={key}
							id={key}
							href={item.link}
							title={this.translatePath(item.labelTranslationPath)}
							className={
								`${getCurrentUrl()===item.link ? 'active' : ''} ${getString(item, 'linkProps.className')}`
							}
							{...omit(getObject(item, 'linkProps'), ['className'])}
						>
							{item.icon ?
								<Icon symbol={item.icon} symbolPrefix={item.iconSymbolPrefix} className={item.iconClassName} />
								: null
							}	
							<Label
								content={this.t('page_link_label', getString(item, 'labelTranslationPath'))}
								element="span"
							/>
							{groupItem ? this.renderGroupToggleButton(key) : null}
						</a>
					: item instanceof MainSidebarCustomComponentLinkDataObject ?
						item.component ? 
							<item.component key={key} id={key} groupItem={groupItem} {...omit(item.props, ['key'])} /> 
							: null
					: item instanceof MainSidebarGroupLinkDataObject ?
						<div 
							key={key} 
							id={`${key}-group`} 
							className={
								`group-menu-item ${this.isGroupOpened(key) ? 'opened' : ''} ` + 
								`${this.isGroupChildActive(key) ? 'active-child' : ''}`
							}
						>
							{this.renderItem(item.rootItem, key, true)}
							<div className="group-menu-item-children">
								<div className="indent-menu-item">
									{item.children.map((child, idx) => this.renderItem(child, `${key}-${idx}`))}
								</div>
							</div>
						</div>
					:
						<NavLink
							key={key}
							id={key}
							title={this.t('page_title', getString(item, 'translationPath'))}
							to={
								// Replace router path :projectId with the value form session storage (cookie)
								getRouterPathUrl(
									getRouterPathUrl(item.routerPath, getObject(this.props, 'match')),
									{params: {projectId: getString(currentProjectId)}}
								)
							}
							onClick={this.hide}
							{...routeOptions}
						>
							{item.hasOwnProperty('iconElement') ? item.iconElement : null}
							<Label
								content={this.t('page_link_label', getString(item, 'translationPath'))}
								element="span"
							/>
							{groupItem ? this.renderGroupToggleButton(key) : null}
						</NavLink>
				)
			: null
		);
	}

	/**
	 * Render main menu section items
	 * @note This method will only return visible (ACL check) rendered items.
	 *
	 * @param {string} section - Section name.
	 * @return {JSX.Element[]}
	 */
	renderSectionItems(section) {
		const {app} = this.props;
		const {pages} = this.state;
		return getArray(get(pages, app.name), section).map((item, idx) =>
			this.renderItem(item, `${section}-${idx}`)
		).filter(item => item !== null);
	}

	/**
	 * Render main menu section
	 * @note Section will be rendered only if it has visible (ACL check) items.
	 * 
	 * @param {string} section - Section name.
	 * @return {JSX.Element|null}
	 */
	renderSection(section) {
		const sectionItemsToRender = this.renderSectionItems(section).filter(i => i !== null);
		return (
			sectionItemsToRender.length > 0 ?
				<div className="main-menu-section" key={section}>
					{section !== 'root' ?
						<Separator
							icon={get(MAIN_SIDEBAR_SECTION_ICONS, [section, 'symbol'])}
							iconSymbolPrefix={get(MAIN_SIDEBAR_SECTION_ICONS, [section, 'symbolPrefix'])}
							content={this.t(section)}
							noBorder={true}
						/>
						: null
					}
					{this.renderSectionItems(section)}
				</div>
				: null
		);
	}

	render() {
		const {className, app, shrank, isMobileBreakpoint} = this.props;
		const {pages} = this.state;
		
		return this.renderSidebar(
			<>
				<Link 
					to={app_home_page_router_path} 
					onClick={this.hide} 
					className={`${styles['logo-link']} ${shrank ? styles['shrank'] : ''}`}
				>
					<img 
						className="logo"
						src={
							getSkin() === SKIN_MODE.DARK ?
								(!isMobileBreakpoint && shrank ? logoSmallDark : logoDark) :
								(!isMobileBreakpoint && shrank ? logoSmallLight : logoLight)
						} 
						alt={this.t('title', 'App')} 
					/>
				</Link>
				
				<div className="main-menu">
					{Object.keys(getObject(pages, app.name)).map(this.renderSection)}
				</div>
			</>,
			`${className} ${styles['wrapper']}`,
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MainSidebar.propTypes = {
	// Sidebar wrapper element id attribute
	id: PropTypes.string,
	// Sidebar wrapper element class attribute
	className: PropTypes.string,
	// Admin app config
	app: PropTypes.object,
};

/**
 * Define component default values for own props
 */
MainSidebar.defaultProps = {
	// Inherit all default values from parent abstract component
	...SidebarComponent.defaultProps,
	
	// @note 'visible' is set to null to disable animations on when component mounts (first page load or refresh).
	visible: null,
};

export default withRouter(connect(mapStateToProps, getGlobalActions())(MainSidebar));
export {default as reducer, reducerStoreKey, selectors, actionCreators} from "./reducer";
export * from "./actions";
