import {STATISTICS_SUB_SECTION_MAP} from 'Pages/apps/default/statistics/const';
import {getArray} from 'Core/helpers/data';
import {find, get} from 'lodash';
import {StatisticsItemResultSectionDataObject} from 'Pages/apps/default/statistics/dataObjects';

/**
 * Get statistics item result data used for rendering
 * @param {StatisticsItemResultDataObject} itemResult - Statistics item result.
 * @param {boolean} [skipTotal=false] - Flag that specifies if total will be excluded from the result.
 * @param {boolean} [skipCtr=false] - Flag that specifies if click-through rate will be excluded from the result.
 * @return {StatisticsItemResultSectionDataObject[]}
 */
export const getStatisticsItemResultData = (itemResult, skipTotal = false, skipCtr = false) => {
	let result = itemResult.byStatusList.map(i => new StatisticsItemResultSectionDataObject(
		i.status,
		i.count,
		getArray(STATISTICS_SUB_SECTION_MAP, i.status).map(s => new StatisticsItemResultSectionDataObject(
			s, get(find(itemResult.byStatusList, {status: s}), 'count')
		))
	));
	
	// Add total
	if (!skipTotal) result.unshift(new StatisticsItemResultSectionDataObject(
		'total',
		itemResult.total,
		getArray(STATISTICS_SUB_SECTION_MAP, 'total').map(s => new StatisticsItemResultSectionDataObject(
			s, get(itemResult, s)
		))
	));

	// Add click-through rate
	if (!skipCtr) {
		result.unshift(new StatisticsItemResultSectionDataObject('clickThroughRate', itemResult.clickThroughRate));
	}
	
	return result;
};