import {CUSTOMER_CUSTOM_FIELD_TYPE} from 'Pages/apps/default/customerCustomFields/const';

/**
 * Data object representing the customer custom fields list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class CustomerCustomFieldsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} name - List item's name.
	 * @param {string} description - List item's description.
	 * @param {CustomerCustomFiledType} dataType - List item's data type.
	 */
	constructor(id, name, description, dataType) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.dataType = dataType;
	}
}

/**
 * Data object representing the customer custom fields item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class CustomerCustomFieldsItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [name=''] - Item's name.
	 * @param {string} [description=''] - List item's description.
	 * @param {CustomerCustomFiledType} [dataType='TEXT'] - List item's data type.
	 */
	constructor(id = null, name = '', description = '', dataType = CUSTOMER_CUSTOM_FIELD_TYPE.TEXT) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.dataType = dataType;
	}
}